import React, { Component } from 'react';
import Page from '../../components/Page';
import { Icon, Segment, Form, Table, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

class Phones extends Component {
	phoneToDelete = null;
	state = {
		loading: true,
		saveButton: 'add',
		ex_file: '',
	};
	componentDidMount() {
		this.getList();
	}
	getList() {
		const { product } = this.props;
		let pid = product.id;
		fetch(`https://micro.yapaytech.com/api/dump/read?pid=${pid}&key=contactList`)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let result = data.result;
				let excelData = result && result.data;
				this.setState({ list: excelData, loading: false });
			});
	}

	fileUpload(file) {
		const { product } = this.props;
		this.setState({ loadingBtn: true });
		let pid = product.id;
		const url = 'https://micro.yapaytech.com/api/dump/write/excel';
		const formData = new FormData();
		formData.append('file', file);
		formData.append('pid', pid);
		formData.append('key', 'contactList');
		let file_url = '';
		fetch(url, {
			method: 'post',
			body: formData,
		})
			.then((response) => {
				return response.json();
			})
			.then(async (data) => {
				file_url = data && data.files && data.files[0] && data.files[0].url;
				this.setState({ ex_file: file_url, loadingBtn: false, message: true });
				this.getList();
			});
	}
	handleFileInput = (e) => {
		let file = e.target.files[0];
		this.fileUpload(file);
	};
	downloadExample = async () => {
		var a = document.createElement('a');
		a.href = '/contacts_example.xlsx';
		a.target = '_blank';
		a.download = 'contacts_example.xlsx';
		a.click();
	};
	render() {
		const { t } = this.props;
		let { loadingBtn, message, list } = this.state;
		if (this.state.loading) {
			return <Page.Loader />;
		} else {
			return (
				<Segment>
					<div style={{ padding: '10px', width: '100%' }}>
						<h3>
							<Icon name="whatsapp" />
							{t('contacts')}
						</h3>
						<Form>
							<Form.Field style={{ display: 'flex', marginBottom: '10px' }}>
								<div className="image-upload" style={{ margin: 'auto' }}>
									<label htmlFor="file-input" className={`ui blue icon left labeled button ${loadingBtn ? 'loading' : null}`}>
										{t('upload')}
										<Icon name="upload" style={{ cursor: 'pointer' }}></Icon>
									</label>
									<Button
										onClick={() => this.downloadExample()}
										content={t('example download')}
										icon="download"
										labelPosition="left"
										color="teal"
									/>
								</div>

								<input
									id="file-input"
									type="file"
									accept=".xlsx, .xls"
									style={{ display: 'none' }}
									onChange={(e) => {
										this.handleFileInput(e);
									}}
								/>
							</Form.Field>
							{message ? <p style={{ textAlign: 'center' }}>{t('messageExcelSuccess')}</p> : null}
						</Form>
					</div>
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>{'Id'}</Table.HeaderCell>
								<Table.HeaderCell>{'Name'}</Table.HeaderCell>
								<Table.HeaderCell>{'Phone'}</Table.HeaderCell>
								<Table.HeaderCell>{'Website'}</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{_.map(list, (it) => (
								<Table.Row>
									<Table.Cell>{it['Users Id']}</Table.Cell>
									<Table.Cell>{it.Name}</Table.Cell>
									<Table.Cell>{it.Phone}</Table.Cell>
									<Table.Cell>{it.Website}</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table>
				</Segment>
			);
		}
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.live,
	user: state.access.user.loggedUserObj,
});

export default connect(stateToProps)(withTranslation()(Phones));
