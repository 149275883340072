import _ from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Button, Form, Header, Icon, Label } from 'semantic-ui-react';
import Page from '../components/Page';
import { log } from '../util/api';
/* const shoplist = [
	{
		title: 'Gourmet Beverages',
		name: 'Single Espresso',
		price: 1.7,
		image: 'https://cdnydm.com/media/2_CPZhahrnR2SopUPVrq-A.jpg?size=1280x851',
	},
	{
		title: 'Gourmet Beverages',
		name: 'Cho Espresso2',
		price: 1.7,
		image: 'https://cdnydm.com/media/2_CPZhahrnR2SopUPVrq-A.jpg?size=1280x851',
	},
	{
		title: 'Gourmet Beverages',
		name: 'Single Espresso3',
		price: 1.7,
		image: 'https://cdnydm.com/media/2_CPZhahrnR2SopUPVrq-A.jpg?size=1280x851',
	},
	{
		title: 'Gourmet Beverages',
		name: 'Single Espresso4',
		price: 1.7,
		image: 'https://cdnydm.com/media/2_CPZhahrnR2SopUPVrq-A.jpg?size=1280x851',
	},

	{
		title: 'Cold Beverages',
		name: 'Chocolate',
		price: '0.5',
		image: 'https://cdnydm.com/media/2_CPZhahrnR2SopUPVrq-A.jpg?size=1280x851',
	},
	{
		title: 'Cold Beverages',
		name: 'Chocolate2',
		price: 1.7,
		image: 'https://cdnydm.com/media/2_CPZhahrnR2SopUPVrq-A.jpg?size=1280x851',
	},
	{
		title: 'Cold Beverages',
		name: 'Chocolate3',
		price: 1.7,
		image: 'https://cdnydm.com/media/2_CPZhahrnR2SopUPVrq-A.jpg?size=1280x851',
	},
]; */
class ShoppingList extends Component {
	state = {
		search_text: '',
		cart: [],
		cartShow: 'noShow',
		pid: window.location.pathname.replace('/shoppinglist/', ''),
		loading: true,
		shoplist: [],
	};

	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	handleChangeCart = (name) => {
		let { cart } = this.state;
		let isOnCart = _.find(cart, { name });
		if (isOnCart) {
			isOnCart.quantity = isOnCart.quantity + 1;
			this.setState({ cart });
		} else {
			let pro = _.find(this.state.shoplist, (it) => it.data.name === name);
			pro.data.quantity = 1;
			cart.push(pro.data);
			this.setState({ cart });
		}
	};
	handleChangeQuantity = (name, f) => {
		let { cart } = this.state;
		let product = _.find(cart, { name });
		if (f === 'plus') {
			product.quantity = product.quantity + 1;
			this.setState({ cart });
		} else {
			if (product.quantity === 1) {
				cart = _.pull(cart, product);
				//product.quantity = product.quantity + 1;
			} else {
				product.quantity = product.quantity - 1;
			}
			this.setState({ cart });
		}
	};
	handleChangeQuantityInput = (e, { name, value }) => {
		let { cart } = this.state;
		let product = _.find(cart, (it) => it.name === name);
		if (parseFloat(value) <= 0) cart = _.pull(cart, product);
		else product.quantity = parseFloat(value);
		this.setState({ cart });
	};
	componentDidMount() {
		let { pid } = this.state;
		log('/api/readItems', { where: { pid } }).then((data) => {
			let shoplist = data.data.data;
			this.setState({ shoplist });
		});
		log('/api/getUserExtra', { pid }).then((data) => {
			if (data.data.success && data.data.data.wcart)
				this.setState({ user: data.data.data.wcart, wcart_category: _.filter(data.data.data.category, { status: true }), loading: false });
			else this.setState({ nouser: true });
		});
	}
	render() {
		let { search_text, username, address1, cart, special, cartShow, loading, shoplist, user, wcart_category, nouser } = this.state;
		if (nouser) {
			return <Redirect to="/"></Redirect>;
		}
		if (loading) return <Page.Loader></Page.Loader>;
		let regex = new RegExp(search_text.toLowerCase());
		let minDelivery = parseFloat(user.mindelivery);
		let minFreeDelivery = parseFloat(user.minfreedelivery);
		let deliveryFee = parseFloat(user.deliveryfee);
		let currency = user.currency;
		let phone = user.phone;
		let discount = parseFloat(user.discount) / 100;
		let s_discount = 1 - parseFloat(user.discount) / 100;
		let filteredList = search_text ? _.filter(shoplist, (o) => regex.test(o.data.name.toLowerCase())) : shoplist;
		let subtotal = _.sumBy(cart, (o) => o.quantity * o.price);
		let totalQuantity = _.sumBy(cart, (o) => o.quantity);
		let deliveryCost = _.sumBy(cart, (o) => o.quantity * o.price) < minFreeDelivery ? deliveryFee : 0;
		let wpMessage = `Merhaba, Sipariş oluşturmak istiyorum.👇
--------
${_.map(cart, (it) => '▪ ' + it.quantity + ' x ' + it.name + ' ' + currency + it.price + '\n')
	.toString()
	.replace(/,/gm, '')}
--------
*Not:*${special || ''}
--------
Ürünler Toplam: ${currency} ${subtotal.toFixed(2)}
${user.discount ? `İndirim(${user.discount}%): ${currency} ${(subtotal * discount).toFixed(2)}` : ''}
Sepet Tutarı: ${currency} ${(subtotal * s_discount).toFixed(2)}
Taşıma Ücreti: ${currency} ${deliveryCost}
*Toplam:* *${currency} ${(subtotal * s_discount + deliveryCost).toFixed(2)}*
--------
İsim: ${username || ''}
Adres: ${address1 || ''}`;
		//console.log(_.map(cart, (it) => '▪' + it.quantity + ' x ' + it.name + ' ₺' + it.price + '\n'));
		return (
			<div className="shoplist">
				<div className="list">
					<div style={{ display: 'flex' }}>
						{/* <img src={user.logo} alt={user.logo} width="40"></img> */}
						<div>
							<p style={{ marginLeft: '10px' }}>{user.shopname}</p>
						</div>
					</div>

					<h3>Ürünler</h3>
					<Form>
						<Form.Input
							icon="search"
							iconPosition="left"
							placeholder="Search"
							value={search_text}
							name="search_text"
							onChange={this.handleChange}
						/>
					</Form>
					<br />
					{_.map(wcart_category, (it) => {
						let product_list = _.filter(filteredList, (oo) => {
							return oo.data.title === it.name && oo.data.deleted === false && oo.data.status;
						});
						return (
							<div className="list-block">
								{product_list && product_list.length ? <Header className="title">{it.name}</Header> : ''}
								<div className="product-block">
									{_.map(product_list, (o, oi) => (
										<div className="product">
											<div className="pro-image">
												<img src={o.data.image} alt={o.data.image} />
											</div>
											<div className="name">
												<p style={{ margin: '0' }}>
													{o.data.name}
													{_.find(cart, { name: o.data.name }) && (
														<Label size="mini" style={{ marginLeft: '10px' }}>
															x{_.find(cart, { name: o.data.name }).quantity}
														</Label>
													)}
												</p>
												<p style={{ color: 'grey' }}>{currency + ' ' + o.data.price}</p>
											</div>
											<div className="btn">
												<Button basic style={{ margin: 'auto' }} size="mini" onClick={() => this.handleChangeCart(o.data.name)}>
													Ekle
												</Button>
											</div>
										</div>
									))}
								</div>
							</div>
						);
					})}
				</div>
				<div className={`orderlist ${cartShow}`}>
					<Header className="title" textAlign="center">
						Sepet
						<div
							className="close-modal"
							onClick={() => {
								this.setState({ cartShow: 'noShow' });
							}}
						>
							<Icon name="close"></Icon>
						</div>
					</Header>
					<div className="address">
						<Form>
							<Form.Input label="İsim" placeholder="İsim" value={username} name="username" onChange={this.handleChange} required />
							{/* <Form.Input label="Your Phone" placeholder="Your phone" value={userphone} name="userphone" onChange={this.handleChange} required /> */}
							<Form.TextArea
								label="Adres Satırı"
								placeholder="Tam Adres Girin"
								value={address1}
								name="address1"
								onChange={this.handleChange}
								required
							/>
							{/* <Form.Input
								label="Adres Satırı 2"
								placeholder="Landmark / City / Postal code"
								value={address2}
								name="address2"
								onChange={this.handleChange}
								required
							/> */}
						</Form>
					</div>
					<div className="cart">
						{cart.length ? (
							<div>
								<div className="cart-block">
									{_.map(cart, (o) => (
										<div className="cart-product">
											<div className="pro-image">
												<img src={o.image} alt={o.image} />
											</div>
											<div className="name">
												<p>{o.name}</p>
												<p style={{ color: 'grey' }}>{currency + ' ' + o.price}</p>
											</div>
											<div className="count">
												<Form>
													<Form.Input
														value={o.quantity}
														style={{ width: '60px' }}
														name={o.name}
														onChange={this.handleChangeQuantityInput}
													/>
												</Form>
												<Button.Group size="mini">
													<Button icon="minus" onClick={() => this.handleChangeQuantity(o.name, 'minus')} />
													<Button icon="plus" onClick={() => this.handleChangeQuantity(o.name, 'plus')} />
												</Button.Group>
											</div>
										</div>
									))}
								</div>
								<div className="dive" />
								<div className="cart-details">
									<div className="price-div">
										<p>Ara Toplam</p>
										<p>{currency + ' ' + subtotal.toFixed(2)}</p>
									</div>
									{user.discount ? (
										<div className="price-div">
											<p>İndirim (%{user.discount})</p>
											<p>{currency + ' ' + (subtotal * discount).toFixed(2)}</p>
										</div>
									) : (
										''
									)}

									<div className="price-div">
										<p>Sepet Tutarı</p>
										<p>{currency + ' ' + (subtotal * s_discount).toFixed(2)}</p>
									</div>
									<div className="price-div">
										<p>Taşıma Ücreti</p>
										<p>{(subtotal * s_discount).toFixed(2) < minFreeDelivery ? currency + ' ' + user.deliveryfee : '0'}</p>
									</div>
									<div className="price-div">
										<p>Toplam</p>
										<p>{currency + ' ' + (subtotal * s_discount + deliveryCost).toFixed(2)} </p>
									</div>
								</div>
								<div className="cart-info" style={{ display: (subtotal * s_discount).toFixed(2) < minFreeDelivery ? 'block' : 'none' }}>
									{(subtotal * s_discount).toFixed(2) < minDelivery ? (
										<div>
											<p>Minimum sepet tutarı {minDelivery + currency}'dir.</p>
											<p>Sepete {(minDelivery - subtotal * s_discount).toFixed(2) + currency} daha ekle.</p>
										</div>
									) : (subtotal * s_discount).toFixed(2) < minFreeDelivery ? (
										<div>
											<p>Ücretsiz teslimat için {(minFreeDelivery - subtotal * s_discount).toFixed(2) + currency} daha ekle.</p>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						) : (
							<p style={{ margin: '0', color: 'gray', padding: '10px', textAlign: 'center' }}>Sepet Boş</p>
						)}
					</div>
					<div className="special">
						<Form>
							<Form.Input label="Sipariş Notu (opsiyonel)" value={special} name="special" onChange={this.handleChange}></Form.Input>
							<p>Bilgilerinizi asla başkalarıyla paylaşmayacağız.</p>
						</Form>
					</div>
					<div className="whatsapp">
						<Button
							disabled={!cart.length || !username || !address1 || (subtotal * s_discount).toFixed(2) < 20}
							color="green"
							as="a"
							href={`https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(wpMessage)}`}
							target="_blank"
						>
							<Icon name="whatsapp" /> Sipariş Ver
						</Button>
					</div>
				</div>
				<div
					className="mobile-cart"
					onClick={() => {
						this.setState({ cartShow: 'show' });
					}}
				>
					<div className="fixd-btm-cart">
						<h3 style={{ marginTop: '10px', fontSize: '26px', width: '80%' }}>Sepetin</h3>
						<div style={{ textAlign: 'right', width: '100%' }}>
							<p style={{ marginBottom: '0', fontSize: '16px' }}>
								<span className="cart-total-quantity">{totalQuantity + ' ürün(ler)'}</span>
							</p>
							<p style={{ marginBottom: '0', fontSize: '16px' }}>
								<span className="cart-total-amount">{(subtotal * s_discount + 5).toFixed(2)}</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const stateToProps = (state) => ({ product: state.product.live, list: state.product.list, user: state.access.user.loggedUserObj });
export default connect(stateToProps)(withTranslation()(ShoppingList));
