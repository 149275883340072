import React, { Component } from 'react';
import { Table, Breadcrumb, Dimmer, Loader, Grid, Segment, Label, Divider, Header, Icon, Tab } from 'semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { post } from '../../util/api';
import MyMenu from './menu';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const Plan_Package = [
	{
		text: 'Start',
		name: 'start_monthly',
		price: 24,
		value: '595965',
	},
	{
		text: 'Grow',
		name: 'grow_monthly',
		price: 36,
		value: '595967',
	},
	{
		text: 'Scale',
		name: 'scale_monthly',
		price: 48,
		value: '595969',
	},
];
const TableLine = (data) =>
	_.map(data, (val, index) => (
		<Table.Row key={val.owner_id}>
			<Table.Cell>{index + 1}</Table.Cell>
			<Table.Cell>{val && val.owner_id}</Table.Cell>
			<Table.Cell>{val && val.owner_email}</Table.Cell>
			<Table.Cell>
				{val.package === 'trial-reseller'
					? 'Trial'
					: val && _.find(Plan_Package, { value: val.real_package }) && _.find(Plan_Package, { value: val.real_package }).text}
			</Table.Cell>
			<Table.Cell>{val && val.agent_limit}</Table.Cell>
			<Table.Cell>{val && val.phone_limit}</Table.Cell>
			<Table.Cell>
				{_.map(val && val.agents, (it) => (
					<p>{it.agent_email}</p>
				))}
			</Table.Cell>
		</Table.Row>
	));
const TableLinePhones = (data) =>
	_.map(data, (val, index) => (
		<Table.Row key={val && val.owner_id}>
			<Table.Cell>{index + 1}</Table.Cell>
			<Table.Cell>{val && val.owner_id}</Table.Cell>
			<Table.Cell>{val && val.owner_email}</Table.Cell>
			<Table.Cell>
				{val.package === 'trial-reseller'
					? 'Trial'
					: val && _.find(Plan_Package, { value: val.real_package }) && _.find(Plan_Package, { value: val.real_package }).text}
			</Table.Cell>
			<Table.Cell>{val && val.phone_limit}</Table.Cell>
			<Table.Cell>
				{val && val.phones && val.phones.length ? (
					<Table>
						{_.map(val && val.phones, (it) => (
							<Table.Row>
								<Table.Cell>{it.id}</Table.Cell>
								<Table.Cell style={{ textAlign: 'center' }}>{it.number || '-----'}</Table.Cell>
								<Table.Cell>{it.status}</Table.Cell>
								<Table.Cell style={{ textAlign: 'center' }}>
									<a
										href={'https://wapi.yapaytech.com/api/' + (val.wapi_pid || val.pid) + '/' + it.id + '/screen?token=' + val.wapi_token}
										target="_blank"
										style={{ margin: 'auto' }}
										rel="noopener noreferrer"
									>
										{'screen '}
										<Icon name="external alternate" />
									</a>
									<a
										href={
											'https://wapi.yapaytech.com/api/' +
											(val.wapi_pid || val.pid) +
											'/' +
											it.id +
											'/factoryreset?token=' +
											val.wapi_token
										}
										target="_blank"
										style={{ margin: 'auto' }}
										rel="noopener noreferrer"
									>
										{'factory reset'}
										<Icon name="redo" />
									</a>
									<a
										href={'https://wapi.yapaytech.com/api/' + (val.wapi_pid || val.pid) + '/' + it.id + '/redeploy?token=' + val.wapi_token}
										target="_blank"
										style={{ margin: 'auto' }}
										rel="noopener noreferrer"
									>
										{'redeploy '}
										<Icon name="redo" />
									</a>
								</Table.Cell>
							</Table.Row>
						))}
					</Table>
				) : null}
			</Table.Cell>
		</Table.Row>
	));
const CardWrap = (props) => (
	<Grid.Column width={props.width}>
		<Segment textAlign="center" style={{ paddingTop: 50, height: 200 }} size="massive">
			<Label size="large" color={props.color} attached="top">
				{props.title}
			</Label>
			<div>
				{props.data}
				{props.unit ? <span style={{ fontSize: '10px' }}>{props.unit}</span> : ''}
			</div>
			{props.note ? <p style={{ fontSize: '10px', padding: '0', margin: '0' }}>{props.note}</p> : ''}
			{props.note2 ? <p style={{ fontSize: '10px', padding: '0', margin: '0' }}>{props.note2}</p> : ''}
			{props.note3 ? <p style={{ fontSize: '10px', padding: '0', margin: '0' }}>{props.note3}</p> : ''}
			<Divider />
			<div style={{ fontSize: '12px' }}>{props.description}</div>
		</Segment>
	</Grid.Column>
);
const MyDivider = (props) => (
	<Divider horizontal className="my-divider">
		<Header as="h4">
			<Icon name={props.icon} />
			{props.name}
		</Header>
	</Divider>
);
class Dashboard extends Component {
	state = { column: null, direction: null, loading: true };
	componentDidMount() {
		this.getData();
	}
	async getData() {
		await post('/reseller/product/dashboard').then((data) => this.setState({ data: data.data.result, loading: false }));
	}
	handleSort = (clickedColumn, data) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				data: _.sortBy(data, [clickedColumn]),
				direction: 'ascending',
			});

			return;
		}

		this.setState({
			data: data.reverse(),
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};
	render() {
		let { loading, data, column, direction } = this.state;
		let { product, t } = this.props;
		let dg = _.map(data, function (it) {
			it = {
				agent_limit: it.agent_limit || 0,
				phone_limit: it.phone_limit || 0,
				owner_id: it.owner_id,
				owner_email: it.owner_email,
				pid: it.pid,
				token: it.token,
				agents: it.agents,
				phones: it.phones,
				package: it.package,
				real_package: it.real_package,
				verified: it.verified,
				total_agent: it.total_agent,
				wapi_pid: it.wapi_pid,
				wapi_token: it.wapi_token,
			};
			return it;
		});

		if (loading) {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/reseller`}>
								{t('Partnership')}
							</Breadcrumb.Section>
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="dashboard">
						<Dimmer active inverted>
							<Loader inverted></Loader>
						</Dimmer>
					</MyMenu>
				</Page>
			);
		}
		let agentLimit = product && product.settings && product.settings.agent_limit;
		let phoneLimit = product && product.settings && product.settings.phone_limit;
		let enterprise = product && product.settings && product.settings.enterprise;
		/* let totalAgents = _.sumBy(
			_.filter(data, function (it) {
				return it.package !== 'trial-start';
			}),
			'agent_limit'
		);
		let totalPhones = _.sumBy(
			_.filter(data, function (it) {
				return it.package !== 'trial-start';
			}),
			'phone_limit'
		); */
		let totalAgents = _.sumBy(data, 'agent_limit');
		let totalPhones = _.sumBy(data, 'phone_limit');
		let trialSubs = _.filter(data, { package: 'trial-reseller' });
		let startSubs = _.filter(data, { real_package: '595965' });
		let growSubs = _.filter(data, { real_package: '595967' });
		let scaleSubs = _.filter(data, { real_package: '595969' });
		let trialSubsCount = _.sumBy(trialSubs, 'agent_limit');
		let startSubsCount = _.sumBy(startSubs, 'agent_limit');
		let growSubsCount = _.sumBy(growSubs, 'agent_limit');
		let scaleSubsCount = _.sumBy(scaleSubs, 'agent_limit');
		let trialPhoneCount = _.sumBy(trialSubs, 'phone_limit');
		let startPhoneCount = _.sumBy(startSubs, 'phone_limit');
		let growPhoneCount = _.sumBy(growSubs, 'phone_limit');
		let scalePhoneCount = _.sumBy(scaleSubs, 'phone_limit');
		let totalPrice = startSubsCount * 24 + growSubsCount * 36 + scaleSubsCount * 48 + (startPhoneCount + growPhoneCount + scalePhoneCount) * 49;
		let totalPriceAgents = startSubsCount * 24 + growSubsCount * 36 + scaleSubsCount * 48;
		let totalPricePhones = (startPhoneCount + growPhoneCount + scalePhoneCount) * 49;
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/reseller`}>
							{t('Partnership')}
						</Breadcrumb.Section>
					</Breadcrumb>
				}
				flex
			>
				<MyMenu activeItem="dashboard">
					<Page.Wrap>
						<Grid stackable columns={2} style={{ width: '100%', margin: 0 }}>
							<MyDivider name={t('Limits')} icon="exclamation"></MyDivider>
							<CardWrap width={3} title={t('AgentLimit')} color="blue" data={agentLimit || 0} description={t('AgentLimitDesc')} />
							<CardWrap width={3} title={t('PhoneLimit')} color="yellow" data={phoneLimit || 0} description={t('PhoneLimitDesc')} />
							<MyDivider name={t('TotalData')} icon="align justify"></MyDivider>
							{enterprise ? null : (
								<CardWrap width={3} title={t('TotalPrice')} color="orange" data={'$' + totalPrice || 0} description={t('TotalPriceDesc')} />
							)}
							<CardWrap
								width={3}
								title={t('TotalAgentNumbers')}
								color="blue"
								data={totalAgents || 0}
								description={t('TotalAgentNumbersDesc')}
								note={t('TrialAgents') + ': ' + trialSubsCount}
							/>
							<CardWrap
								width={3}
								title={t('TotalPhoneNumbers')}
								color="red"
								data={totalPhones || 0}
								description={t('TotalPhoneNumbersDesc')}
								note={t('TrialPhones') + ': ' + trialPhoneCount}
							/>
							{enterprise ? null : (
								<CardWrap
									width={3}
									title={t('TotalAgentPrice')}
									color="green"
									data={'$' + totalPriceAgents || 0}
									description={t('TotalAgentPriceDesc')}
									note={startSubsCount ? startSubsCount + ' Start Agent x $24' : ''}
									note2={growSubsCount ? growSubsCount + ' Grow Agent x $36' : ''}
									note3={scaleSubsCount ? scaleSubsCount + ' Scale Agent x $48' : ''}
								/>
							)}
							{enterprise ? null : (
								<CardWrap
									width={3}
									title={t('TotalPhonePrices')}
									color="yellow"
									data={'$' + totalPricePhones || 0}
									description={t('TotalPhonePricesDesc')}
									note={totalPhones + ' ' + t('phones') + ' x $49'}
								/>
							)}
							<MyDivider name={t('subscriptions')} icon="cart"></MyDivider>
							<CardWrap
								width={3}
								title={t('XPackage', { query: t('Trial') })}
								color="yellow"
								data={trialSubs.length || 0}
								description={t('XPackageDesc', { query: t('trial') })}
								note={t('AgentNumbers') + ': ' + trialSubsCount}
								note2={t('PhoneNumbers') + ': ' + trialPhoneCount}
							/>
							<CardWrap
								width={3}
								title={t('XPackage', { query: 'Start' })}
								color="green"
								data={startSubs.length || 0}
								description={t('XPackageDesc', { query: 'start' })}
								note={t('AgentNumbers') + ': ' + startSubsCount}
								note2={t('PhoneNumbers') + ': ' + startPhoneCount}
							/>
							<CardWrap
								width={3}
								title={t('XPackage', { query: 'Grow' })}
								color="blue"
								data={growSubs.length || 0}
								description={t('XPackageDesc', { query: 'grow' })}
								note={t('AgentNumbers') + ': ' + growSubsCount}
								note2={t('PhoneNumbers') + ': ' + growPhoneCount}
							/>
							<CardWrap
								width={3}
								title={t('XPackage', { query: 'Scale' })}
								color="red"
								data={scaleSubs.length || 0}
								description={t('XPackageDesc', { query: 'scale' })}
								note={t('AgentNumbers') + ': ' + scaleSubsCount}
								note2={t('PhoneNumbers') + ': ' + scalePhoneCount}
							/>
						</Grid>
						<Tab
							menu={{ secondary: true, pointing: true }}
							panes={[
								{
									menuItem: t('general'),
									render: () => (
										<Tab.Pane attached={false}>
											<Table celled sortable>
												<Table.Header>
													<Table.Row>
														<Table.HeaderCell>#</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'owner_id' ? direction : null}
															onClick={this.handleSort('owner_id', dg)}
														>
															Id
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'owner_email' ? direction : null}
															onClick={this.handleSort('owner_email', dg)}
														>
															Email
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'package' ? direction : null}
															onClick={this.handleSort('package', dg)}
														>
															{t('Package')}
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'agent_limit' ? direction : null}
															onClick={this.handleSort('agent_limit', dg)}
														>
															Agent Limit
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'phone_limit' ? direction : null}
															onClick={this.handleSort('phone_limit', dg)}
														>
															{t('PhoneLimit')}
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'agents' ? direction : null}
															onClick={this.handleSort('agents', dg)}
														>
															{t('agents')}
														</Table.HeaderCell>
													</Table.Row>
												</Table.Header>
												<Table.Body>{TableLine(dg)} </Table.Body>
											</Table>
										</Tab.Pane>
									),
								},
								{
									menuItem: t('Phones'),
									render: () => (
										<Tab.Pane attached={false}>
											<Table celled sortable>
												<Table.Header>
													<Table.Row>
														<Table.HeaderCell>#</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'owner_id' ? direction : null}
															onClick={this.handleSort('owner_id', dg)}
														>
															Id
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'owner_email' ? direction : null}
															onClick={this.handleSort('owner_email', dg)}
														>
															Email
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'package' ? direction : null}
															onClick={this.handleSort('package', dg)}
														>
															{t('Package')}
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'phone_limit' ? direction : null}
															onClick={this.handleSort('phone_limit', dg)}
														>
															{t('PhoneLimit')}
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'phones' ? direction : null}
															onClick={this.handleSort('phones', dg)}
														>
															{t('Phones')}
														</Table.HeaderCell>
													</Table.Row>
												</Table.Header>
												<Table.Body>{TableLinePhones(dg)} </Table.Body>
											</Table>
										</Tab.Pane>
									),
								},
							]}
						/>
					</Page.Wrap>
				</MyMenu>
			</Page>
		);
	}
}

const stateToProps = (state) => ({
	product: state.product.reseller,
});

export default connect(stateToProps)(withTranslation()(Dashboard));
