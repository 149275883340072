import React, { Component } from 'react';
import Page from '../../../components/Page';
import { Icon, Breadcrumb, Table, Button, Segment, Step, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import MyMenu from '../menu';
import { connect } from 'react-redux';
import { FacebookProvider, Status, LoginButton } from 'react-facebook';
import 'react-intl-tel-input/dist/main.css';
import { post } from '../../../util/api';
import AccountProgress from '../../../components/AccountProgress';
import { withTranslation } from 'react-i18next';

class PageSeceltor extends Component {
	state = { loading: true, buttonLoading: -1, list: [], error: false };

	async componentDidMount() {
		const [connectedPages, apiresponse] = await Promise.all([
			post('/messenger/register/insta/list', {}).then((data) =>
				_.filter(data.data.result, function (it) {
					return it.accessToken;
				})
			),
			new Promise((cb) => window.FB.api('/me/accounts', cb)),
		]);
		if (apiresponse) this.setState({ connectedPages, loading: false, list: apiresponse.data });
		else this.setState({ connectedPages, loading: false, error: 'etc...' });
	}

	connect = (props) => async () => {
		this.setState({ buttonLoading: props.id });
		await post('/messenger/register/insta/connect', { page_id: props.id, page_access_token: props.token }).then((response) => {
			fetch('/api/dahi/channel/save', {
				method: 'POST',
				body: JSON.stringify({ channel: 'live', data: { set: { staticOptions: { filter_instagram: true } } } }),
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then((response) => {
					return response.json();
				})
				.then(() => {
					this.setState({ buttonLoading: -1 });
					window.location.reload();
				})
				.catch((e) => {
					console.error(e);
					this.setState({ error: true });
				});
		});
	};
	disconnect = (props) => async () => {
		this.setState({ buttonLoading: props.id });
		await post('/messenger/register/insta/disconnect', { page_id: props.id, page_access_token: props.token }).then((response) => {
			this.setState({ buttonLoading: -1 });
			window.location.reload();
		});
	};
	render() {
		/* console.log('sss', this.state.connectedPages, this.state.list); */
		const { t } = this.props;
		//let { error } = this.state;
		if (this.state.loading)
			return (
				<div style={{ textAlign: 'center' }}>
					<Icon name="asterisk" loading></Icon>
					<p>{t('loading')}</p>
				</div>
			);
		return (
			<div>
				<div style={{ textAlign: 'end' }}>
					<LoginButton
						className="ui button instagram"
						scope="instagram_basic,instagram_manage_messages,pages_manage_metadata,instagram_manage_comments, pages_read_engagement"
						onCompleted={console.log}
						onError={console.error}
					>
						<span>{t('changePermission')}</span>
					</LoginButton>
					{/* <Button
						as="a"
						href="https://www.facebook.com/pages/create/?ref_type=site_footer"
						target="_blank"
						positive
						style={{ marginLeft: 'auto', marginRight: '15px' }}
					>
						{t('addPage')}
					</Button> */}
				</div>
				<Table>
					{_.map(this.state.list, (it) => (
						<Table.Row key={it.id}>
							<Table.Cell key={it.id} width={12}>
								<a href={'https://m.me/' + it.id} target="_blank" rel="noopener noreferrer">
									{it.name}
								</a>
							</Table.Cell>
							<Table.Cell key={it.id} style={{ textAlign: 'end' }}>
								{/* {console.log('s', _.find(this.state.connectedPages, { accessToken: it.access_token }))} */}
								<Button
									color={!_.find(this.state.connectedPages, { shop: it.id }) ? 'green' : 'red'}
									loading={this.state.buttonLoading === it.id}
									onClick={
										!_.find(this.state.connectedPages, { shop: it.id })
											? this.connect({ id: it.id, token: it.access_token })
											: this.disconnect({ id: it.id, token: it.access_token })
									}
								>
									{!_.find(this.state.connectedPages, { shop: it.id }) ? t('connect') : t('disconnect')}
								</Button>
							</Table.Cell>
						</Table.Row>
					))}
				</Table>
			</div>
		);
	}
}

class FacebookMessenger extends Component {
	state = {
		loading: false,
		activeStep: 0,
	};
	componentDidMount() {
		post('/messenger/register/integrationCount/instagram').then((data) => this.setState({ connectedCount: data.data.result }));
	}
	render() {
		const { t } = this.props;
		let { connectedCount } = this.state;
		/* console.log(this.props); */
		if (this.state.loading)
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="instagram">
						<Page.Loader />
					</MyMenu>
				</Page>
			);

		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/settings`}>
							<Icon name="setting" />
							{t('settings')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
							{t('integrations')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />
						<Breadcrumb.Section>Instagram Messenger</Breadcrumb.Section>
					</Breadcrumb>
				}
				extra={
					<div className="ml-auto">
						<AccountProgress></AccountProgress>
					</div>
				}
				flex
			>
				<MyMenu activeItem="instagram">
					<Page.Wrap>
						<Segment>
							<FacebookProvider appId="296064065127789">
								<Status>
									{(info) => {
										let activeStep = info.status === 'connected' ? 1 : 0;
										return (
											<div>
												{/* 	{JSON.stringify(info)} */}
												{connectedCount ? (
													<Message info style={{ textAlign: 'center' }}>
														{t('connectedCount', { count: connectedCount })}
													</Message>
												) : (
													''
												)}
												<Message info style={{ textAlign: 'center' }}>
													{t('instagramDesc', { title: window.CONFIG.title })}
												</Message>
												<Step.Group widths={4}>
													<Step active={activeStep === 0} completed={activeStep === 1}>
														<Icon name="instagram" />
														<Step.Content>
															<Step.Title>{t('loginInstagram')}</Step.Title>
														</Step.Content>
													</Step>
													<Step active={activeStep === 1}>
														<Icon name="linkify" />
														<Step.Content>
															<Step.Title>{t('connectToInstagramPage')}</Step.Title>
														</Step.Content>
													</Step>
												</Step.Group>
												{!activeStep && (
													<Segment attached className="login">
														<LoginButton
															className="ui button instagram"
															scope="instagram_basic,instagram_manage_messages,pages_manage_metadata,instagram_manage_comments, pages_read_engagement"
															onCompleted={console.log}
															onError={console.error}
														>
															<span>{t('loginWithInstagram')}</span>
														</LoginButton>
													</Segment>
												)}
												{activeStep ? <PageSeceltor t={t} /> : ''}
											</div>
										);
									}}
								</Status>
							</FacebookProvider>
							<div>
								<img
									src="/images/insta_instruction.png"
									alt="/images/insta_instruction.png"
									width="900"
									style={{ display: 'flex', margin: 'auto' }}
								></img>
							</div>
						</Segment>
					</Page.Wrap>
				</MyMenu>
			</Page>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, product: state.product, location: state.router.location });

export default connect(stateToProps)(withTranslation()(FacebookMessenger));
