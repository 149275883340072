import React, { Component } from 'react';
import { Message, Header, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import 'react-intl-tel-input/dist/main.css';
import { withTranslation } from 'react-i18next';
import Page from '../../components/Page';
import { Link } from 'react-router-dom';
class PlatformModal extends Component {
	state = {
		selectedPlatform: '',
		activePage: 1,
		btnLoading: false,
	};

	render() {
		let { t, user } = this.props;
		const roleAgent = user && user.role === 'agent';
		return (
			<Page.Wrap>
				<Segment>
					{roleAgent ? (
						<Message warning>
							Agent hesabıyla giriş yaptınız,{' '}
							<a href="https://web.whatsapp.com" target="_blank" rel="noopener noreferrer">
								https://web.whatsapp.com
							</a>{' '}
							adresine giderek eklentimiz üzerinden mail adresiniz ve şifreniz ile giriş yapmanız gerekmektedir. Eklentimize aşağıdaki bölümden
							ulaşabilirsiniz.
						</Message>
					) : null}
					<Header>{t('howtoInstall')}</Header>
					{roleAgent ? (
						<div className="howtoinstall">
							<div className="main-step">
								<div className="title">1. Chrome Eklentisini Kurun</div>
								<div className="description">
									<p>
										Chrome tarayıcınıza Dialogtab WhatsApp eklentisini kurmalısınız. Eklentiye{' '}
										<a
											href="https://chrome.google.com/webstore/detail/dialogtab-whatsapp/jjpficnagafofjbfigphjegdnpoibkgp?hl=tr"
											target="_blank"
											rel="noopener noreferrer"
										>
											Chrome uygulama sayfasından
										</a>{' '}
										ulaşabilirsiniz.
									</p>
								</div>
							</div>
							<div className="main-step">
								<div className="title">2. WhatsApp Business</div>
								<div className="description">
									<p>
										Eklentimizin etiket özelliğiniz kullanabilmek için WhatsApp Business uygulumasını uygulama mağazınızdan indirip, telefon
										numaranızı bağlamanız gerekmektedir. Sonrasında Chrome tarayıcısından{' '}
										<a href="https://web.whatsapp.com" target="_blank" rel="noopener noreferrer">
											https://web.whatsapp.com
										</a>{' '}
										adresine gidip qr okutmalısınız. Detaylı bilgi için <Link to="/appstore/help/whatsapp">Yardım</Link> sayfasına
										gidebilirsiniz.
									</p>
								</div>
							</div>
							<div className="main-step">
								<div className="title">3. Etiket Tanımla</div>
								<div className="description">
									<p>
										Etiket özelliğini kullanabilmek için WhatsApp Business uygulamasını kullanmalısınız, WhatsApp Business uygulamasından
										etiket tanımlaması yapmanız gerekmektedir. Nasıl tanımlamanız gerektiğini görmek için{' '}
										<Link to="/appstore/help/label">Yardım</Link> sayfasını inceleyebilirsiniz.
									</p>
								</div>
							</div>
						</div>
					) : (
						<div className="howtoinstall">
							<div className="main-step">
								<div className="title">1. Chrome Eklentisini Kurun</div>
								<div className="description">
									<p>
										Chrome tarayıcınıza Dialogtab WhatsApp eklentisini kurmalısınız. Eklentiye{' '}
										<a
											href="https://chrome.google.com/webstore/detail/dialogtab-whatsapp/jjpficnagafofjbfigphjegdnpoibkgp?hl=tr"
											target="_blank"
											rel="noopener noreferrer"
										>
											Chrome uygulama sayfasından
										</a>{' '}
										ulaşabilirsiniz.
									</p>
								</div>
							</div>
							<div className="main-step">
								<div className="title">2. Agent Ekleyin</div>
								<div className="description">
									<p>
										WhatsApp'ınızı kullanacak olan hesapları <Link to="/appstore/agents">Agentlar</Link> sayfasından oluşturun. Bu
										hesaplarla eklenti üzerinden giriş yapabilirsiniz.
									</p>
								</div>
							</div>
							<div className="main-step">
								<div className="title">3. WhatsApp Business Uygulamasına Geçiş Yapın</div>
								<div className="description">
									<p>
										Uygulamamızı verimli kullanabilmesi için WhatsApp Business uygulamasını uygulama mağazasından indirip, telefon
										numaranızı bağlamanız gerekmektedir. Sonrasında Chrome tarayıcısından{' '}
										<a href="https://web.whatsapp.com" target="_blank" rel="noopener noreferrer">
											https://web.whatsapp.com
										</a>{' '}
										adresine gidip QR okutmalısınız. Detaylı bilgi için <Link to="/appstore/help/whatsapp">Yardım</Link> sayfasına
										gidebilirsiniz.
									</p>
								</div>
							</div>
							<div className="main-step">
								<div className="title">4. Etiket Tanımlayın</div>
								<div className="description">
									<p>
										Etiket özelliğini kullanabilmek için WhatsApp Business uygulamasını kullanmalısınız, WhatsApp Business uygulamasından
										etiket tanımlaması yapmanız gerekmektedir. Nasıl tanımlamanız gerektiğini görmek için{' '}
										<Link to="/appstore/help/label">Yardım</Link> sayfasını inceleyebilirsiniz.
									</p>
								</div>
							</div>
							<div className="main-step">
								<div className="title">5. E-ticaret Entegrasyonu Ayarlayın (Opsiyonel bir adım)</div>
								<div className="description">
									<p>
										Eklentimiz üzerinden E-ticaret özelliklerini kullanabilmeniz için <Link to="/ecommerce">E-ticaret</Link> sayfanızdan
										site servis bilgilerinizi girmeniz gerekmektedir.
									</p>
								</div>
							</div>
							<div className="main-step">
								<div className="title">6. Aboneliğinizi Seçin</div>
								<div className="description">
									<p>
										3 günlük deneme süresi sonunda özelliklerimizi kullanmaya devam edebilmek için <Link to="/appstore">Abonelik</Link>{' '}
										sayfasından istediğiniz özelliğe abone olmalısınız.
									</p>
								</div>
							</div>
						</div>
					)}
				</Segment>
			</Page.Wrap>
		);
	}
}
const stateToProps = (state) => ({ user: state.access.user.loggedUserObj, product: state.product.live });

export default connect(stateToProps)(withTranslation()(PlatformModal));
