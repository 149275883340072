import React, { Component } from 'react';
import { Segment, Container, Button } from 'semantic-ui-react';
import moment from 'moment';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { json2csv } from 'json-2-csv';
import { post } from '../../util/api';

class Analytic extends Component {
	state = {
		loading: true,
		start_date: this.props.start_date,
		end_date: this.props.end_date,
		today: moment(),
		buttonLoading: false,
		pid: /* '1e00a47c670273a623c13c54dba44006' || */ /* 'f1ad8a6a33ef0e4eaf1c12206b64beeb' ||  */ this.props.product.settings.live.token,
	};
	_i = 0;
	async componentDidMount() {
		let { start_date, end_date } = this.state;
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		await post('/dahi/tag/list', {}).then((data) => this.setState({ labels: data.data.result.result }));
		fetch('https://live.dahi.ai/analytics/dialogtab/userList', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
				format: 'csv',
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					usersCsv: data.data,
				});
			});
		fetch('https://live.dahi.ai/analytics/dialogtab/userList', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loading: false,
					users: _.filter(data.data, function (it) {
						return it.name;
					}),
				});
			});
	}

	componentDidUpdate(oldProps, oldState) {
		const check_list = ['start_date', 'end_date', 'selectedAgent'];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			let timer;
			if (key === 'start_date' || key === 'end_date') {
				if (this.props[key] !== oldProps[key]) {
					window.clearTimeout(timer);
					timer = setTimeout(() => this.getData(), 3000);
					break;
				}
			}
			if (this.state[key] !== oldState[key]) {
				window.clearTimeout(timer);
				timer = setTimeout(() => this.getData(), 3000);
				break;
			}
		}
	}

	async getData() {
		this.setState({ loading: true });
		let { start_date, end_date } = this.props;
		this.setState({ buttonLoading: true });
		fetch('https://live.dahi.ai/analytics/dialogtab/userList', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
				format: 'csv',
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					usersCsv: data.data,
				});
			});
		fetch('https://live.dahi.ai/analytics/dialogtab/userList', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loading: false,
					buttonLoading: false,
					users: _.filter(data.data, function (it) {
						return it.name;
					}),
				});
			});
	}
	handlePaginationChange = (e, { activePage }) => this.setState({});
	dateChange = ({ selection }) => {
		let start_date = moment(selection.startDate).startOf('day');
		let end_date = moment(selection.endDate);
		if (end_date.format('YYYY-MM-DD') !== this.state.today.format('YYYY-MM-DD')) {
			end_date = end_date.endOf('day');
		} else {
			end_date = moment();
		}
		this.setState({ start_date, end_date });
	};
	exportExcel = async () => {
		this.setState({ buttonLoading: true });
		let pid = this.state.pid;
		let { labels } = this.state;
		let { start_date, end_date } = this.props;
		let { users } = this.state;

		let excel_data = _.map(users, (o) => {
			let label_texts = (o.tags && _.map(o.tags, (it) => _.find(labels, { _id: it }) && _.find(labels, { _id: it }).title)) || [];
			return {
				isim: o.name,
				Phone: o.phone,
				email: o.email,
				kapatma: o.closedtag,
				tarih: moment(o.createDate).format('DD-MM-YYYY'),
				son_mesaj: moment(o.lastMessageDate).format('DD-MM-YYYY'),
				Etiket: label_texts.join(','),
			};
		});
		let sheets = [
			{
				name: 'userlist',
				x: ['isim', 'Phone', 'email', 'kapatma', 'Etiket', 'tarih', 'son_mesaj'],
				datasets: excel_data,
			},
		];
		await fetch('https://log.yapaytech.com/analytic/download', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				filename: moment(start_date).format('DD-MM-YYYY') + '_' + moment(end_date).format('DD-MM-YYYY') + '_users_info_' + pid,
				sheets,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let dataurl = data.url;
				var a = document.createElement('a');
				a.href = dataurl;
				a.setAttribute('download', dataurl);
				a.click();
				this.setState({ buttonLoading: false });
			});
	};
	exportCsv = async () => {
		this.setState({ buttonLoading: true });
		let { usersCsv } = this.state;
		json2csv(usersCsv, (err, csv) => {
			var a = document.createElement('a');
			a.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
			a.target = '_blank';
			a.download = 'contacts.csv';
			a.click();
			this.setState({ buttonLoading: false });
		});
	};
	exportExcelAll = async () => {
		this.setState({ buttonLoading: true });
		let pid = this.state.pid;
		let { labels } = this.state;
		//let { start_date, end_date } = this.props;
		//let users;
		let users = await fetch('https://live.dahi.ai/analytics/dialogtab/userList', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				full_date: true,
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				return _.filter(data.data, function (it) {
					return it.name;
				});
			});
		let excel_data = _.map(users, (o) => {
			let label_texts = (o.tags && _.map(o.tags, (it) => _.find(labels, { _id: it }).title)) || [];
			return {
				isim: o.name,
				Phone: o.phone,
				email: o.email,
				kapatma: o.closedtag,
				tarih: moment(o.createDate).format('DD-MM-YYYY'),
				son_mesaj: moment(o.lastMessageDate).format('DD-MM-YYYY'),
				Etiket: label_texts.join(','),
			};
		});
		let sheets = [
			{
				name: 'userlist',
				x: ['isim', 'Phone', 'email', 'kapatma', 'Etiket', 'tarih', 'son_mesaj'],
				datasets: excel_data,
			},
		];
		await fetch('https://log.yapaytech.com/analytic/download', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				filename: 'all_time_users_info_' + pid,
				sheets,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let dataurl = data.url;
				var a = document.createElement('a');
				a.href = dataurl;
				a.setAttribute('download', dataurl);
				a.click();
				this.setState({ buttonLoading: false });
			});
	};
	exportCsvAll = async () => {
		this.setState({ buttonLoading: true });
		let usersCsv;
		fetch('https://live.dahi.ai/analytics/dialogtab/userList', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				pid: this.state.pid,
				format: 'csv',
				full_date: true,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				usersCsv = data.data;
				json2csv(usersCsv, (err, csv) => {
					var a = document.createElement('a');
					a.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
					a.target = '_blank';
					a.download = 'contacts.csv';
					a.click();
					this.setState({ buttonLoading: false });
				});
			});
	};
	render() {
		let { t, product } = this.props;
		let isAllDate = product && product.settings && product.settings.analyticView && product.settings.analyticView.is_alldate;
		if (this.state.loading)
			return (
				<Container>
					<Segment style={{ marginBottom: '20px' }}>
						<Page.Loader></Page.Loader>
					</Segment>
				</Container>
			);
		return (
			<Container>
				<Segment style={{ marginBottom: '20px' }}>
					<div style={{ textAlign: 'center' }}>
						<p>{t('downloaduserinfo')}</p>

						<Button icon positive onClick={() => this.exportExcel()} loading={this.state.buttonLoading} disabled={this.state.buttonLoading}>
							{t('DownloadExcel')}
						</Button>
						<Button icon positive onClick={() => this.exportCsv()} loading={this.state.buttonLoading} disabled={this.state.buttonLoading}>
							{t('DownloadCsv')}
						</Button>
					</div>
					{isAllDate ? (
						<div style={{ textAlign: 'center', marginTop: '20px' }}>
							<p>{t('downloaduserinfoAll')}</p>

							<Button icon primary onClick={() => this.exportExcelAll()} loading={this.state.buttonLoading} disabled={this.state.buttonLoading}>
								{t('DownloadExcelall')}
							</Button>
							<Button icon primary onClick={() => this.exportCsvAll()} loading={this.state.buttonLoading} disabled={this.state.buttonLoading}>
								{t('DownloadCsvall')}
							</Button>
						</div>
					) : (
						''
					)}
				</Segment>
			</Container>
		);
	}
}
const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product.live });

export default connect(stateToProps)(withTranslation()(Analytic));