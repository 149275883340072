import React, { useState, useEffect, Component } from 'react';
import Page from '../../../components/Page';
import {
	Icon,
	Breadcrumb,
	Button,
	Modal,
	Header,
	Message,
	Grid,
	Segment,
	Embed,
	List,
	Dropdown,
	Popup,
	Responsive,
	Image,
	Progress,
	Form,
	Input,
	Label,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import AccountProgress from '../../../components/AccountProgress';
import { connect } from 'react-redux';
import { toWapi, get, post } from '../../../util/api';
import MyMenu from '../menu';
import _ from 'lodash';
import * as Sentry from '@sentry/browser';
import { withTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import moment from 'moment';

function usePhoneState(phone, product) {
	const [state, setState] = useState({ id: phone && phone.id });
	const refresh_time = 1000;
	useEffect(() => {
		if (!phone || !product) return;
		let timeout, removed;
		function update() {
			toWapi(`/api/${product.settings.wapi_pid || product.id}/${phone.id}/instance_status`, product.settings.wapi_token).then((response) => {
				if (!response || removed || response.status !== 200) return;

				let { success, status } = response.data;
				if (success) {
					let { replicas = 0, availableReplicas = 0, readyReplicas = 0 } = status;

					setState({ id: phone && phone.id, replicas, availableReplicas, readyReplicas });
					if (!readyReplicas) timeout = setTimeout(update, refresh_time);
				} else timeout = setTimeout(update, refresh_time);
			});
		}
		timeout = setTimeout(update, refresh_time);
		return () => {
			removed = true;
			clearTimeout(timeout);
		};
	}, [phone, product]);
	return state;
}

function StatusBar(props) {
	const state = usePhoneState(props.phone, props.product);
	const [ready, setReady] = useState(props.image_loaded);
	useEffect(() => {
		const timer = setTimeout(() => {
			if (props.image_loaded) setReady(true);
		}, 3000);
		return () => clearTimeout(timer);
	}, [props.image_loaded]);
	if (!state || !props.phone || ready) return null;
	const { replicas, readyReplicas } = state;
	let label = 'loading state';
	let percent = 20;
	if (props.image_loaded) {
		percent = 100;

		label = 'ready';
	} else {
		if (replicas) {
			percent += 30;
			label = 'instance creating';
		}
		if (readyReplicas) {
			percent += 30;
			label = 'instance created';
		}
	}
	return (
		<Segment attached textAlign="center" style={{ padding: 0, overflow: 'hidden', borderBottom: 0 }}>
			<Progress className="phone-progress" size="small" percent={percent} indicating label={label} />
		</Segment>
	);
}

class PhonePreview extends React.Component {
	state = {
		error: null,
		errorInfo: null,
	};

	componentDidCatch(error, errorInfo) {
		this.setState({ error, errorInfo });
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	}

	render() {
		const { error } = this.state;
		const { t } = this.props;
		if (error)
			return (
				<Segment attached="bottom" textAlign="center" style={{ padding: 0, overflow: 'hidden' }}>
					<div>
						<Image
							fluid
							key={this.props.screen_ss}
							onLoad={this.props.imageOnLoad}
							src={this.props.selectedPhone.url + this.props.screen_ss}
							style={this.props.imageLoad ? {} : { width: 0, height: 0, visibility: 'hidden' }}
							alt=""
						/>
					</div>
				</Segment>
			);
		return (
			<Segment attached="bottom" textAlign="center" style={{ padding: 0, overflow: 'hidden' }}>
				{this.props.selectedPhone ? (
					<div>
						<Image
							fluid
							key={this.props.screen_ss}
							onLoad={this.props.imageOnLoad}
							src={this.props.selectedPhone.url + this.props.screen_ss}
							style={this.props.imageLoad ? {} : { width: 0, height: 0, visibility: 'hidden' }}
							alt=""
						/>
						{!this.props.imageLoad && (
							<div
								style={{
									backgroundColor: '#eee',
									height: '540px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									fontSize: '24px',
									fontWeight: '100',
								}}
							>
								<span>
									<Icon loading name="spinner" /> Loading...
								</span>
							</div>
						)}
					</div>
				) : (
					<div
						style={{
							backgroundColor: '#eee',
							height: '540px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							fontSize: '24px',
							fontWeight: '100',
						}}
					>
						{t('selectPhone')}
					</div>
				)}
			</Segment>
		);
	}
}

class Phones extends Component {
	phoneToDelete = null;
	state = {
		currentPhones: 0,
		totalPhones: 0,
		loading: true,
		imageLoad: false,
		imageOnceLoaded: false,
		phones: [],
		refreshBtn: false,
		selectedPhone: null,
		screen_ss: null,
		addBtnClickable: true,
		deleteBtnClicked: false,
		hoveredIndex: null,
		goToSubs: false,
		phoneLoading: false,
		enableBtnClicked: false,
	};

	componentDidMount() {
		let { product } = this.props;
		let pid = product.id;
		let wapiPid = product && product.settings && product.settings.wapi_pid;
		if (product.settings === null || !product.settings.subscription_package || !product.settings.subscription_package) {
			return this.setState({ loading: false });
		}

		toWapi(`/api/${wapiPid || pid}/listPhones`, product.settings.wapi_token)
			.then((data) => {
				if (data.status !== 200) {
					return;
				}
				data = _.orderBy(
					_.filter(data.data, function (it) {
						return it.type === 'whatsapp';
					}),
					'id'
				);
				/* 
				for (let i = 0; i < data.length; i++) {
					data[i].order = i;
				} */
				let currentPhones = data.length;
				let totalPhones = currentPhones;
				if (product.settings && product.settings.phone_limit) {
					totalPhones = product.settings.phone_limit;
				}
				this.setState({ loading: false, phones: data, currentPhones, totalPhones, addBtnClickable: currentPhones < totalPhones });
				if (data && data.length) {
					let first = data[0];
					if (first.status !== 'disabled') {
						this.viewPhoneScreen(first.id, first.number, first.status, first.name, first.multi_device);
					}
				}
			})
			.catch((err) => {
				console.error(err);
				this.setState({ loading: false });
			});
		this._auto_timer = 0;
		this._auto_refresh = setInterval(() => {
			if (this.state && this.state.imageOnceLoaded) {
				this._auto_timer++;
				if (this._auto_timer >= 8) {
					this.screenRefresh();
				}
			}
		}, 1000);
	}
	componentWillUnmount() {
		clearTimeout(this._sr);
		clearInterval(this._auto_refresh);
	}

	addNewPhone = () => {
		let { product } = this.props;
		let pid = product.id;
		let wapiPid = product && product.settings && product.settings.wapi_pid;
		toWapi(`/api/${wapiPid || pid}/addPhone`, product.settings.wapi_token, {})
			.then((data) => {
				if (data.status !== 200) {
					return;
				}
				data = data.data;

				if (data.success === false) {
					return;
				}
				let phones = this.state.phones;
				let newPhone = {
					id: data.id,
					number: data.number,
					multi_device: data.multi_device,
					name: data.name,
					status: 'creating...',
				};
				phones.push(newPhone);
				this.setState({ phones, currentPhones: phones.length, addBtnClickable: this.state.totalPhones > phones.length });
				this.viewPhoneScreen(newPhone.id, newPhone.number, newPhone.status, newPhone.name, newPhone.multi_device);
			})
			.catch((err) => {
				console.error(err);
			});
	};
	setName = () => {
		let { product } = this.props;
		let { selectedPhone, phones } = this.state;
		this.setState({ setnameloading: true });
		toWapi(`/api/${product.id}/${this.state.selectedPhone.id}/setName`, product.settings.wapi_token, { name: this.state.phone_name })
			.then((data) => {
				if (data.status !== 200) {
					return;
				}
				data = data.data;

				if (data.success === false) {
					return;
				}
				let index = _.findIndex(phones, function (it) {
					return it.id === selectedPhone.id;
				});
				phones[index].name = this.state.phone_name;
				this.setState({ phones });
			})
			.catch((err) => {
				console.error(err);
			});
	};
	screenRefresh = (full_refresh) => {
		this._auto_timer = 0;
		this.setState({ refreshBtn: true });
		this._sr = setTimeout(() => {
			this.setState({ refreshBtn: false });
		}, 3000);

		this.setState({
			screen_ss: new Date().getTime(),
			imageLoad: false,
			imageOnceLoaded: full_refresh ? false : this.state.imageOnceLoaded,
		});
	};
	copyClick = () => {
		this.setState({ copy: true });
	};
	logoutPhone = () => {
		let selectedPhone = this.state.selectedPhone;
		let { product } = this.props;
		let wapiPid = product && product.settings && product.settings.wapi_pid;
		toWapi(`/api/${wapiPid || product.id}/${selectedPhone.id}/logout`, product.settings.wapi_token).then((data) => {
			//console.log(data);
			if (data.data.success) {
				this.screenRefresh(true);
			} else {
				console.error(data.data);
			}
		});
	};
	reloadPhone = () => {
		let selectedPhone = this.state.selectedPhone;
		let { product } = this.props;
		let wapiPid = product && product.settings && product.settings.wapi_pid;
		toWapi(`/api/${wapiPid || product.id}/${selectedPhone.id}/restart`, product.settings.wapi_token).then((data) => {
			if (data.data.success) {
				//console.log(data.data.success);
				this.screenRefresh(true);
			}
		});
	};

	redeployPhone = () => {
		this.setState({ refreshBtn: true, redeployModal: false });
		let selectedPhone = this.state.selectedPhone;
		let { product } = this.props;
		let wapiPid = product && product.settings && product.settings.wapi_pid;
		toWapi(`/api/${wapiPid || product.id}/${selectedPhone.id}/redeploy`, product.settings.wapi_token).then((data) => {
			if (data.data.success) {
				//console.log(data.data.success);
				this.screenRefresh();
			}
		});
	};
	factoryResetPhone = () => {
		let selectedPhone = this.state.selectedPhone;
		this.setState({ factoryButtonLoading: true });
		let { product } = this.props;
		toWapi(`/api/${product.id}/${selectedPhone.id}/factoryreset`, product.settings.wapi_token).then((data) => {
			if (data.data.success) {
				//console.log(data.data.success);
				this.setState({ factoryresetmodal: false, factoryButtonLoading: false });
				this.screenRefresh();
			}
		});
	};
	StuckClearPhone = () => {
		let selectedPhone = this.state.selectedPhone;
		let { product } = this.props;
		toWapi(`/api/${product.id}/${selectedPhone.id}/clear`, product.settings.wapi_token).then((data) => {
			if (data.data.success) {
				this.setState({ stuckmodal: false });
				this.screenRefresh(true);
			}
		});
	};
	imageOnLoad = () => {
		this.setState({ imageLoad: true, imageOnceLoaded: true });
	};

	updatePhoneAndImageWhenClicked = () => {
		let selectedPhone = this.state.selectedPhone;
		let { product } = this.props;
		let wapiPid = product && product.settings && product.settings.wapi_pid;
		toWapi(`/api/${wapiPid || product.id}/${selectedPhone.id}/status`, product.settings.wapi_token)
			.then((data) => {
				//console.log(data);
				data = data.data;
				if (data.success) {
					let updatedStatus = 'active';
					if (data.status.connRetry) updatedStatus = 'no internet';
					else if (data.status.isQr) updatedStatus = 'qr-screen';
					else if (data.status.loading) updatedStatus = 'loading';
					else if (data.status.message) updatedStatus = 'phone-error';
					else if (data.status.loggedIn) updatedStatus = 'active';
					else updatedStatus = 'other';
					let phone = _.find(this.state.phones, { id: selectedPhone.id });
					phone.status = updatedStatus;
					phone.number = data.number;
					phone.own_multi_device = data.status.multidevice;
					this.setState({ phones: this.state.phones });
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};
	checkEnabled = (id, number, status, name, multi_device) => {
		let { product } = this.props;
		toWapi(`/api/${product.settings.wapi_pid || product.id}/${id}/enable`, product.settings.wapi_token).then((data) => {
			if (data.data.success) {
				this.viewPhoneScreen(id, number, status, name, multi_device);
			} else {
				this.setState({ disabledModal: true, disabledModalMessage: data.data.message });
			}
		});
	};

	viewPhoneScreen = (id, number, status, name, multi_device) => {
		let { product } = this.props;
		this.setState({
			selectedPhone: {
				id,
				number,
				status,
				multi_device,
				url: `https://wapi.yapaytech.com/api/${product.settings.wapi_pid || product.id}/${id}/screen?token=${product.settings.wapi_token}&time=`,
				// url: `http://localhost:5000/api/${product.id}/${id}/screen?token=${product.settings.wapi_token}&time=`,
			},
			phone_name: name,
		});
		this.screenRefresh(true);
		setTimeout(() => {
			this.updatePhoneAndImageWhenClicked();
		}, 0);
	};

	deletePhone = (id, number, status) => {
		this.phoneToDelete = { id, number, status };
		this.setState({ selectedPhone: null, deleteBtnClicked: true });
	};

	closeDeleteButton = () => {
		this.setState({ deleteBtnClicked: false, goToSubs: false, disabledModal: false, factoryresetmodal: false, redeployModal: false, stuckmodal: false });
	};

	handleDelete = () => {
		let phones = this.state.phones;
		let { product } = this.props;
		let wapiPid = product && product.settings && product.settings.wapi_pid;
		let newPhones = [];
		for (let i = 0; i < phones.length; i++) {
			let phone = phones[i];
			if (phone.id !== this.phoneToDelete.id) {
				newPhones.push(phone);
			}
		}
		this.setState({ deleteBtnClicked: false, phones, currentPhones: phones.length, addBtnClickable: this.state.totalPhones > phones.length });
		toWapi(`/api/${wapiPid || product.id}/${this.phoneToDelete.id}/delete`, product.settings.wapi_token, {})
			.then((data) => {
				//	console.log(data);
				window.location.reload();
			})
			.catch((err) => {
				console.error(err);
			});
	};
	openAddModal = () => {
		this.setState({ goToSubs: true });
	};
	clickCopy = () => {
		this.setState({ cpy: 'Copied' });
		setTimeout(() => {
			this.setState({ cpy: 'Copy Screen Url' });
		}, 2000);
	};
	TableRow = (phonedata, index) => {
		const { t } = this.props;
		let number = phonedata.number;
		let id = phonedata.id;
		let status = phonedata.status;
		let name = phonedata.name;
		let multi_device = phonedata.multi_device;
		let own_multi_device = phonedata.own_multi_device;
		let icon, textColor;
		let { product } = this.props;
		switch (status) {
			case 'active':
				icon = 'check';
				textColor = 'green';
				break;
			case 'qr-screen':
				icon = 'qrcode';
				textColor = 'orange';
				break;
			case 'loading':
				icon = 'clock';
				textColor = 'orange';
				break;
			case 'conn-retry':
				status = 'no internet';
				icon = 'wifi';
				textColor = 'red';
				break;
			case 'TIMEOUT':
			case 'PAIRING':
				icon = 'close';
				textColor = 'orange';
				break;
			case 'phone-error':
				icon = 'close';
				textColor = 'red';
				break;
			case 'low-battery':
				icon = 'battery zero';
				textColor = 'orange';
				break;
			case 'idle':
				icon = 'clock';
				textColor = 'grey';
				break;
			case 'disabled':
				icon = 'dont';
				textColor = 'grey';
				break;
			default:
				//status = 'no calls';
				icon = 'question circle';
				textColor = 'blue';
				break;
		}
		return (
			<List.Item
				key={index}
				active={this.state.selectedPhone && this.state.selectedPhone.id === id}
				style={{ padding: 0, paddingLeft: '0.5em', paddingRight: '0.5em' }}
			>
				<div className="phone-screen">
					<div
						className="phone-line"
						onClick={() => {
							status === 'disabled'
								? this.checkEnabled(id, number, status, name, multi_device)
								: this.viewPhoneScreen(id, number, status, name, multi_device);
						}}
					>
						{multi_device && (
							<div className="multi-device">{<Popup content="Multi-Device" basic trigger={<Image src="/icons/multi-device.svg"></Image>} />}</div>
						)}
						{own_multi_device && !multi_device && (
							<div className="multi-device">
								<Popup content={t('noSupportMulti')} basic trigger={<Icon name="dont" color="red"></Icon>} />
							</div>
						)}
						<div className="phone-id">
							<strong>id: {id}</strong>
						</div>
						<Responsive as="div" className="phone-number" minWidth={Responsive.onlyMobile.maxWidth - 1}>
							<div>
								<Icon name="phone"></Icon> {number || t('newPhone')}
							</div>
							<div>{name && '(' + name + ')'}</div>
						</Responsive>
						<div className="phone-status" style={{ color: textColor }}>
							<Icon name={icon}></Icon>
							{status}
						</div>
					</div>
					{status !== 'disabled' ? (
						<div style={{ textAlign: 'center', color: 'white', whiteSpace: 'nowrap' }}>
							<Popup
								content={this.state.cpy || 'Copy Screen Url'}
								position="bottom right"
								trigger={
									<CopyToClipboard
										text={`https://wapi.yapaytech.com/api/${product.id}/${id}/screen?token=${product.settings.wapi_token}&time=`}
									>
										<Button icon size="tiny" color="teal" onClick={() => this.clickCopy()}>
											<Icon name="copy" style={{ margin: 0 }}></Icon>
										</Button>
									</CopyToClipboard>
								}
							/>
							<Popup
								content="Disable"
								position="bottom right"
								trigger={
									<Button
										icon
										color="red"
										size="tiny"
										onClick={(e) => {
											this.deletePhone(id, number, status);
											e.preventDefault();
											e.stopPropagation();
										}}
									>
										<Icon name="dont"></Icon>
									</Button>
								}
							/>
							{/* <Popup
								content="Redeploy"
								trigger={
									<Button icon size="tiny" onClick={() => this.redeployPhone(id)}>
										<Icon name="redo"></Icon>
									</Button>
								}
							/>
							<Popup
								content="Factory Reset"
								position="bottom right"
								trigger={
									<Button icon size="tiny" onClick={() => this.factoryResetPhone(id)}>
										<Icon name="setting"></Icon>
									</Button>
								}
							/>
							<Popup
								content="Logout"
								position="bottom right"
								trigger={
									<Button icon size="tiny" onClick={() => this.logoutPhone(id)} disabled={status === 'qr-screen' || status === 'idle'}>
										<Icon name="sign-out"></Icon>
									</Button>
								}
							/> */}
						</div>
					) : null}
				</div>
			</List.Item>
		);
	};
	createTrial = (value) => async () => {
		await post('/live/payment/createFreeTrial', { pid: value.pid, plan: value.plan }).then((data) => {
			if (data.data.success) {
				this.setState({ trialSuccess: true, messageOnScreen: data.data.message });
			} else {
				this.refresh();
				this.setState({ messageOnScreen: data.data.message });
			}
		});
	};
	header() {
		var steps = [
			{
				icon: 'user',
				title: 'Profile Settings',
				description: 'Fill user information.',
				sub: {
					name: this.props.user.name,
					company: this.props.user.company,
					phone: this.props.user.phone,
				},
				completed: !!(this.props.user.name && this.props.user.company && this.props.user.phone),
				link: '/settings/account',
				show: true,
			},
			{
				icon: 'bullhorn',
				title: 'Subscribe',
				description: 'Subscribe any package.',
				completed: !!this.props.product.settings.subscription_package,
				link: '/settings/subscription',
				show: true,
			},
			{
				icon: 'bullhorn',
				title: 'Phone',
				description: 'Add first phone.',
				completed: !!this.state.phones.length,
				link: '/phones',
				show: true,
			},
			{
				icon: 'bullhorn',
				title: 'Phone',
				description: 'Active first phone.',
				completed: this.state.phones && this.state.phones[0] && this.state.phones[0].status === 'active',
				link: '/phones',
				show: true,
			},
			{
				icon: 'bullhorn',
				title: 'Agent',
				description: 'Add Agent',
				completed: this.props.product.settings.subscription_package && this.props.product.settings.subscription_package !== 'api',
				link: '/agents',
				show: this.props.product.settings.subscription_package !== 'api',
			},
		];
		const percentage = ((_.filter(steps, { completed: true }).length / steps.length) * 100).toFixed();
		const color = percentage === '100' ? '#21BA45' : '#57BAE4';
		let products = this.props.product;

		var trials = { 'maytapi-trial-api': 'API', 'maytapi-trial-live': 'LiveChat' };
		let subscription = products.settings && products.settings.subscription_package && trials[products.settings.subscription_package];
		let expiredTrialType =
			products.trial === false &&
			products.deletedSubs &&
			products.deletedSubs[0] &&
			products.deletedSubs[0].data.trialType &&
			trials[products.deletedSubs[0].data.trialType];
		let trialEndDate = products.activeSubs[0] && moment(products.activeSubs[0].created_at).startOf('day').add('3', 'days');
		let diffDate = moment(trialEndDate).diff(moment().startOf('day'), 'days');
		return (
			<>
				<Header as="h3">
					<Icon name="mobile alternate" />
					<Header.Content>Maytapi - WhatsApp API</Header.Content>
				</Header>
				{products.trial ? (
					<div className="pageTrial" style={{ display: 'flex', margin: 'auto' }}>
						<p style={{ margin: 'auto' }}>
							{'Your ' + subscription + ' trial will over'} {diffDate > 0 ? diffDate + ' days later.' : 'today'}
						</p>
						<Button
							className="pageMessage"
							as="a"
							href="/settings/subscription"
							positive
							style={{ marginLeft: '10px', marginTop: 'auto', padding: '8px' }}
						>
							Purchase
						</Button>
						<Button className="pageMessage" as="a" href="/contact" primary style={{ marginLeft: '10px', marginTop: 'auto', padding: '8px' }}>
							Contact Us
						</Button>
					</div>
				) : products.trial === false && !(products.activeSubs && products.activeSubs.length) ? (
					<div className="pageTrial" style={{ display: 'flex', margin: 'auto' }}>
						<p style={{ margin: 'auto' }}>{expiredTrialType ? 'Your ' + expiredTrialType + ' trial is over.' : 'Your trial is over.'}</p>
						<Button
							className="pageMessage"
							as="a"
							href="/settings/subscription"
							positive
							style={{ marginLeft: '10px', marginTop: 'auto', padding: '8px' }}
						>
							Purchase
						</Button>
						<Button className="pageMessage" as="a" href="/contact" primary style={{ marginLeft: '10px', marginTop: 'auto', padding: '8px' }}>
							Contact Us
						</Button>
					</div>
				) : (
					''
				)}
				<div style={{ marginLeft: 'auto' }}>
					<Popup
						popperModifiers={{
							preventOverflow: { enabled: false },
						}}
						trigger={
							<div style={{ width: '45px', margin: 'auto 10px auto auto' }}>
								<h1 style={{ color: color }}>%{percentage}</h1>
							</div>
						}
						content={_.map(steps, (it, i) => (
							<div key={i}>
								{it.show ? (
									it.completed ? (
										<p style={{ marginBottom: 0 }}>
											<Icon name={it.completed ? 'check' : 'close'} color={it.completed ? 'green' : 'red'}></Icon>
											{it.description}
											{!it.completed && it.sub ? ' (Enter your ' + _.map(it.sub, (it, i) => (!it ? i : '')).join(' ') + ')' : ''}
										</p>
									) : (
										<a href={it.link} style={{ marginBottom: 0 }}>
											<Icon name={it.completed ? 'check' : 'close'} color={it.completed ? 'green' : 'red'}></Icon>
											{it.description}
											{!it.completed && it.sub
												? ' (Enter your ' +
												  _.map(it.sub, (it, i) => (!it ? i : ''))
														.join(', ')
														.replace(', ,', '') +
												  ')'
												: ''}
										</a>
									)
								) : (
									''
								)}
							</div>
						))}
						on="click"
						position="top right"
						flowing
					/>
				</div>
			</>
		);
	}
	async refreshSubscription() {
		await get('/live/payment/refreshSubscription').then(this.setState({ phoneLoading: false }));
	}
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	render() {
		const { t } = this.props;
		const { product, user } = this.props;
		let host = user.host === null || user.host === 'app.dialogtab.com';
		let trial = product.trial;
		let { phone_name } = this.state;
		if (this.state.loading) {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="whatsapp-web-api">
						<Page.Loader />
					</MyMenu>
				</Page>
			);
		} else if (!this.props.product.settings || !this.props.product.settings.phone_limit) {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />
							<Breadcrumb.Section>{t('webApiSettings')}</Breadcrumb.Section>
						</Breadcrumb>
					}
					extra={
						<div className="ml-auto">
							<AccountProgress></AccountProgress>
						</div>
					}
					flex
				>
					<MyMenu activeItem="whatsapp-web-api">
						<Page.Wrap>
							<Segment>
								<div style={{ textAlign: 'center' }}>
									<div>
										<Icon size="big" name="mobile alternate"></Icon>
									</div>

									{host ? (
										<div>
											<h2>{t('noSubscription')}</h2>
											<p>{t('subscribeToWhatsapp')}</p>
											<Button as={Link} to="/settings/subscription" primary>
												{trial === null ? 'Start Free Trial' : t('goToSubscription')}
											</Button>
										</div>
									) : (
										<div>
											<h2>{t('noSubscription')}</h2>
											<p>{t('pleaseContactUs')}</p>
										</div>
									)}
								</div>
							</Segment>
						</Page.Wrap>
					</MyMenu>
				</Page>
			);
		} else {
			let orderList = _.orderBy(this.state.phones, ['id'], ['asc']);
			const status = this.state.selectedPhone && this.state.selectedPhone.status;
			//console.log(status);
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />
							<Breadcrumb.Section>{t('webApiSettings')}</Breadcrumb.Section>
						</Breadcrumb>
					}
					extra={
						<div className="ml-auto">
							<AccountProgress></AccountProgress>
						</div>
					}
					flex
				>
					<MyMenu activeItem="whatsapp-web-api">
						<Page.Wrap>
							<Grid className="phone-side">
								<Grid.Column className="phone-list" mobile={16} computer={6}>
									<Segment attached="top" color="green" textAlign="center">
										{t('registiredPhones')}
									</Segment>
									<Segment attached textAlign="center" style={{ padding: 5 }}>
										<Button onClick={this.state.addBtnClickable ? this.addNewPhone : this.openAddModal} fluid positive>
											<span style={{ marginRight: 5 }}>{t('addNewPhone')}</span> ({this.state.currentPhones}/{this.state.totalPhones})
										</Button>
									</Segment>
									{this.state.phones && (
										<Segment attached="bottom" piled style={{ padding: 5 }}>
											{this.state.phones.length ? (
												<List selection verticalAlign="middle">
													{_.map(orderList, (data, index) => this.TableRow(data, index))}
												</List>
											) : (
												<p style={{ textAlign: 'center' }}>{t('thereIsNoPhones')}</p>
											)}
										</Segment>
									)}
									<Segment attached="top" color="green" textAlign="center">
										{t('howToConnectPhone')}
									</Segment>
									<Segment attached style={{ padding: '0' }}>
										<Embed placeholder="/whatsapp-placeholder.jpg" url="/whatsapp.mp4"></Embed>
									</Segment>
								</Grid.Column>
								<Grid.Column className="phone-screen" computer={10} mobile={16}>
									<Segment
										attached="top"
										color="green"
										style={{
											padding: '5px',
											height: '49px',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											position: 'relative',
										}}
									>
										{orderList && orderList.length > 1 && this.state.imageLoad && this.state.selectedPhone ? (
											<Form style={{ position: 'absolute', left: 0, marginLeft: '5px' }}>
												<Input name="phone_name" value={phone_name} onChange={this.handleChange} style={{ maxWidth: '170px' }}>
													<Label style={{ display: 'flex', alignItems: 'center' }}>{t('name')}</Label>
													<input maxLength={20} />
													<Button icon type="button" color="teal" onClick={() => this.setName()}>
														<Icon name="save"></Icon>
													</Button>
												</Input>
											</Form>
										) : (
											''
										)}
										{this.state.imageLoad ? (
											this.state.selectedPhone ? (
												t('Screen') + ' [ID:' + this.state.selectedPhone.id + ']'
											) : (
												t('Screen')
											)
										) : this.state.selectedPhone ? (
											<span>
												<Icon loading name="spinner" />{' '}
												{status === 'creating...'
													? t('phoneIsCreating')
													: status === 'no calls'
													? t('phoneIsCreatingAgain')
													: t('phoneLoading')}
											</span>
										) : null}
										<div
											className="buttons"
											style={{
												position: 'absolute',
												right: 0,
												marginRight: '5px',
											}}
										>
											<Popup
												content={t(this.state.cpy) || t('Copy Screen Url')}
												position="bottom right"
												trigger={
													<CopyToClipboard text={this.state.selectedPhone && this.state.selectedPhone.url}>
														<Button
															icon
															disabled={!this.state.selectedPhone || this.state.refreshBtn}
															onClick={() => this.clickCopy()}
														>
															<Icon name="copy" style={{ margin: 0 }}></Icon>
														</Button>
													</CopyToClipboard>
												}
											/>
											<Popup
												content={'Redeploy'}
												position="bottom right"
												trigger={
													<Button
														icon
														onClick={() => {
															this.setState({ redeployModal: true });
														}}
														disabled={!this.state.selectedPhone || this.state.refreshBtn}
													>
														<Icon name="redo" style={{ margin: 0 }}></Icon> {'Redeploy'}
													</Button>
												}
											/>
											<Popup
												content={t('RefreshScreen')}
												position="bottom right"
												trigger={
													<Button
														icon
														onClick={() => this.screenRefresh(true)}
														disabled={!this.state.selectedPhone || this.state.refreshBtn}
													>
														<Icon name="sync" style={{ margin: 0 }}></Icon> {t('RefreshScreen')}
													</Button>
												}
											/>

											<Dropdown icon="bars" button className="icon" direction="left" disabled={!this.state.selectedPhone}>
												<Dropdown.Menu>
													{/* 	<Dropdown.Item icon="sync" text="Refresh" onClick={this.screenRefresh} /> */}
													<Dropdown.Item
														icon="redo"
														text="Redeploy"
														onClick={() => {
															this.setState({ redeployModal: true });
														}}
													/>
													<Dropdown.Item
														icon="redo"
														text={t('factoryReset')}
														onClick={() => {
															this.setState({ factoryresetmodal: true });
														}}
													/>
													<Dropdown.Item
														icon="question"
														text={t('browserStuck')}
														onClick={() => this.setState({ stuckmodal: true })}
													/>
													<Dropdown.Item
														icon="sign-out"
														text={t('Logout')}
														onClick={this.logoutPhone}
														disabled={status === 'qr-screen'}
													/>
												</Dropdown.Menu>
											</Dropdown>
										</div>
									</Segment>
									<StatusBar
										key={'status-' + this.state.screen_ss}
										image_loaded={this.state.imageOnceLoaded}
										product={product}
										phone={this.state.selectedPhone}
									/>
									<PhonePreview
										screen_ss={this.state.screen_ss}
										imageOnLoad={this.imageOnLoad}
										selectedPhone={this.state.selectedPhone}
										imageLoad={this.state.imageLoad}
										t={t}
									/>
								</Grid.Column>
							</Grid>
							<Message hidden={!!_.find(orderList, { status: 'active' })} warning style={{ textAlign: 'center' }}>
								{t('noScanMessage')}
							</Message>
						</Page.Wrap>
					</MyMenu>
					{/*go to disable*/}
					<Modal open={this.state.deleteBtnClicked} onClose={this.closeDeleteButton} style={{ width: 500 }}>
						<Header icon="dont" content={t('deletePhone')} />
						<Modal.Content>
							<p>{t('areYouSureDeletePhone')}</p>
						</Modal.Content>
						<Modal.Actions>
							<Button color="red" onClick={this.closeDeleteButton}>
								<Icon name="remove" /> {t('no')}
							</Button>
							<Button color="green" onClick={this.handleDelete}>
								<Icon name="checkmark" /> {t('yes')}
							</Button>
						</Modal.Actions>
					</Modal>
					{/* {/*go to enable
					<Modal open={this.state.enableBtnClicked} onClose={this.closeDeleteButton} style={{ width: 500 }}>
						<Header icon="mobile alternate" content={t('enablePhone')} />
						<Modal.Content>
							<p>{t('areYouSureEnablePhone')}</p>
						</Modal.Content>
						<Modal.Actions>
							<Button color="red" onClick={this.closeDeleteButton}>
								<Icon name="remove" /> {t('no')}
							</Button>
							<Button
								color="green"
								onClick={() => this.checkEnabled(this.state.toEnable.id, this.state.toEnable.number, this.state.toEnable.status)}
							>
								<Icon name="checkmark" /> {t('yes')}
							</Button>
						</Modal.Actions>
					</Modal> */}
					{/*go to subs modal*/}
					<Modal open={this.state.goToSubs} onClose={this.closeDeleteButton} style={{ width: 500 }}>
						<Header icon="mobile alternate" content={t('phoneLimit')} />
						<Modal.Content>
							<p>{t('notEnoughPhoneLimit')}</p>
							<p>{t('doYouUpdatePhone')}</p>
						</Modal.Content>
						<Modal.Actions>
							<Button color="red" onClick={this.closeDeleteButton}>
								<Icon name="remove" /> {t('no')}
							</Button>
							<Button color="green" as={Link} to="/settings/subscription">
								<Icon name="checkmark" /> {t('yes')}
							</Button>
						</Modal.Actions>
					</Modal>
					{/*disabled modal*/}
					<Modal open={this.state.disabledModal} onClose={this.closeDeleteButton} style={{ width: 500 }}>
						<Header icon="dont" content={t('enabledError')} />
						<Modal.Content>
							<p>{this.state.disabledModalMessage}</p>
						</Modal.Content>
						<Modal.Actions>
							<Button color="green" as={Link} to="/settings/subscription">
								{t('gotoSubs')}
							</Button>
						</Modal.Actions>
					</Modal>
					{/*factoryreset modal*/}
					<Modal open={this.state.factoryresetmodal} onClose={this.closeDeleteButton} style={{ width: 500 }}>
						<Header icon="dont" content={t('factoryReset')} />
						<Modal.Content>
							<p>{t('areYouSureFactoryReset')}</p>
						</Modal.Content>
						<Modal.Actions>
							<Button color="red" onClick={this.closeDeleteButton}>
								<Icon name="remove" /> {t('no')}
							</Button>
							<Button color="green" onClick={this.factoryResetPhone} loading={this.state.factoryButtonLoading}>
								<Icon name="checkmark" /> {t('yes')}
							</Button>
						</Modal.Actions>
					</Modal>
					{/*stuck modal*/}
					<Modal open={this.state.stuckmodal} onClose={this.closeDeleteButton}>
						<Header icon="question" content={t('browserStuck')} />

						<Modal.Content>
							<p>{t('stuckDesc')}</p>
							<p>{t('stuckQues')}</p>
						</Modal.Content>
						<Modal.Actions>
							<Button color="red" onClick={this.closeDeleteButton}>
								<Icon name="remove" /> {t('no')}
							</Button>
							<Button color="green" onClick={this.StuckClearPhone}>
								<Icon name="checkmark" /> {t('yes')}
							</Button>
						</Modal.Actions>
					</Modal>
					{/*redeploy modal*/}
					<Modal open={this.state.redeployModal} onClose={this.closeDeleteButton} style={{ width: 500 }}>
						<Header icon="dont" content={t('redeploy')} />
						<Modal.Content>
							<p>{t('areYouSureRedeploy')}</p>
						</Modal.Content>
						<Modal.Actions>
							<Button color="red" onClick={this.closeDeleteButton}>
								<Icon name="remove" /> {t('no')}
							</Button>
							<Button color="green" onClick={this.redeployPhone}>
								<Icon name="checkmark" /> {t('yes')}
							</Button>
						</Modal.Actions>
					</Modal>
				</Page>
			);
		}
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.live,
	user: state.access.user.loggedUserObj,
});

export default connect(stateToProps)(withTranslation()(Phones));
