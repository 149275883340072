import React, { Component } from 'react';
import Page from '../../components/Page';
import { connect } from 'react-redux';
import Countries from './countries.json';
import { Icon, Table, Button, Segment, Card, Header, Divider, Modal, Dropdown, Radio, Form, Breadcrumb } from 'semantic-ui-react';
//import { Link, Prompt, Route, matchPath, NavLink } from 'react-router-dom';
import { post } from '../../util/api';
import { load_product } from '../../actions/product.actions';
import _ from 'lodash';
import moment from 'moment';
import MyMenu from './menu';
import { Link } from 'react-router-dom';
import AccountProgress from '../../components/AccountProgress';
import { withTranslation } from 'react-i18next';
//import get from 'lodash/get';

/* const LIVE_PLAN_ID = process.env.REACT_APP_LIVE_PLAN_ID;
const API_PLAN_ID = process.env.REACT_APP_API_PLAN_ID;
const AGENT_PLAN_ID = process.env.REACT_APP_AGENT_PLAN_ID;
const PHONE_PLAN_ID = process.env.REACT_APP_PHONE_PLAN_ID; */

const options = [
	{ key: '1', text: '1', value: 1 },
	{ key: '2', text: '2', value: 2 },
	{ key: '3', text: '3', value: 3 },
	{ key: '4', text: '4', value: 4 },
	{ key: '5', text: '5', value: 5 },
	{ key: '6', text: '6', value: 6 },
	{ key: '7', text: '7', value: 7 },
	{ key: '8', text: '8', value: 8 },
	{ key: '9', text: '9', value: 9 },
	{ key: '10', text: '10', value: 10 },
	{ key: '11', text: '11', value: 11 },
	{ key: '12', text: '12', value: 12 },
	{ key: '13', text: '13', value: 13 },
	{ key: '14', text: '14', value: 14 },
	{ key: '15', text: '15', value: 15 },
	{ key: '16', text: '16', value: 16 },
	{ key: '17', text: '17', value: 17 },
	{ key: '18', text: '18', value: 18 },
	{ key: '19', text: '19', value: 19 },
	{ key: '20', text: '20', value: 20 },
];
const startPackageOptions = [
	{ key: '1', text: '1', value: 1 },
	{ key: '2', text: '2', value: 2 },
	{ key: '3', text: '3', value: 3 },
	{ key: '4', text: '4', value: 4 },
	{ key: '5', text: '5', value: 5 },
];
const Package = [
	{
		name: 'Start',
		key: 'start',
		price: 24,
		plan_id: '595965',
	},
	{
		name: 'Grow',
		price: 36,
		key: 'grow',
		plan_id: '595967',
	},
	{
		name: 'Scale',
		key: 'scale',
		price: 48,
		plan_id: '595969',
	},
	{
		name: 'WhatsApp Business API',
		key: 'wbusiness',
		price: 149,
		plan_id: '595973',
	},
	{
		name: 'WhatsApp Web API',
		key: 'wweb',
		price: 49,
		plan_id: '595975',
	},
	{
		name: 'Instagram API',
		key: 'instagram',
		price: 49,
		plan_id: '595975',
	},
];
const AnnualPackage = [
	{
		name: 'Start',
		key: 'start',
		price: 19,
		plan_id: '595966',
	},
	{
		name: 'Grow',
		key: 'start',
		price: 29,
		plan_id: '595968',
	},
	{
		name: 'Scale',
		key: 'scale',
		price: 39,
		plan_id: '595970',
	},
	{
		name: 'WhatsApp Business API',
		key: 'wbusiness',
		price: 119,
		plan_id: '595974',
	},
	{
		name: 'WhatsApp Web API',
		key: 'wweb',
		price: 39,
		plan_id: '595976',
	},
	{
		name: 'Instagram API',
		key: 'instagram',
		price: 39,
		plan_id: '595976',
	},
];
/*const Package = [
	{
		name: 'Start',
		key: 'start',
		price: 24,
		plan_id: '596032',
	},
	{
		name: 'Grow',
		price: 36,
		key: 'grow',
		plan_id: '596034',
	},
	{
		name: 'Scale',
		key: 'scale',
		price: 49,
		plan_id: '596036',
	},
	{
		name: 'WhatsApp Business',
		key: 'wbusiness',
		price: 149,
		plan_id: '595973',
	},
	{
		name: 'WhatsApp Web API',
		key: 'wweb',
		price: 49,
		plan_id: '596036',
	},
];
const AnnualPackage = [
	{
		name: 'Start',
		key: 'start',
		price: 19,
		plan_id: '596033',
	},
	{
		name: 'Grow',
		key: 'start',
		price: 29,
		plan_id: '596035',
	},
	{
		name: 'Scale',
		key: 'scale',
		price: 39,
		plan_id: '596037',
	},
	{
		name: 'WhatsApp Business',
		key: 'wbusiness',
		price: 119,
		plan_id: '595974',
	},
	{
		name: 'WhatsApp Web API',
		key: 'wweb',
		price: 39,
		plan_id: '595098',
	},
];*/
/* const realPackage = {
	'trial-start': '595965',
	'trial-grow': '595965',
	'trial-scale': '595965',
}; */
const planIdName = {
	595965: 'Start - Monthly',
	595967: 'Grow - Monthly',
	595969: 'Scale - Monthly',
	595966: 'Start - Annually',
	595968: 'Grow - Annually',
	595970: 'Scale - Annually',
	595973: 'WhatsApp Business - Monthly',
	595974: 'WhatsApp Business - Annually',
	595975: 'WhatsApp Web API - Monthly',
	595976: 'WhatsApp Web API - Annually',
	'595976_ia': 'Instagram API - Annually',
	'595976_im': 'Instagram API - Annually',
	'trial-start': 'Trial Start',
	'trial-grow': 'Trial Grow',
	'trial-scale': 'Trial Scale',
};
const LABELS_En = [
	{ key: 'annual', label: 'Annually', color: 'green', parse: 'float' },
	{ key: 'monthly', label: 'Monthly', color: 'green' },
];
const LABELS_Tr = [
	{ key: 'annual', label: 'Yıllık', color: 'green', parse: 'float' },
	{ key: 'monthly', label: 'Aylık', color: 'green' },
];
const MyDivider = (props) => (
	<Divider horizontal className="my-divider" style={{ width: '100%', marginLeft: '0' }}>
		<Header as="h4">
			<Icon name={props.icon} />
			{props.name}
		</Header>
	</Divider>
);
class Subscription extends Component {
	state = {
		loading: true,
		loadingStart: false,
		trialButtonLoadingStart: false,
		trialSuccess: false,
		quantityUpdate: 1,
		updateModalOpen: false,
		amountPrice: 29,
		previewModal: false,
		planChangeModal: false,
		planChangeModalPreview: false,
		purchaseModal: false,
		unsubscribe: false,
		historyModal: false,
		toggle: 'annual',
		companyName: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.name) || '',
		vatNumber: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.vatNumber) || '',
		companyCountry: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.country) || '',
		companyCity: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.city) || '',
		companyState: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.state) || '',
		companyPostCode: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.postcode) || '',
		companyStreet: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.street) || '',
	};
	refresh() {
		setTimeout(() => {
			this.props.dispatch(load_product('live'));
		}, 1000);
	}
	async componentDidMount() {
		let data = await post('/live/product/getsubscription', { pid: this.props.product.id });
		this.setState({ subscriptions: data.data.subscriptions, loading: false });
	}
	closeTrialModal = () => {
		this.refresh();
	};
	handleFormChange = (e, { name, value }) => this.setState({ [name]: value });
	closeModal = () => {
		this.setState({
			open: false,
			unsubscribe: false,
			openCreate: false,
			openNew: false,
			unsubscribeTrial: false,
			trialSuccess: false,
			previewModal: false,
			planChangeModal: false,
			purchaseModal: false,
			planChangeModalPreview: false,
			historyModal: false,
		});
	};
	openPurchaseModal = (value, price) => {
		this.setState({ purchaseModal: true, selectedPlanId: value, purchasePrice: price });
	};
	openUpdateModal = (value) => {
		this.setState({
			selectedSubs: value.selectedSubs,
			selectedCurQu: value.currentQuantity,
			selectedUserid: value.user_id,
			selectedPlanid: value.plan_id,
			amountPrice: value.price,
			selectedOldQuantity: value.old_quantity,
			updateModalOpen: true,
		});
	};

	updatePayment = (value) => async () => {
		let update_url = value.data.data.update_url;
		window.Paddle.Checkout.open({
			override: update_url,
			passthrough: {
				pid: this.props.product.id,
			},
		});
	};
	openChangePlanModal = (plan_id, subscription_id) => {
		this.setState({ selectedPlanid: plan_id, selectedSubcriptionId: subscription_id, planChangeModal: true });
	};
	openChangePlanModalPreview = (plan_id, subscription_id, quantity) => async () => {
		await post('/live/payment/checkpreview', {
			subscription_id: this.state.selectedSubcriptionId,
			plan_id: this.state.selectedPlanId,
		}).then((data) => {
			if (data.data.success) {
				this.setState({
					previewdata: data.data.response,
					planChangeModalPreview: true,
					selectedChange: plan_id,
					selectedSubcriptionId: subscription_id,
				});
			} else {
				this.setState({ messageOnScreen: data.data.message.message, messageColor: 'red' });
			}
		});
	};
	closeUpdateModal = () => {
		this.setState({ updateModalOpen: false });
	};
	handleAddition = (e, { value }) => {
		this.setState((prevState) => ({
			options: [{ text: value, value }, ...prevState.options],
		}));
	};
	handleChangeUpdate = (e, { value }) => this.setState({ quantityUpdate: value });
	postData(values) {
		let extra = _.merge(this.props.user.extra, {
			company: {
				name: values.companyName,
				vatNumber: values.vatNumber,
				country: values.country,
				state: values.state,
				city: values.city,
				street: values.street,
				postcode: values.postcode,
			},
		});
		post('/auth/updateInfo', {
			id: this.props.user.id,
			company: values.companyName,
			extra,
		});
	}
	checkoutHandler = (quantity, plan, isVat) => async () => {
		this.postData({
			companyName: this.state.companyName,
			vatNumber: this.state.vatNumber,
			country: this.state.companyCountry,
			city: this.state.companyCity,
			state: this.state.companyState,
			street: this.state.companyStreet,
			postcode: this.state.companyPostCode,
		});
		let link = await post('/live/payment/custompaddlelink', {
			isVat,
			plan_id: plan,
			vat_number: this.state.vatNumber,
			vat_company_name: this.state.companyName,
			vat_street: this.state.companyStreet,
			vat_city: this.state.companyCity,
			vat_country: this.state.companyCountry,
			country: this.state.companyCountry || 'BR',
			postcode: this.state.companyPostCode,
			vat_state: this.state.companyState,
			vat_postcode: this.state.companyPostCode,
			email: this.props.access.user.loggedUserObj.email,
			pid: this.props.product.id,
		});
		try {
			let { product = {} } = this.props;
			let email = this.props.access.user.loggedUserObj.email;
			//let country = 'BR';
			if (product && product.id) {
				window.Paddle.Checkout.open({
					override: link.data.response.url,
					email,
					country: this.state.companyCountry || 'BR',
					quantity,
					postcode: this.state.companyPostCode,
					successCallback: (data) => {
						post('/live/payment/checkSubscription', {
							checkout_id: data.checkout.id,
							plan_id: data.product.id,
							user_id: data.user.id,
							pid: this.props.product.id,
							quantity: data.product.quantity,
							email: data.user.email,
							extra: data,
						})
							.then((resp) => {
								if (resp.data.success) {
									this.refresh();
									this.setState({ messageOnScreen: 'Process is successful!', messageColor: 'green' });
								} else {
									this.setState({ messageOnScreen: 'Process cannot be applied!', messageColor: 'red' });
								}
							})
							.catch((err) => {
								console.error(err);
							});
					},
					closeCallback: () => {
						this.setState({ messageOnScreen: 'Your process has been cancelled, see you again soon!', messageColor: 'red' });
					},
				});
			}
		} catch (err) {
			console.error(err);
		}
	};
	createTrial = (value) => async () => {
		await post('/live/payment/createFreeTrial', { pid: value.pid, plan: value.plan }).then((data) => {
			if (data.data.success) {
				this.setState({ trialSuccess: true, messageOnScreen: data.data.message });
			} else {
				this.refresh();
				this.setState({ messageOnScreen: data.data.message });
			}
		});
	};
	openPreviewModal = (value) => async () => {
		let quantity = this.state.quantityUpdate;
		await post('/live/payment/checkpreview', {
			subscription_id: this.state.selectedSubs,
			quantity,
		}).then((data) => {
			if (data.data.success) {
				this.setState({ previewdata: data.data.response, previewModal: true });
			} else {
				this.setState({ messageOnScreen: data.data.message.message, messageColor: 'red', open: false, openNew: false });
			}
		});
	};
	handleChangeNewPackage = (value, key) => this.setState({ newPackage: value, selectedPlanId: key });
	updateAfterAmuntEntered = (value) => async () => {
		//let planId = parseInt(value.plan_id, 10);
		post('/live/payment/updateSubscription', {
			quantity: value.quantity,
			pid: this.props.product.id,
			subscription_id: value.subscription_id,
			old_quantity: value.old_quantity,
			plan_id: value.plan_id,
			user_id: value.user_id,
		}).then((data) => {
			if (data.data.success) {
				this.refresh();
				this.setState({ messageOnScreen: 'Update process is successful!', messageColor: 'green' });
			} else {
				this.setState({ messageOnScreen: 'Update process cannot be applied!', messageColor: 'red' });
			}
		});
		this.closeModal();
	};
	changePlanId = (plan_id, subscription_id, pid, quantity) => async () => {
		this.closeModal();
		await post('/live/payment/updateplanid', {
			subscription_id: this.state.selectedSubcriptionId,
			plan_id: this.state.selectedPlanId,
			pid,
			quantity: this.state.quantityUpdate,
		}).then((data) => {
			if (data.data.success) {
				this.refresh();
				this.setState({ messageOnScreen: 'Update process is successful!', messageColor: 'green' });
			} else {
				this.setState({ messageOnScreen: 'Update process cannot be applied!', messageColor: 'red' });
			}
		});
	};
	getPaymentHistory = (value) => async () => {
		await post('/live/payment/getPaymentHistory', { subscription_id: value.subscription_id, pid: this.state.pid }).then((data) => {
			this.setState({ paymentHistory: _.orderBy(data.data.response, 'id', 'desc'), historyModal: true });
		});
	};
	openSubsModal = (value) => () => {
		this.setState({
			selectedPlanid: value.plan_id,
			selectedUserid: value.user_id,
			selectedQuantity: value.quantity,
			selectedSubs: value.subscription_id,
			unsubscribe: true,
		});
	};
	clickUnSubs = (value) => async () => {
		post('/live/payment/cancelSubscription', {
			pid: this.props.product.id,
			subscription_id: value.subscription_id,
			plan_id: value.plan_id,
			user_id: value.user_id,
			quantity: value.quantity,
		}).then(() => {
			this.refresh();
			this.setState({ messageOnScreen: 'Unsubscription is successful!', messageColor: 'green' });
		});
		this.closeModal();
	};
	button_click = (data) => {
		this.setState({ toggle: data });
	};
	render() {
		const { quantityUpdate } = this.state;
		const { t, i18n } = this.props;
		if (this.state.loading) {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="package">
						<Page.Loader />
					</MyMenu>
				</Page>
			);
		}
		let { product } = this.props;
		let { vatNumber, companyCity, companyCountry, companyName, companyPostCode, companyState, companyStreet } = this.state;
		let isShowWebApi = true || (product && product.settings && product.settings.isShowWebApi);
		let { subscriptions } = this.state;
		let activeSubscriptions = _.filter(subscriptions, function (o) {
			return o.data.status === 'active' || o.data.status === 'trial';
		});
		let usedTrial = _.filter(subscriptions, function (o) {
			return o.trial === false;
		}).length;
		let isTrial = !!_.filter(subscriptions, function (o) {
			return o.trial === true;
		}).length;
		let switchPackage = this.state.toggle === 'annual' ? AnnualPackage : Package;
		let haveWebApi =
			activeSubscriptions &&
			!!_.filter(activeSubscriptions, function (o) {
				return o.data.subscription_plan_id === '595976' || o.data.subscription_plan_id === '595975';
			}).length;
		let haveBusinessApi =
			activeSubscriptions &&
			!!_.filter(activeSubscriptions, function (o) {
				return o.data.subscription_plan_id === '595973' || o.data.subscription_plan_id === '595974';
			}).length;
		if (product.settings.subscription_package === 'custom') {
			return (
				<Page
					header={
						<>
							<Breadcrumb size="big">
								<Breadcrumb.Section as={Link} to={`/settings`}>
									<Icon name="setting" />
									{t('settings')}
								</Breadcrumb.Section>
								<Breadcrumb.Divider icon="right chevron" />

								<Breadcrumb.Section as={Link} to={`/settings/subscription`}>
									{t('subscription')}
								</Breadcrumb.Section>
							</Breadcrumb>
							<AccountProgress></AccountProgress>
						</>
					}
					flex
				>
					<MyMenu>
						<Page.Wrap>
							<Segment style={{ textAlign: 'center', fontSize: '18px' }}>
								<h3 style={{ fontSize: '20px' }}>{t('youHaveEnterprise')}</h3>
								<p>{t('contactForDetails')}</p>
								<p>
									Email: <a href="mailto:info@dialogtab.com">info@dialogtab.com</a>
								</p>
								<p>
									{t('phone')}:
									<a href={'https://wa.me/908503071748?text=I have question about my enterprise subscription.'}>+90 850 307 1748</a>
								</p>
							</Segment>

							<Segment>
								<Table>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>{t('subscription')}</Table.HeaderCell>
											<Table.HeaderCell>{t('agentNumbers')}</Table.HeaderCell>
											<Table.HeaderCell>{t('phoneNumbers')}</Table.HeaderCell>
											<Table.HeaderCell>{t('enddate')}</Table.HeaderCell>
											<Table.HeaderCell>{t('invoice')}</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{_.map(activeSubscriptions, (data, i) => (
											<Table.Row key={i}>
												<Table.Cell>{t('enterprise')}</Table.Cell>
												<Table.Cell>{data.data.quantity}</Table.Cell>
												<Table.Cell>{data.data.phone_quantity}</Table.Cell>
												<Table.Cell>{data.data.end_date}</Table.Cell>
												<Table.Cell>{data.data.invoice_link}</Table.Cell>
											</Table.Row>
										))}
									</Table.Body>
								</Table>
							</Segment>
						</Page.Wrap>
					</MyMenu>
				</Page>
			);
		} else {
			if (!activeSubscriptions.length) {
				if (usedTrial) {
					return (
						<Page
							header={
								<>
									<Breadcrumb size="big">
										<Breadcrumb.Section as={Link} to={`/settings`}>
											<Icon name="setting" />
											{t('settings')}
										</Breadcrumb.Section>
										<Breadcrumb.Divider icon="right chevron" />

										<Breadcrumb.Section as={Link} to={`/settings/subscription`}>
											{t('subscription')}
										</Breadcrumb.Section>
									</Breadcrumb>
									<AccountProgress></AccountProgress>
								</>
							}
							flex
						>
							<MyMenu>
								<Page.Wrap>
									<Segment>
										<div style={{ textAlign: 'center' }}>
											<Button.Group>
												{_.map(i18n.language === 'tr' ? LABELS_Tr : LABELS_En, (it) => (
													<Button
														type="button"
														key={it.key}
														name={it.key}
														color={it.color}
														basic={this.state.toggle !== it.key}
														onClick={() => this.button_click(it.key)}
													>
														{it.label}
													</Button>
												))}
											</Button.Group>
										</div>
										<div>
											<MyDivider name={t('subscriptions')} icon="dollar sign" />
											<Card.Group>
												<Card>
													<Card.Content
														style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
														header={'Start' + t('package')}
													/>
													<Card.Content
														description={
															<div style={{ maxHeight: '200px' }}>
																<div style={{ textAlign: 'center' }}>
																	<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																		${switchPackage[0].price}
																		<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																	</span>
																</div>

																<div style={{ listStyle: 'none' }}>
																	<li>{t('xchannels', { int: 1 })}</li>
																	<li>{t('xMonthRetention', { int: 3 })}</li>
																</div>
															</div>
														}
													/>
													<Card.Content style={{ maxHeight: '80px' }}>
														<Button
															onClick={() => this.openPurchaseModal(switchPackage[0].plan_id, switchPackage[0].price)}
															positive
															fluid
															loading={this.state.trialButtonLoadingLive}
														>
															{t('purchase')}
														</Button>
													</Card.Content>
												</Card>
												<Card>
													<Card.Content
														style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
														header={'Grow' + t('package')}
													/>
													<Card.Content
														description={
															<div style={{ maxHeight: '200px' }}>
																<div style={{ textAlign: 'center' }}>
																	<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																		${switchPackage[1].price}
																		<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																	</span>
																</div>

																<div style={{ listStyle: 'none' }}>
																	<li>{t('xchannels', { int: 3 })}</li>
																	<li>{t('productSearch')}</li>
																	<li>{t('xMonthRetention', { int: 6 })}</li>
																</div>
															</div>
														}
													/>
													<Card.Content style={{ maxHeight: '80px' }}>
														<Button
															onClick={() => this.openPurchaseModal(switchPackage[1].plan_id, switchPackage[1].price)}
															positive
															fluid
															loading={this.state.trialButtonLoadingLive}
														>
															{t('purchase')}
														</Button>
													</Card.Content>
												</Card>
												<Card>
													<Card.Content
														style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
														header={'Scale' + t('package')}
													/>
													<Card.Content
														description={
															<div style={{ maxHeight: '200px' }}>
																<div style={{ textAlign: 'center' }}>
																	<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																		${switchPackage[2].price}
																		<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																	</span>
																</div>

																<div style={{ listStyle: 'none' }}>
																	<li>{t('phoneSupport')}</li>
																	<li>API</li>
																	<li>{t('xMonthRetention', { int: 6 })}</li>
																</div>
															</div>
														}
													/>
													<Card.Content style={{ maxHeight: '80px' }}>
														<Button
															onClick={() => this.openPurchaseModal(switchPackage[2].plan_id, switchPackage[2].price)}
															positive
															fluid
															loading={this.state.trialButtonLoadingLive}
														>
															{t('purchase')}
														</Button>
													</Card.Content>
												</Card>
											</Card.Group>
										</div>

										<div>
											<MyDivider name={t('add-Ons')} icon="add" />
											<Card.Group>
												<Card>
													<Card.Content
														style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
														header="WhatsApp Business API"
													/>
													<Card.Content
														description={
															<div style={{ maxHeight: '200px' }}>
																<div style={{ textAlign: 'center' }}>
																	<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																		${switchPackage[3].price}
																		<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																	</span>
																</div>
															</div>
														}
													/>
													<Card.Content style={{ maxHeight: '80px' }}>
														<Button
															positive
															fluid
															onClick={() => this.openPurchaseModal(switchPackage[3].plan_id, switchPackage[3].price)}
														>
															{t('purchase')}
														</Button>
													</Card.Content>
												</Card>
												{isShowWebApi ? (
													<Card>
														<Card.Content
															style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
															header="WhatsApp Web API"
														/>
														<Card.Content
															description={
																<div style={{ maxHeight: '200px' }}>
																	<div style={{ textAlign: 'center' }}>
																		<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																			${switchPackage[4].price}
																			<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																		</span>
																	</div>
																</div>
															}
														/>
														<Card.Content style={{ maxHeight: '65px' }}>
															<Button
																positive
																fluid
																onClick={() => this.openPurchaseModal(switchPackage[4].plan_id, switchPackage[4].price)}
															>
																{t('purchase')}
															</Button>
														</Card.Content>
													</Card>
												) : (
													''
												)}
												{/* <Card>
													<Card.Content style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }} header="Instagram API" />
													<Card.Content
														description={
															<div style={{ maxHeight: '200px' }}>
																<div style={{ textAlign: 'center' }}>
																	<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																		${switchPackage[5].price}
																		<span style={{ fontSize: '16px', color: '#A9A9A9' }}>/mo</span>
																	</span>
																</div>
															</div>
														}
													/>
													<Card.Content style={{ maxHeight: '65px' }}>
														<Button
															positive
															fluid
															onClick={() => this.openPurchaseModal(switchPackage[5].plan_id, switchPackage[4].price)}
														>
															Purchase
														</Button>
													</Card.Content>
												</Card> */}
											</Card.Group>
										</div>
									</Segment>
								</Page.Wrap>
							</MyMenu>
							{/* trial success modal */}
							<Modal open={this.state.trialSuccess} onClose={this.closeTrialModal} style={{ maxWidth: '500px' }}>
								<Header icon="check" content={t('subscriptionSuccess')} />
								<Modal.Content>
									<p>{t('successTrial')}</p>
									<p>{this.state.messageOnScreen}</p>
								</Modal.Content>
								<Modal.Actions style={{ textAlign: 'center' }}>
									<Button color="red" onClick={this.closeTrialModal}>
										<Icon name="checkmark" /> {t('close')}
									</Button>
								</Modal.Actions>
							</Modal>
							<Modal open={this.state.purchaseModal} onClose={this.closeModal} style={{ maxWidth: '500px' }}>
								<Header icon="exchange" content={t('purchase')} />
								<Modal.Content>
									<div>
										<div style={{ margin: 'auto', marginBottom: '20px' }}>
											<h3>{t('packagePrice', { price: this.state.purchasePrice * this.state.quantityUpdate })}</h3>
											<div>
												<Dropdown
													options={
														this.state.selectedPlanId === '595965' || this.state.selectedPlanId === '595966'
															? startPackageOptions
															: options
													}
													placeholder={t('chooseQuantity')}
													selection
													value={quantityUpdate}
													defaultValue={1}
													onAddItem={this.handleAddition}
													onChange={this.handleChangeUpdate}
												/>
											</div>
											<p>{t('ifDontVat')}</p>
											<Button primary onClick={this.checkoutHandler(this.state.quantityUpdate, this.state.selectedPlanId, false)}>
												{t('iDontCompany')}
											</Button>
										</div>
										<Form onSubmit={this.checkoutHandler(this.state.quantityUpdate, this.state.selectedPlanId, true)}>
											<Form.Input
												label={t('vatNumber')}
												placeholder={t('companyPagePlaceholder', { query: t('vatNumber') })}
												required
												name="vatNumber"
												value={vatNumber}
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Input
												label={t('companyName')}
												required
												name="companyName"
												placeholder={t('companyPagePlaceholder', { query: t('companyName') })}
												value={companyName}
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Input
												label={t('street')}
												required
												name="companyStreet"
												value={companyStreet}
												placeholder={t('companyPagePlaceholder', { query: t('street') })}
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Input
												label={t('city')}
												required
												value={companyCity}
												name="companyCity"
												placeholder={t('companyPagePlaceholder', { query: t('city') })}
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Input
												label={t('state')}
												value={companyState}
												name="companyState"
												placeholder={t('companyPagePlaceholder', { query: t('state') })}
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Dropdown
												label={t('country')}
												placeholder={t('companyPagePlaceholder', { query: t('country') })}
												search
												required
												autoComplete="off"
												fluid
												name="companyCountry"
												value={companyCountry}
												options={Countries}
												selection
												onChange={this.handleFormChange}
											></Form.Dropdown>
											<Form.Input
												label={t('postcode') + ' (**)'}
												placeholder={t('companyPagePlaceholder', { query: t('postcode') })}
												value={companyPostCode}
												required={_.includes(['AU', 'CA', 'DE', 'ES', 'FR', 'GB', 'IN', 'IT', 'NL', 'US'], this.state.country)}
												name="companyPostCode"
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Group>
												<Form.Button style={{ marginLeft: 'auto' }} positive type="submit">
													<Icon name="checkmark" /> {t('next')}
												</Form.Button>
												<Form.Button color="red" onClick={this.closeModal}>
													<Icon name="x" /> {t('close')}
												</Form.Button>
											</Form.Group>
										</Form>
										<span>{t('companyPageLabel')}</span>
									</div>
								</Modal.Content>
							</Modal>
						</Page>
					);
				}
				return (
					<Page
						header={
							<>
								<Breadcrumb size="big">
									<Breadcrumb.Section as={Link} to={`/settings`}>
										<Icon name="setting" />
										{t('settings')}
									</Breadcrumb.Section>
									<Breadcrumb.Divider icon="right chevron" />

									<Breadcrumb.Section as={Link} to={`/settings/subscription`}>
										{t('subscriptions')}
									</Breadcrumb.Section>
								</Breadcrumb>
								<AccountProgress></AccountProgress>
							</>
						}
						flex
					>
						<MyMenu>
							<Page.Wrap>
								<Segment>
									<div style={{ textAlign: 'center' }}>
										<Button.Group>
											{_.map(i18n.language === 'tr' ? LABELS_Tr : LABELS_En, (it) => (
												<Button
													type="button"
													key={it.key}
													name={it.key}
													color={it.color}
													basic={this.state.toggle !== it.key}
													onClick={() => this.button_click(it.key)}
												>
													{it.label}
												</Button>
											))}
										</Button.Group>
									</div>
									<div>
										<MyDivider name={t('subscriptions')} icon="dollar sign" />
										<Card.Group>
											<Card>
												<Card.Content
													style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
													header={'Start' + t('package')}
												/>
												<Card.Content
													description={
														<div style={{ maxHeight: '200px' }}>
															<div style={{ textAlign: 'center' }}>
																<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																	${switchPackage[0].price}
																	<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																</span>
															</div>

															<div style={{ listStyle: 'none' }}>
																<li>{t('xchannels', { int: 1 })}</li>
																<li>{t('xMonthRetention', { int: 3 })}</li>
															</div>
														</div>
													}
												/>
												<Card.Content style={{ maxHeight: '80px' }}>
													<Button
														onClick={this.createTrial({ pid: this.props.product.id, plan: 'start', loadingStart: true })} //createTrial
														positive
														fluid
														loading={this.state.trialButtonLoadingLive}
													>
														{t('start14Trial')}
													</Button>
													<div style={{ textAlign: 'right' }}>
														<Button
															onClick={() => this.openPurchaseModal(switchPackage[0].plan_id, switchPackage[0].price)}
															style={{
																fontSize: '11px',
																color: 'black',
																textDecorationLine: 'underline',
																background: 'white',
																padding: '0',
															}}
														>
															{t('purchaseSkip')}
														</Button>
													</div>
												</Card.Content>
											</Card>
											<Card>
												<Card.Content
													style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
													header={'Grow' + t('package')}
												/>
												<Card.Content
													description={
														<div style={{ maxHeight: '200px' }}>
															<div style={{ textAlign: 'center' }}>
																<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																	${switchPackage[1].price}
																	<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																</span>
															</div>

															<div style={{ listStyle: 'none' }}>
																<li>{t('xchannels', { int: 3 })}</li>
																<li>{t('productSearch')}</li>
																<li>{t('xMonthRetention', { int: 6 })}</li>
															</div>
														</div>
													}
												/>
												<Card.Content style={{ maxHeight: '80px' }}>
													<Button
														onClick={this.createTrial({ pid: this.props.product.id, plan: 'grow', loadingStart: true })} //createTrial
														positive
														fluid
														loading={this.state.trialButtonLoadingLive}
													>
														{t('start14Trial')}
													</Button>
													<div style={{ textAlign: 'right' }}>
														<Button
															onClick={() => this.openPurchaseModal(switchPackage[1].plan_id, switchPackage[1].price)}
															style={{
																fontSize: '11px',
																color: 'black',
																textDecorationLine: 'underline',
																background: 'white',
																padding: '0',
															}}
														>
															{t('purchaseSkip')}
														</Button>
													</div>
												</Card.Content>
											</Card>
											<Card>
												<Card.Content
													style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
													header={'Scale' + t('package')}
												/>
												<Card.Content
													description={
														<div style={{ maxHeight: '200px' }}>
															<div style={{ textAlign: 'center' }}>
																<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																	${switchPackage[2].price}
																	<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																</span>
															</div>

															<div style={{ listStyle: 'none' }}>
																<li>{t('phoneSupport')}</li>
																<li>API</li>
																<li>{t('xMonthRetention', { int: 12 })}</li>
															</div>
														</div>
													}
												/>
												<Card.Content style={{ maxHeight: '80px' }}>
													<Button
														onClick={this.createTrial({ pid: this.props.product.id, plan: 'scale', loadingStart: true })} //createTrial
														positive
														fluid
														loading={this.state.trialButtonLoadingLive}
													>
														{t('start14Trial')}
													</Button>
													<div style={{ textAlign: 'right' }}>
														<Button
															onClick={() => this.openPurchaseModal(switchPackage[2].plan_id, switchPackage[2].price)}
															style={{
																fontSize: '11px',
																color: 'black',
																textDecorationLine: 'underline',
																background: 'white',
																padding: '0',
															}}
														>
															{t('purchaseSkip')}
														</Button>
													</div>
												</Card.Content>
											</Card>
										</Card.Group>
									</div>

									<div>
										<MyDivider name={t('add-Ons')} icon="add" />
										<p style={{ color: 'red', textAlign: 'center' }}>{t('forAddOnsSubscribe')}</p>
										<Card.Group>
											<Card>
												<Card.Content
													style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
													header="WhatsApp Business API"
												/>
												<Card.Content
													description={
														<div style={{ maxHeight: '200px' }}>
															<div style={{ textAlign: 'center' }}>
																<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																	${switchPackage[3].price}
																	<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																</span>
															</div>
														</div>
													}
												/>
												<Card.Content style={{ maxHeight: '80px' }}>
													<Button positive fluid disabled>
														{t('purchase')}
													</Button>
												</Card.Content>
											</Card>
											{isShowWebApi ? (
												<Card>
													<Card.Content
														style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
														header="WhatsApp Web API"
													/>
													<Card.Content
														description={
															<div style={{ maxHeight: '200px' }}>
																<div style={{ textAlign: 'center' }}>
																	<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																		${switchPackage[4].price}
																		<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																	</span>
																</div>
															</div>
														}
													/>
													<Card.Content style={{ maxHeight: '65px' }}>
														<Button positive fluid disabled>
															{t('purchase')}
														</Button>
													</Card.Content>
												</Card>
											) : (
												''
											)}
											{/* <Card>
												<Card.Content style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }} header="Instagram API" />
												<Card.Content
													description={
														<div style={{ maxHeight: '200px' }}>
															<div style={{ textAlign: 'center' }}>
																<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																	${switchPackage[5].price}
																	<span style={{ fontSize: '16px', color: '#A9A9A9' }}>/mo</span>
																</span>
															</div>
														</div>
													}
												/>
												<Card.Content style={{ maxHeight: '65px' }}>
													<Button positive fluid disabled>
														Purchase
													</Button>
												</Card.Content>
											</Card> */}
										</Card.Group>
									</div>
								</Segment>
							</Page.Wrap>
						</MyMenu>
						{/* trial success modal */}
						<Modal open={this.state.trialSuccess} onClose={this.closeTrialModal} style={{ maxWidth: '500px' }}>
							<Header icon="check" content={t('subscriptionSuccess')} />
							<Modal.Content>
								<p>{t('successTrial')}</p>
								<p>{this.state.messageOnScreen}</p>
							</Modal.Content>
							<Modal.Actions style={{ textAlign: 'center' }}>
								<Button color="red" onClick={this.closeTrialModal}>
									<Icon name="checkmark" /> {t('close')}
								</Button>
							</Modal.Actions>
						</Modal>
						<Modal open={this.state.purchaseModal} onClose={this.closeModal} style={{ maxWidth: '500px' }}>
							<Header icon="exchange" content={t('purchase')} />
							<Modal.Content>
								<div>
									<div style={{ margin: 'auto', marginBottom: '20px' }}>
										<h3>{t('packagePrice', { price: this.state.purchasePrice * this.state.quantityUpdate })}</h3>
										<div>
											<Dropdown
												options={
													this.state.selectedPlanId === '595965' || this.state.selectedPlanId === '595966'
														? startPackageOptions
														: options
												}
												placeholder={t('chooseQuantity')}
												selection
												value={quantityUpdate}
												defaultValue={1}
												onAddItem={this.handleAddition}
												onChange={this.handleChangeUpdate}
											/>
										</div>
										<p>{t('ifDontVat')}</p>
										<Button primary onClick={this.checkoutHandler(this.state.quantityUpdate, this.state.selectedPlanId, false)}>
											{t('iDontCompany')}
										</Button>
									</div>
									<Form onSubmit={this.checkoutHandler(this.state.quantityUpdate, this.state.selectedPlanId, true)}>
										<Form.Input
											label={t('vatNumber')}
											placeholder={t('companyPagePlaceholder', { query: t('vatNumber') })}
											required
											name="vatNumber"
											value={vatNumber}
											onChange={this.handleFormChange}
										></Form.Input>
										<Form.Input
											label={t('companyName')}
											required
											name="companyName"
											placeholder={t('companyPagePlaceholder', { query: t('companyName') })}
											value={companyName}
											onChange={this.handleFormChange}
										></Form.Input>
										<Form.Input
											label={t('street')}
											required
											name="companyStreet"
											value={companyStreet}
											placeholder={t('companyPagePlaceholder', { query: t('street') })}
											onChange={this.handleFormChange}
										></Form.Input>
										<Form.Input
											label={t('city')}
											required
											value={companyCity}
											name="companyCity"
											placeholder={t('companyPagePlaceholder', { query: t('city') })}
											onChange={this.handleFormChange}
										></Form.Input>
										<Form.Input
											label={t('state')}
											value={companyState}
											name="companyState"
											placeholder={t('companyPagePlaceholder', { query: t('state') })}
											onChange={this.handleFormChange}
										></Form.Input>
										<Form.Dropdown
											label={t('country')}
											placeholder={t('companyPagePlaceholder', { query: t('country') })}
											search
											required
											autoComplete="off"
											fluid
											name="companyCountry"
											value={companyCountry}
											options={Countries}
											selection
											onChange={this.handleFormChange}
										></Form.Dropdown>
										<Form.Input
											label={t('postcode') + ' (**)'}
											placeholder={t('companyPagePlaceholder', { query: t('postcode') })}
											value={companyPostCode}
											required={_.includes(['AU', 'CA', 'DE', 'ES', 'FR', 'GB', 'IN', 'IT', 'NL', 'US'], this.state.country)}
											name="companyPostCode"
											onChange={this.handleFormChange}
										></Form.Input>
										<Form.Group>
											<Form.Button style={{ marginLeft: 'auto' }} positive type="submit">
												<Icon name="checkmark" /> {t('next')}
											</Form.Button>
											<Form.Button color="red" onClick={this.closeModal}>
												<Icon name="x" /> {t('close')}
											</Form.Button>
										</Form.Group>
									</Form>
									<span>{t('companyPageLabel')}</span>
								</div>
							</Modal.Content>
						</Modal>
					</Page>
				);
			} else {
				if (isTrial) {
					return (
						<Page
							header={
								<>
									<Breadcrumb size="big">
										<Breadcrumb.Section as={Link} to={`/settings`}>
											<Icon name="setting" />
											{t('settings')}
										</Breadcrumb.Section>
										<Breadcrumb.Divider icon="right chevron" />

										<Breadcrumb.Section as={Link} to={`/settings/subscription`}>
											{t('subscription')}
										</Breadcrumb.Section>
									</Breadcrumb>
									<AccountProgress></AccountProgress>
								</>
							}
							flex
						>
							<MyMenu>
								<Page.Wrap>
									<Segment>
										<div style={{ textAlign: 'center' }}>
											<Button.Group>
												{_.map(i18n.language === 'tr' ? LABELS_Tr : LABELS_En, (it) => (
													<Button
														type="button"
														key={it.key}
														name={it.key}
														color={it.color}
														basic={this.state.toggle !== it.key}
														onClick={() => this.button_click(it.key)}
													>
														{it.label}
													</Button>
												))}
											</Button.Group>
										</div>
										<div>
											<MyDivider name={t('subscription')} icon="dollar sign" />
											<Card.Group>
												<Card>
													<Card.Content
														style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
														header={'Start' + t('package')}
													/>
													<Card.Content
														description={
															<div style={{ maxHeight: '200px' }}>
																<div style={{ textAlign: 'center' }}>
																	<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																		${switchPackage[0].price}
																		<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																	</span>
																</div>

																<div style={{ listStyle: 'none' }}>
																	<li>{t('xchannels', { int: 1 })}</li>
																	<li>{t('xMonthRetention', { int: 3 })}</li>
																</div>
															</div>
														}
													/>
													<Card.Content style={{ maxHeight: '80px' }}>
														<Button
															onClick={() => this.openPurchaseModal(switchPackage[0].plan_id, switchPackage[0].price)}
															positive
															fluid
															loading={this.state.trialButtonLoadingLive}
														>
															{t('purchase')}
														</Button>
													</Card.Content>
												</Card>
												<Card>
													<Card.Content
														style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
														header={'Grow' + t('package')}
													/>
													<Card.Content
														description={
															<div style={{ maxHeight: '200px' }}>
																<div style={{ textAlign: 'center' }}>
																	<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																		${switchPackage[1].price}
																		<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																	</span>
																</div>

																<div style={{ listStyle: 'none' }}>
																	<li>{t('xchannels', { int: 3 })}</li>
																	<li>{t('productSearch')}</li>
																	<li>{t('xMonthRetention', { int: 6 })}</li>
																</div>
															</div>
														}
													/>
													<Card.Content style={{ maxHeight: '80px' }}>
														<Button
															onClick={() => this.openPurchaseModal(switchPackage[1].plan_id, switchPackage[1].price)}
															positive
															fluid
															loading={this.state.trialButtonLoadingLive}
														>
															{t('purchase')}
														</Button>
													</Card.Content>
												</Card>
												<Card>
													<Card.Content
														style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
														header={'Scale' + t('package')}
													/>
													<Card.Content
														description={
															<div style={{ maxHeight: '200px' }}>
																<div style={{ textAlign: 'center' }}>
																	<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																		${switchPackage[2].price}
																		<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																	</span>
																</div>

																<div style={{ listStyle: 'none' }}>
																	<li>{t('phoneSupport')}</li>
																	<li>API</li>
																	<li>{t('xMonthRetention', { int: 12 })}</li>
																</div>
															</div>
														}
													/>
													<Card.Content style={{ maxHeight: '80px' }}>
														<Button
															onClick={() => this.openPurchaseModal(switchPackage[2].plan_id, switchPackage[2].price)}
															positive
															fluid
															loading={this.state.trialButtonLoadingLive}
														>
															{t('purchase')}
														</Button>
													</Card.Content>
												</Card>
											</Card.Group>
										</div>

										<div>
											<MyDivider name={t('add-Ons')} icon="add" />
											<Card.Group>
												<Card>
													<Card.Content
														style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
														header="WhatsApp Business API"
													/>
													<Card.Content
														description={
															<div style={{ maxHeight: '200px' }}>
																<div style={{ textAlign: 'center' }}>
																	<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																		${switchPackage[3].price}
																		<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																	</span>
																</div>
															</div>
														}
													/>
													<Card.Content style={{ maxHeight: '80px' }}>
														<Button
															positive
															fluid
															onClick={() => this.openPurchaseModal(switchPackage[3].plan_id, switchPackage[3].price)}
														>
															{t('purchase')}
														</Button>
													</Card.Content>
												</Card>
												{isShowWebApi ? (
													<Card>
														<Card.Content
															style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
															header="WhatsApp Web API"
														/>
														<Card.Content
															description={
																<div style={{ maxHeight: '200px' }}>
																	<div style={{ textAlign: 'center' }}>
																		<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																			${switchPackage[4].price}
																			<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																		</span>
																	</div>
																</div>
															}
														/>
														<Card.Content style={{ maxHeight: '65px' }}>
															<Button
																positive
																fluid
																onClick={() => this.openPurchaseModal(switchPackage[4].plan_id, switchPackage[4].price)}
															>
																{t('purchase')}
															</Button>
														</Card.Content>
													</Card>
												) : (
													''
												)}

												{/* <Card>
													<Card.Content style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }} header="Instagram API" />
													<Card.Content
														description={
															<div style={{ maxHeight: '200px' }}>
																<div style={{ textAlign: 'center' }}>
																	<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																		${switchPackage[5].price}
																		<span style={{ fontSize: '16px', color: '#A9A9A9' }}>/mo</span>
																	</span>
																</div>
															</div>
														}
													/>
													<Card.Content style={{ maxHeight: '65px' }}>
														<Button
															positive
															fluid
															onClick={() => this.openPurchaseModal(switchPackage[5].plan_id, switchPackage[4].price)}
														>
															Purchase
														</Button>
													</Card.Content>
												</Card> */}
											</Card.Group>
										</div>
									</Segment>

									<Segment>
										<Table>
											<Table.Header>
												<Table.Row>
													<Table.HeaderCell>{t('subscription')}</Table.HeaderCell>
													<Table.HeaderCell>{t('quantitys')}</Table.HeaderCell>
													<Table.HeaderCell>{t('trialEndDate')}</Table.HeaderCell>
												</Table.Row>
											</Table.Header>
											<Table.Body>
												{_.map(activeSubscriptions, (data, i) => (
													<Table.Row key={i}>
														<Table.Cell>{planIdName[data.data.subscription_plan_id] || 'Custom'}</Table.Cell>
														<Table.Cell>{data.data.quantity || data.data.new_quantity}</Table.Cell>
														<Table.Cell>{moment(data.created_at).add('14', 'days').format('MM-DD-YYYY')}</Table.Cell>
													</Table.Row>
												))}
											</Table.Body>
										</Table>
									</Segment>
								</Page.Wrap>
							</MyMenu>
							{/*update modal*/}
							<Modal open={this.state.updateModalOpen} onClose={this.closeUpdateModal} style={{ width: 500 }}>
								<Header icon="redo" content={t('updatePackage')} />
								<Modal.Content style={{ textAlign: 'center' }}>
									<p>{t('enterAmount')}</p>
								</Modal.Content>
								<Modal.Content style={{ display: 'flex' }}>
									<Modal.Content style={{ width: 250, textAlign: 'center' }}>
										<Dropdown
											options={
												this.state.selectedPlanId === '595965' || this.state.selectedPlanId === '595966' ? startPackageOptions : options
											}
											placeholder={t('chooseQuantity')}
											selection
											value={quantityUpdate}
											defaultValue={1}
											onAddItem={this.handleAddition}
											onChange={this.handleChangeUpdate}
										/>
									</Modal.Content>
									<Modal.Content style={{ width: 250, textAlign: 'center', margin: 'auto' }}>
										<p>
											{t('totalAmountIs')}: {this.state.quantityUpdate * this.state.amountPrice}$
										</p>
									</Modal.Content>
								</Modal.Content>
								<Modal.Actions style={{ textAlign: 'center' }}>
									<Button color="red" onClick={this.closeUpdateModal}>
										<Icon name="remove" /> {t('back')}
									</Button>
									<Button color="green" onClick={this.openPreviewModal({ add: false })}>
										<Icon name="checkmark" /> {t('proceed')}
									</Button>
								</Modal.Actions>
							</Modal>
							{/*update preview subs  */}
							<Modal open={this.state.previewModal} onClose={this.closeModal} style={{ maxWidth: '700px' }}>
								<Header icon="exchange" content={t('updateSubscription')} />
								<Modal.Content>
									<div style={{ display: 'flex' }}>
										<div style={{ margin: 'auto' }}>
											<h3>{t('immediatePayment')}:</h3>
											<p>
												{t('amount')}: {this.state.previewdata && this.state.previewdata.immediate_payment.amount + ' $'}
											</p>
											<p>
												{t('date')}: {this.state.previewdata && this.state.previewdata.immediate_payment.date}
											</p>
										</div>
										<div style={{ margin: 'auto' }}>
											<h3>{t('nextPayment')}:</h3>
											<p>
												{t('amount')}: {this.state.previewdata && this.state.previewdata.next_payment.amount + ' $'}
											</p>
											<p>
												{t('date')}: {this.state.previewdata && this.state.previewdata.next_payment.date}
											</p>
										</div>
									</div>
								</Modal.Content>
								<Modal.Actions style={{ textAlign: 'left' }}>
									<div style={{ display: 'flex' }}>
										<h3 style={{ marginBottom: 'auto', marginTop: 'auto' }}>
											{t('areYouSureUpdatePlan', { plan: this.state.selectedPlanid })}.
										</h3>
										<Button
											color="green"
											style={{ marginLeft: 'auto' }}
											onClick={this.updateAfterAmuntEntered({
												subscription_id: this.state.selectedSubs,
												user_id: this.state.selectedUserid,
												plan_id: this.state.selectedPlanid,
												quantity: this.state.quantityUpdate,
												old_quantity: this.state.selectedOldQuantity,
											})}
										>
											<Icon name="checkmark" /> {t('yes')}
										</Button>
										<Button color="red" onClick={this.closeModal}>
											<Icon name="x" /> {t('close')}
										</Button>
									</div>
								</Modal.Actions>
							</Modal>
							{/*purchase modal*/}
							<Modal open={this.state.purchaseModal} onClose={this.closeModal} style={{ maxWidth: '500px' }}>
								<Header icon="exchange" content={t('purchase')} />
								<Modal.Content>
									<div>
										<div style={{ margin: 'auto', marginBottom: '20px' }}>
											<h3>{t('packagePrice', { price: this.state.purchasePrice * this.state.quantityUpdate })}</h3>
											<div>
												<Dropdown
													options={
														this.state.selectedPlanId === '595965' || this.state.selectedPlanId === '595966'
															? startPackageOptions
															: options
													}
													placeholder={t('chooseQuantity')}
													selection
													value={quantityUpdate}
													defaultValue={1}
													onAddItem={this.handleAddition}
													onChange={this.handleChangeUpdate}
												/>
											</div>
											<p>{t('ifDontVat')}</p>
											<Button primary onClick={this.checkoutHandler(this.state.quantityUpdate, this.state.selectedPlanId, false)}>
												{t('iDontCompany')}
											</Button>
										</div>
										<Form onSubmit={this.checkoutHandler(this.state.quantityUpdate, this.state.selectedPlanId, true)}>
											<Form.Input
												label={t('vatNumber')}
												placeholder={t('companyPagePlaceholder', { query: t('vatNumber') })}
												required
												name="vatNumber"
												value={vatNumber}
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Input
												label={t('companyName')}
												required
												name="companyName"
												placeholder={t('companyPagePlaceholder', { query: t('companyName') })}
												value={companyName}
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Input
												label={t('street')}
												required
												name="companyStreet"
												value={companyStreet}
												placeholder={t('companyPagePlaceholder', { query: t('street') })}
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Input
												label={t('city')}
												required
												value={companyCity}
												name="companyCity"
												placeholder={t('companyPagePlaceholder', { query: t('city') })}
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Input
												label={t('state')}
												value={companyState}
												name="companyState"
												placeholder={t('companyPagePlaceholder', { query: t('state') })}
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Dropdown
												label={t('country')}
												placeholder={t('companyPagePlaceholder', { query: t('country') })}
												search
												required
												autoComplete="off"
												fluid
												name="companyCountry"
												value={companyCountry}
												options={Countries}
												selection
												onChange={this.handleFormChange}
											></Form.Dropdown>
											<Form.Input
												label={t('postcode') + ' (**)'}
												placeholder={t('companyPagePlaceholder', { query: t('postcode') })}
												value={companyPostCode}
												required={_.includes(['AU', 'CA', 'DE', 'ES', 'FR', 'GB', 'IN', 'IT', 'NL', 'US'], this.state.country)}
												name="companyPostCode"
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Group>
												<Form.Button style={{ marginLeft: 'auto' }} positive type="submit">
													<Icon name="checkmark" /> {t('next')}
												</Form.Button>
												<Form.Button color="red" onClick={this.closeModal}>
													<Icon name="x" /> {t('close')}
												</Form.Button>
											</Form.Group>
										</Form>
										<span>{t('companyPageLabel')}</span>
									</div>
								</Modal.Content>
							</Modal>
						</Page>
					);
				}
				return (
					<Page
						header={
							<>
								<Breadcrumb size="big">
									<Breadcrumb.Section as={Link} to={`/settings`}>
										<Icon name="setting" />
										{t('settings')}
									</Breadcrumb.Section>
									<Breadcrumb.Divider icon="right chevron" />

									<Breadcrumb.Section as={Link} to={`/settings/subscription`}>
										{t('subscription')}
									</Breadcrumb.Section>
								</Breadcrumb>
								<AccountProgress></AccountProgress>
							</>
						}
						flex
					>
						<MyMenu>
							<Page.Wrap>
								<Segment>
									<div style={{ textAlign: 'center' }}>
										<Button.Group>
											{_.map(i18n.language === 'tr' ? LABELS_Tr : LABELS_En, (it) => (
												<Button
													type="button"
													key={it.key}
													name={it.key}
													color={it.color}
													basic={this.state.toggle !== it.key}
													onClick={() => this.button_click(it.key)}
												>
													{it.label}
												</Button>
											))}
										</Button.Group>
									</div>
									<div>
										<MyDivider name={t('subscription')} icon="dollar sign" />
										<Card.Group>
											<Card>
												<Card.Content
													style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
													header={'Start' + t('package')}
												/>
												<Card.Content
													description={
														<div style={{ maxHeight: '200px' }}>
															<div style={{ textAlign: 'center' }}>
																<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																	${switchPackage[0].price}
																	<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																</span>
															</div>

															<div style={{ listStyle: 'none' }}>
																<li>{t('xchannels', { int: 1 })}</li>
																<li>{t('xMonthRetention', { int: 3 })}</li>
															</div>
														</div>
													}
												/>
												<Card.Content style={{ maxHeight: '80px' }}>
													<Button
														onClick={() => this.openPurchaseModal(switchPackage[0].plan_id, switchPackage[0].price)}
														positive
														fluid
														loading={this.state.trialButtonLoadingLive}
													>
														{t('purchase')}
													</Button>
												</Card.Content>
											</Card>
											<Card>
												<Card.Content
													style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
													header={'Grow' + t('package')}
												/>
												<Card.Content
													description={
														<div style={{ maxHeight: '200px' }}>
															<div style={{ textAlign: 'center' }}>
																<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																	${switchPackage[1].price}
																	<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																</span>
															</div>

															<div style={{ listStyle: 'none' }}>
																<li>{t('xchannels', { int: 3 })}</li>
																<li>{t('productSearch')}</li>
																<li>{t('xMonthRetention', { int: 6 })}</li>
															</div>
														</div>
													}
												/>
												<Card.Content style={{ maxHeight: '80px' }}>
													<Button
														onClick={() => this.openPurchaseModal(switchPackage[1].plan_id, switchPackage[1].price)}
														positive
														fluid
														loading={this.state.trialButtonLoadingLive}
													>
														{t('purchase')}
													</Button>
												</Card.Content>
											</Card>
											<Card>
												<Card.Content
													style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
													header={'Scale' + t('package')}
												/>
												<Card.Content
													description={
														<div style={{ maxHeight: '200px' }}>
															<div style={{ textAlign: 'center' }}>
																<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																	${switchPackage[2].price}
																	<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																</span>
															</div>

															<div style={{ listStyle: 'none' }}>
																<li>{t('phoneSupport')}</li>
																<li>API</li>
																<li>{t('xMonthRetention', { int: 12 })}</li>
															</div>
														</div>
													}
												/>
												<Card.Content style={{ maxHeight: '80px' }}>
													<Button
														onClick={() => this.openPurchaseModal(switchPackage[2].plan_id, switchPackage[2].price)}
														positive
														fluid
														loading={this.state.trialButtonLoadingLive}
													>
														{t('purchase')}
													</Button>
												</Card.Content>
											</Card>
										</Card.Group>
									</div>
									<div>
										<MyDivider name={t('add-Ons')} icon="add" />
										<Card.Group>
											<Card>
												<Card.Content
													style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
													header="WhatsApp Business API"
												/>
												<Card.Content
													description={
														<div style={{ maxHeight: '200px' }}>
															<div style={{ textAlign: 'center' }}>
																<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																	${switchPackage[3].price}
																	<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																</span>
															</div>
														</div>
													}
												/>
												<Card.Content style={{ maxHeight: '80px' }}>
													<Button
														positive
														fluid
														disabled={haveBusinessApi}
														onClick={
															haveBusinessApi
																? ''
																: () => this.openPurchaseModal(switchPackage[3].plan_id, switchPackage[3].price)
														}
													>
														{t('purchase')}
													</Button>
												</Card.Content>
											</Card>
											{isShowWebApi ? (
												<Card>
													<Card.Content
														style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }}
														header="WhatsApp Web API"
													/>
													<Card.Content
														description={
															<div style={{ maxHeight: '200px' }}>
																<div style={{ textAlign: 'center' }}>
																	<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																		${switchPackage[4].price}
																		<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
																	</span>
																</div>
															</div>
														}
													/>
													<Card.Content style={{ maxHeight: '65px' }}>
														<Button
															positive
															fluid
															disabled={haveWebApi}
															onClick={
																haveWebApi ? '' : () => this.openPurchaseModal(switchPackage[4].plan_id, switchPackage[4].price)
															}
														>
															{t('purchase')}
														</Button>
													</Card.Content>
												</Card>
											) : (
												''
											)}
											{/* <Card>
												<Card.Content style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }} header="Instagram API" />
												<Card.Content
													description={
														<div style={{ maxHeight: '200px' }}>
															<div style={{ textAlign: 'center' }}>
																<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																	${switchPackage[5].price}
																	<span style={{ fontSize: '16px', color: '#A9A9A9' }}>/mo</span>
																</span>
															</div>
														</div>
													}
												/>
												<Card.Content style={{ maxHeight: '65px' }}>
													<Button
														positive
														fluid
														onClick={() => this.openPurchaseModal(switchPackage[5].plan_id, switchPackage[4].price)}
													>
														Purchase
													</Button>
												</Card.Content>
											</Card> */}
										</Card.Group>
									</div>
								</Segment>

								<Segment>
									<Table>
										<Table.Header>
											<Table.Row>
												<Table.HeaderCell>{t('subscription')}</Table.HeaderCell>
												<Table.HeaderCell>{t('quantity')}</Table.HeaderCell>
												<Table.HeaderCell>{t('nextBillDate')}</Table.HeaderCell>
												<Table.HeaderCell>{t('price')}</Table.HeaderCell>
												<Table.HeaderCell width="8" style={{ textAlign: 'center', paddingRight: 130 }}>
													{t('status')}
												</Table.HeaderCell>
											</Table.Row>
										</Table.Header>
										<Table.Body>
											{_.map(activeSubscriptions, (data, i) => (
												<Table.Row key={i}>
													<Table.Cell>{planIdName[data.data.subscription_plan_id] || 'Custom'}</Table.Cell>
													<Table.Cell>
														{data.custom_data
															? `Agent: ${data.custom_data.agent_limit} ${t('phone')}: ${data.custom_data.phone_limit}`
															: data.data.quantity || data.data.new_quantity}
													</Table.Cell>
													<Table.Cell>{data.data.next_bill_date || '-'}</Table.Cell>
													<Table.Cell>
														{data.data.next_payment_amount || data.data.unit_price || data.data.new_price || '0'}
													</Table.Cell>
													<Table.Cell style={{ textAlign: 'right' }}>
														{data.data.nobuttons ? null : (
															<>
																<Button
																	onClick={this.getPaymentHistory({ subscription_id: data.data.subscription_id })}
																	size="mini"
																	color="teal"
																>
																	{t('invoice')}
																</Button>
																<Button
																	color="grey"
																	size="mini"
																	onClick={this.updatePayment({ subscription_id: data.data.subscription_id, data })}
																	style={{ marginLeft: 10 }}
																>
																	{t('updatePayment')}
																</Button>
																<Button
																	positive
																	onClick={() =>
																		this.openUpdateModal({
																			selectedSubs: data.data.subscription_id,
																			user_id: data.data.user_id,
																			plan_id: data.data.subscription_plan_id,
																			price: data.data.unit_price || data.data.new_unit_price,
																			old_quantity: data.data.quantity || data.data.new_quantity,
																		})
																	}
																	size="mini"
																	style={{ marginLeft: 10 }}
																>
																	{t('updateQuantity')}
																</Button>
																{/* 			<Button
													primary
													onClick={() => this.openChangePlanModal(data.data.subscription_plan_id, data.data.subscription_id)}
													size="mini"
													style={{ marginLeft: 10 }}
												>
													Change Package
												</Button> */}
																<Button
																	negative
																	onClick={this.openSubsModal({
																		subscription_id: data.data.subscription_id,
																		plan_id: data.data.subscription_plan_id,
																		user_id: data.data.user_id,
																		quantity: data.data.quantity || data.data.new_quantity,
																	})}
																	size="mini"
																	style={{ marginLeft: 10 }}
																>
																	{t('cancel')}
																</Button>
															</>
														)}
													</Table.Cell>
												</Table.Row>
											))}
										</Table.Body>
									</Table>
								</Segment>
							</Page.Wrap>
						</MyMenu>
						{/*update modal*/}
						<Modal open={this.state.updateModalOpen} onClose={this.closeUpdateModal} style={{ width: 500 }}>
							<Header icon="redo" content={t('updatePackage')} />
							<Modal.Content style={{ textAlign: 'center' }}>
								<p>{t('enterAmount')}</p>
							</Modal.Content>
							<Modal.Content style={{ display: 'flex' }}>
								<Modal.Content style={{ width: 250, textAlign: 'center' }}>
									<Dropdown
										options={
											this.state.selectedPlanId === '595965' || this.state.selectedPlanId === '595966' ? startPackageOptions : options
										}
										placeholder={t('chooseQuantity')}
										selection
										value={quantityUpdate}
										defaultValue={1}
										onAddItem={this.handleAddition}
										onChange={this.handleChangeUpdate}
									/>
								</Modal.Content>
								<Modal.Content style={{ width: 250, textAlign: 'center', margin: 'auto' }}>
									<p>
										{t('totalAmountIs')}: {this.state.quantityUpdate * this.state.amountPrice}$
									</p>
								</Modal.Content>
							</Modal.Content>
							<Modal.Actions style={{ textAlign: 'center' }}>
								<Button color="red" onClick={this.closeUpdateModal}>
									<Icon name="remove" /> {t('back')}
								</Button>
								<Button color="green" onClick={this.openPreviewModal({ add: false })}>
									<Icon name="checkmark" /> {t('proceed')}
								</Button>
							</Modal.Actions>
						</Modal>
						{/*update preview subs  */}
						<Modal open={this.state.previewModal} onClose={this.closeModal} style={{ maxWidth: '700px' }}>
							<Header icon="exchange" content={t('updateSubscription')} />
							<Modal.Content>
								<div style={{ display: 'flex' }}>
									<div style={{ margin: 'auto' }}>
										<h3>{t('immediatePayment')}:</h3>
										<p>
											{t('amount')}: {this.state.previewdata && this.state.previewdata.immediate_payment.amount + ' $'}
										</p>
										<p>
											{t('date')}: {this.state.previewdata && this.state.previewdata.immediate_payment.date}
										</p>
									</div>
									<div style={{ margin: 'auto' }}>
										<h3>{t('nextPayment')}:</h3>
										<p>
											{t('amount')}: {this.state.previewdata && this.state.previewdata.next_payment.amount + ' $'}
										</p>
										<p>
											{t('date')}: {this.state.previewdata && this.state.previewdata.next_payment.date}
										</p>
									</div>
								</div>
							</Modal.Content>
							<Modal.Actions style={{ textAlign: 'left' }}>
								<div style={{ display: 'flex' }}>
									<h3 style={{ marginBottom: 'auto', marginTop: 'auto' }}>Are you sure update your {this.state.selectedPlanid}. </h3>
									<Button
										color="green"
										style={{ marginLeft: 'auto' }}
										onClick={this.updateAfterAmuntEntered({
											subscription_id: this.state.selectedSubs,
											user_id: this.state.selectedUserid,
											plan_id: this.state.selectedPlanid,
											quantity: this.state.quantityUpdate,
											old_quantity: this.state.selectedOldQuantity,
										})}
									>
										<Icon name="checkmark" /> {t('yes')}
									</Button>
									<Button color="red" onClick={this.closeModal}>
										<Icon name="x" /> {t('close')}
									</Button>
								</div>
							</Modal.Actions>
						</Modal>
						{/*change base subs  */}
						<Modal open={this.state.planChangeModal} onClose={this.closeModal} style={{ width: 500 }}>
							<Header icon="redo" content={t('updatePackage')} />
							<Modal.Content style={{ textAlign: 'center' }}>
								<p>{t('enterAmount')}</p>
							</Modal.Content>
							<Modal.Content style={{ display: 'flex' }}>
								<Modal.Content style={{ width: 250, textAlign: 'center' }}>
									<Dropdown
										options={
											this.state.selectedPlanId === '595965' || this.state.selectedPlanId === '595966' ? startPackageOptions : options
										}
										placeholder={t('chooseQuantity')}
										selection
										value={quantityUpdate}
										defaultValue={1}
										onAddItem={this.handleAddition}
										onChange={this.handleChangeUpdate}
									/>
								</Modal.Content>
								<Modal.Content style={{ width: 250, textAlign: 'center', margin: 'auto' }}>
									<p>
										{t('totalAmountIs')}: {this.state.quantityUpdate * this.state.amountPrice}$
									</p>
								</Modal.Content>
							</Modal.Content>
							<Modal.Actions style={{ textAlign: 'center' }}>
								<Button color="red" onClick={this.closeUpdateModal}>
									<Icon name="remove" /> {t('back')}
								</Button>
								<Button color="green" onClick={this.openPreviewModal({ add: false })}>
									<Icon name="checkmark" /> {t('proceed')}
								</Button>
							</Modal.Actions>
						</Modal>
						{/*change base subs  */}
						<Modal open={this.state.planChangeModal} onClose={this.closeModal} style={{ maxWidth: '500px' }}>
							<Header icon="exchange" content={t('selectNewSubs')} />
							<Modal.Content>
								<div style={{ display: 'flex' }}>
									<Radio
										label="Grow"
										name="radioGroup"
										value="grow"
										checked={this.state.newPackage === 'grow'}
										onChange={() => this.handleChangeNewPackage('grow', '595474')}
									/>
									<Radio
										label="Scale"
										name="radioGroup"
										value="scale"
										checked={this.state.newPackage === 'scale'}
										onChange={() => this.handleChangeNewPackage('scale', '595474')}
									/>
									<Dropdown
										options={
											this.state.selectedPlanId === '595965' || this.state.selectedPlanId === '595966' ? startPackageOptions : options
										}
										selection
										value={quantityUpdate}
										defaultValue={1}
										onAddItem={this.handleAddition}
										onChange={this.handleChangeUpdate}
									/>
								</div>
							</Modal.Content>
							<Modal.Actions style={{ textAlign: 'left' }}>
								<div style={{ display: 'flex' }}>
									<h3 style={{ marginBottom: 'auto', marginTop: 'auto' }}>{t('areYouChangeNewPackage')}</h3>
									<Button color="green" style={{ marginLeft: 'auto' }} onClick={this.openChangePlanModalPreview()}>
										<Icon name="checkmark" /> {t('yes')}
									</Button>
									<Button color="red" onClick={this.closeModal}>
										<Icon name="x" /> {t('close')}
									</Button>
								</div>
							</Modal.Actions>
						</Modal>
						<Modal open={this.state.planChangeModalPreview} onClose={this.closeModal} style={{ maxWidth: '500px' }}>
							<Header icon="exchange" content={t('changeYourSubs')} />
							<Modal.Content>
								<div style={{ display: 'flex' }}>
									<div style={{ margin: 'auto' }}>
										<h3>{t('immediatePayment')}:</h3>
										<p>
											{t('amount')}: {this.state.previewdata && this.state.previewdata.immediate_payment.amount + ' $'}
										</p>
										<p>
											{t('date')}: {this.state.previewdata && this.state.previewdata.immediate_payment.date}
										</p>
									</div>
									<div style={{ margin: 'auto' }}>
										<h3>{t('nextPayment')}:</h3>
										<p>
											{t('amount')}: {this.state.previewdata && this.state.previewdata.next_payment.amount + ' $'}
										</p>
										<p>
											{t('date')}: {this.state.previewdata && this.state.previewdata.next_payment.date}
										</p>
									</div>
								</div>
							</Modal.Content>
							<Modal.Actions style={{ textAlign: 'left' }}>
								<div style={{ display: 'flex' }}>
									<h3 style={{ marginBottom: 'auto', marginTop: 'auto' }}>{t('areYouChange')}</h3>
									<Button
										color="green"
										style={{ marginLeft: 'auto' }}
										onClick={this.changePlanId(this.state.selectedChange, this.state.selectedSubcriptionId, this.props.product.id)}
									>
										<Icon name="checkmark" /> {t('yes')}
									</Button>
									<Button color="red" onClick={this.closeModal}>
										<Icon name="x" /> {t('close')}
									</Button>
								</div>
							</Modal.Actions>
						</Modal>
						{/*CANCEL subs  */}
						<Modal open={this.state.unsubscribe} onClose={this.closeModal} style={{ maxWidth: '500px' }}>
							<Header icon="trash" content={t('unsubscribe')} />
							<Modal.Content>
								<p>{t('areYouWantsUnsubscribe')}</p>
							</Modal.Content>
							<Modal.Actions>
								<Button color="red" onClick={this.closeModal}>
									<Icon name="remove" /> {t('no')}
								</Button>
								<Button
									color="green"
									onClick={this.clickUnSubs({
										subscription_id: this.state.selectedSubs,
										plan_id: this.state.selectedPlanId,
										quantity: this.state.selectedQuantity,
									})}
								>
									<Icon name="checkmark" /> {t('yes')}
								</Button>
							</Modal.Actions>
						</Modal>
						<Modal open={this.state.purchaseModal} onClose={this.closeModal} style={{ maxWidth: '500px' }}>
							<Header icon="exchange" content={t('purchase')} />
							<Modal.Content>
								<div>
									<div style={{ margin: 'auto', marginBottom: '20px' }}>
										<h3>{t('packagePrice', { price: this.state.purchasePrice * this.state.quantityUpdate })}</h3>
										<div>
											<Dropdown
												options={
													this.state.selectedPlanId === '595965' || this.state.selectedPlanId === '595966'
														? startPackageOptions
														: options
												}
												placeholder={t('chooseQuantity')}
												selection
												value={quantityUpdate}
												defaultValue={1}
												onAddItem={this.handleAddition}
												onChange={this.handleChangeUpdate}
											/>
										</div>
										<p>{t('ifDontVat')}</p>
										<Button primary onClick={this.checkoutHandler(this.state.quantityUpdate, this.state.selectedPlanId, false)}>
											{t('iDontCompany')}
										</Button>
									</div>
									<Form onSubmit={this.checkoutHandler(this.state.quantityUpdate, this.state.selectedPlanId, true)}>
										<Form.Input
											label={t('vatNumber')}
											placeholder={t('companyPagePlaceholder', { query: t('vatNumber') })}
											required
											name="vatNumber"
											value={vatNumber}
											onChange={this.handleFormChange}
										></Form.Input>
										<Form.Input
											label={t('companyName')}
											required
											name="companyName"
											placeholder={t('companyPagePlaceholder', { query: t('companyName') })}
											value={companyName}
											onChange={this.handleFormChange}
										></Form.Input>
										<Form.Input
											label={t('street')}
											required
											name="companyStreet"
											value={companyStreet}
											placeholder={t('companyPagePlaceholder', { query: t('street') })}
											onChange={this.handleFormChange}
										></Form.Input>
										<Form.Input
											label={t('city')}
											required
											value={companyCity}
											name="companyCity"
											placeholder={t('companyPagePlaceholder', { query: t('city') })}
											onChange={this.handleFormChange}
										></Form.Input>
										<Form.Input
											label={t('state')}
											value={companyState}
											name="companyState"
											placeholder={t('companyPagePlaceholder', { query: t('state') })}
											onChange={this.handleFormChange}
										></Form.Input>
										<Form.Dropdown
											label={t('country')}
											placeholder={t('companyPagePlaceholder', { query: t('country') })}
											search
											required
											autoComplete="off"
											fluid
											name="companyCountry"
											value={companyCountry}
											options={Countries}
											selection
											onChange={this.handleFormChange}
										></Form.Dropdown>
										<Form.Input
											label={t('postcode') + ' (**)'}
											placeholder={t('companyPagePlaceholder', { query: t('postcode') })}
											value={companyPostCode}
											required={_.includes(['AU', 'CA', 'DE', 'ES', 'FR', 'GB', 'IN', 'IT', 'NL', 'US'], this.state.country)}
											name="companyPostCode"
											onChange={this.handleFormChange}
										></Form.Input>
										<Form.Group>
											<Form.Button style={{ marginLeft: 'auto' }} positive type="submit">
												<Icon name="checkmark" /> {t('next')}
											</Form.Button>
											<Form.Button color="red" onClick={this.closeModal}>
												<Icon name="x" /> {t('close')}
											</Form.Button>
										</Form.Group>
									</Form>
									<span>{t('companyPageLabel')}</span>
								</div>
							</Modal.Content>
						</Modal>
						{/*Payment History*/}
						<Modal open={this.state.historyModal} onClose={this.closeModal} style={{ maxWidth: '500px' }}>
							<Header icon="dollar" content={t('paymentHistory')} />
							<Modal.Content>
								<h4>Next Payment</h4>
								{_.map(_.filter(this.state.paymentHistory, { is_paid: 0 }), function (it, i) {
									return (
										<a
											href={it.is_paid ? it.receipt_url : null}
											target="_blank"
											rel="noopener noreferrer"
											key={it.id}
											style={{
												display: 'flex',
												color: '#646464',
												padding: '14px',
												flex: ' 0 0 auto',
												textDecoration: 'none',
												border: '1px solid rgb(221, 221, 221)',
												fontSize: '600',
											}}
										>
											<div style={{ width: '70%' }}>
												<div style={{ fontWeight: '600' }}>{'OrderCode: ' + it.id}</div>
												<div style={{ fontWeight: '600' }}>
													{'Next Bill Date: ' + moment(it.payout_date, 'YYYY-MM-DD').format('MMM DD, YYYY')}
												</div>
											</div>
											<div>
												<div style={{ fontWeight: '600' }}>{'$' + it.amount}</div>
											</div>
										</a>
									);
								})}
							</Modal.Content>
							<Modal.Content style={{ paddingTop: '0', paddingBottom: '0' }}>
								<h4>{t('pastPayments')}</h4>
							</Modal.Content>
							<Modal.Content scrolling>
								<div style={{ borderTop: '1px solid rgb(221, 221, 221)', borderRadius: '4px', display: 'flex' }}></div>
								{_.map(_.filter(this.state.paymentHistory, { is_paid: 1 }), function (it, i) {
									return (
										<a
											href={it.is_paid ? it.receipt_url : null}
											target="_blank"
											rel="noopener noreferrer"
											key={it.id}
											style={{
												display: 'flex',
												color: '#646464',
												padding: '14px',
												flex: ' 0 0 auto',
												textDecoration: 'none',
												border: '1px solid rgb(221, 221, 221)',
												borderTop: 'none',
												fontSize: '600',
											}}
										>
											<div style={{ width: '70%' }}>
												<div style={{ fontWeight: '600' }}>{'Order Code: ' + it.id}</div>
												<div style={{ fontWeight: '600' }}>
													{'Date: ' + moment(it.payout_date, 'YYYY-MM-DD').format('MMM DD, YYYY')}
												</div>
											</div>
											<div>
												<div style={{ fontWeight: '600' }}>{'$' + it.amount}</div>
												<div style={{ textDecoration: 'underline', color: 'black', fontWeight: '400' }}>{t('showReceipt')}</div>
											</div>
										</a>
									);
								})}
							</Modal.Content>
						</Modal>
					</Page>
				);
			}
		}
	}
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product.live, user: state.access.user.loggedUserObj });
export default connect(stateToProps)(withTranslation()(Subscription));
