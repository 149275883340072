import React, { useState } from 'react';
import Page from '../../components/Page';
import { Icon, Breadcrumb, Form, FormGroup, Dropdown, Table, Button, Segment, Modal, Header, Message, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import useFetch from 'use-http';
import MyMenu from './menu';
import AccountProgress from '../../components/AccountProgress';
import { useTranslation } from 'react-i18next';

let agentTypes = [
	{
		text: 'Admin',
		value: 'admin',
	},
	{
		text: 'Agent',
		value: 'agent',
	},
];

const fetch_config = { responseType: 'json', cachePolicy: 'no-cache' };

export default function ProductSettings() {
	const { t } = useTranslation();
	const [reload, setReload] = useState(0);
	const { loading, response } = useFetch('/api/live/agent/list', fetch_config, [reload]);
	const [state, setState] = useState({
		showAdd: false,
		showUpdate: false,
		agentMail: '',
		agentPassword: '',
		agentConfirmPassword: '',
		agentType: 'agent',
		agentName: '',
		passworderror: false,
		error: null,
		deleteBtnClicked: false,
		addButton: t('add'),
		showPassword: true,
	});
	if (loading)
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/settings`}>
							<Icon name="setting" />
							{t('settings')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
					</Breadcrumb>
				}
				flex
			>
				<MyMenu activeItem="agent">
					<Page.Loader></Page.Loader>
				</MyMenu>
			</Page>
		);
	const data = response && response.data && response.data.agents;
	let agentsData = _.filter(data, function (it) {
		return it.role !== 'viewer';
	});
	let viewersData = _.filter(data, function (it) {
		return it.role === 'viewer';
	});
	//const product = response && response.data && response.data.product;
	const user = response && response.data && response.data.user;
	const host = user.host === null || user.host === 'app.dialogtab';
	let agentlimit = (response && response.data && response.data.product && response.data.product.settings.agent_limit) || 0;
	let agentcount = agentsData.length + 1;
	let viewerscount = viewersData.length;
	function handleAddAgents() {
		if (agentcount >= agentlimit) {
			setState({
				addError: true,
				addErrorMessage: t('addAgentErrorMessage'),
			});
		} else {
			reset();
			setState({
				current: 'add',
				currentviewer: false,
				agentType: 'agent',
				addButton: t('add'),
				agentMail: '',
				agentPassword: '',
				agentConfirmPassword: '',
				showPassword: true,
				agentName: '',
			});
		}
	}
	function handleAddViewers() {
		if (viewerscount >= 10) {
			setState({
				addError: true,
				addErrorMessage: "You can't add more than five viewers.",
			});
		} else {
			reset();
			setState({
				currentviewer: 'add',
				current: false,
				agentType: 'agent',
				addButton: 'Add',
				agentMail: '',
				agentPassword: '',
				agentConfirmPassword: '',
				showPassword: true,
				agentName: '',
			});
		}
	}
	function handleUpdateViewers(value) {
		setState({
			currentviewer: 'update',
			agentMail: value.mail,
			current: false,
			selectedAgentUid: value.uid,
			agentType: value.agentType,
			agentName: value.name,
			agentPassword: value.password,
			showPassword: false,
			user_id: value.user_id,
			addButton: t('update'),
		});
	}
	function handleUpdateAgents(value) {
		setState({
			current: 'update',
			currentviewer: false,
			agentMail: value.mail,
			selectedAgentUid: value.uid,
			agentType: value.agentType,
			agentName: value.name,
			agentPassword: value.password,
			user_id: value.user_id,
			showPassword: false,
			addButton: t('update'),
		});
	}

	function reset() {
		setState({
			current: '',
			agentMail: '',
			agentPassword: '',
			agentConfirmPassword: '',
			agentType: 'agent',
			agentName: '',
			addButton: t('add'),
			passworderror: false,
			error: null,
		});
	}
	function validPassword() {
		let isTrue = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/.test(state.agentPassword);
		return isTrue;
	}
	function handleAddAgent() {
		let isTrue = validPassword();
		if (isTrue) {
			setState((state) => ({ ...state, passworderror: false }));

			fetch('/api/live/agent/add', {
				method: 'POST',
				body: JSON.stringify({
					role: state.agentType,
					email: state.agentMail,
					password: state.agentPassword,
					name: state.agentName,
				}),
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.success) {
						reset();
						setReload(reload + 1);
					} else {
						setState((state) => ({ ...state, error: data.pg ? 'Error' : '' + data.message }));
					}
				});
		} else {
			setState((state) => ({ ...state, passworderror: true }));
		}
	}
	function handleAddViewer() {
		let isTrue = validPassword();
		if (isTrue) {
			setState((state) => ({ ...state, passworderror: false }));

			fetch('/api/live/agent/add', {
				method: 'POST',
				body: JSON.stringify({
					role: 'viewer',
					email: state.agentMail,
					password: state.agentPassword,
					name: state.agentName,
				}),
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.success) {
						reset();
						setReload(reload + 1);
					} else {
						setState((state) => ({ ...state, errorviewer: data.pg ? 'Error' : '' + data.message }));
					}
				});
		} else {
			setState((state) => ({ ...state, passworderror: true }));
		}
	}
	function handleUpdateAgent() {
		let isTrue = validPassword();
		if (isTrue) {
			setState((state) => ({ ...state, passworderror: false }));
			fetch('/api/live/agent/update', {
				method: 'POST',
				body: JSON.stringify({
					uid: state.selectedAgentUid,
					role: state.agentType,
					email: state.agentMail,
					password: state.agentPassword,
					name: state.agentName,
					user_id: state.user_id,
				}),
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.success) {
						reset();
						setReload(reload + 1);
					} else {
						setState((state) => ({ ...state, error: data.pg ? 'Error' : '' + data.message }));
					}
				});
		} else {
			setState((state) => ({ ...state, passworderror: true }));
		}
	}
	function handleUpdateViewer() {
		let isTrue = validPassword();
		if (isTrue) {
			setState((state) => ({ ...state, passworderror: false }));
			fetch('/api/live/agent/update', {
				method: 'POST',
				body: JSON.stringify({
					uid: state.selectedAgentUid,
					role: state.agentType,
					email: state.agentMail,
					password: state.agentPassword,
					name: state.agentName,
					user_id: state.user_id,
				}),
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.success) {
						reset();
						setReload(reload + 1);
					} else {
						setState((state) => ({ ...state, errorviewer: data.pg ? 'Error' : '' + data.message }));
					}
				});
		} else {
			setState((state) => ({ ...state, passworderror: true }));
		}
	}
	function handleDeleteAgent() {
		fetch('/api/live/agent/delete', {
			method: 'POST',
			body: JSON.stringify({ uid: state.selectedAgentUid, user_id: state.selectedAgentUser_id }),
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					reset();
					setReload(reload + 1);
				} else {
					setState((state) => ({ ...state, error: '' + data.message }));
				}
			});
	}

	function handleChange(e, { name, value }) {
		setState((state) => ({ ...state, [name]: value }));
	}
	function handleClickDelete() {
		handleDeleteAgent();
	}
	function openDeleteButton(value) {
		setState({ selectedAgentUid: value.uid, selectedAgentUser_id: value.user_id, deleteBtnClicked: true });
	}
	function closeDeleteButton() {
		setState({ deleteBtnClicked: false, goToSubs: false });
	}
	const IS_UPDATE = state.current === 'update';
	const IS_UPDATE_VIEWER = state.currentviewer === 'update';
	return (
		<Page
			header={
				<Breadcrumb size="big">
					<Breadcrumb.Section as={Link} to={`/settings`}>
						<Icon name="setting" />
						{t('settings')}
					</Breadcrumb.Section>
					<Breadcrumb.Divider icon="right chevron" />
					<Breadcrumb.Section>{t('agents')}</Breadcrumb.Section>
				</Breadcrumb>
			}
			extra={
				<div className="ml-auto">
					<AccountProgress></AccountProgress>
				</div>
			}
			flex
		>
			<MyMenu activeItem="agent">
				<Page.Wrap>
					<Segment>
						{state.addError ? (
							<Message error>
								<p>
									{state.addErrorMessage}
									{host ? <Link to="/settings/subscription"> {t('goToSubscription')}</Link> : ' ' + t('Plasecontacttoyouradmin')}
								</p>
							</Message>
						) : (
							''
						)}
						<h3>{t('agents')}</h3>
						<Table>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell width="7">{t('email')}</Table.HeaderCell>
									<Table.HeaderCell width="3">{t('type')}</Table.HeaderCell>
									<Table.HeaderCell width="3">{t('name')}</Table.HeaderCell>
									<Table.HeaderCell width="1"></Table.HeaderCell>
									<Table.HeaderCell>
										<Button positive onClick={() => handleAddAgents()} size="tiny" fluid>
											{t('addAgent') + ' (' + agentcount + '/' + agentlimit + ')'}
										</Button>
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								<Table.Row key={'owner'}>
									<Table.Cell>{user.email}</Table.Cell>
									<Table.Cell>{t('owner')}</Table.Cell>
									<Table.Cell>{user.name || t('enterName')}</Table.Cell>
									<Table.Cell style={{ textAlign: 'right' }}></Table.Cell>
								</Table.Row>
								{_.map(agentsData, (data, index) => (
									<Table.Row key={index}>
										<Table.Cell>{data.email}</Table.Cell>
										<Table.Cell>{_.upperFirst(data.role) || t('owner')}</Table.Cell>
										<Table.Cell>{data.name}</Table.Cell>
										<Table.Cell>
											{index + 2 > agentlimit ? (
												<Popup
													content={t('agentDontUseChat')}
													trigger={
														<Button
															negative
															circular
															icon="warning sign"
															as={Link}
															to="/settings/subscription"
															size="tiny"
														></Button>
													}
												/>
											) : (
												''
											)}
										</Table.Cell>
										{data.type !== 'admin' && (
											<Table.Cell style={{ textAlign: 'right' }}>
												<Button
													primary
													size="tiny"
													onClick={() =>
														handleUpdateAgents({
															mail: data.email,
															agentType: data.role,
															uid: data.id,
															name: data.name,
															password: data.password,
															user_id: data.extra.user_id,
														})
													}
												>
													<i className="edit icon" style={{ color: 'white', opacity: 1, margin: 0, textAlign: 'center' }}></i>
												</Button>
												<Button
													negative
													size="tiny"
													onClick={() => openDeleteButton({ uid: data.uid, email: data.email, user_id: data.extra.user_id })}
												>
													<i className="trash icon" style={{ color: 'white', opacity: 1, margin: 0, textAlign: 'center' }}></i>
												</Button>
											</Table.Cell>
										)}
									</Table.Row>
								))}
							</Table.Body>
						</Table>
					</Segment>
					{state.error && <div>{state.error}</div>}
					{state.current ? (
						<Form
							autoComplete="off"
							onSubmit={() => {
								if (!IS_UPDATE) handleAddAgent();
								else handleUpdateAgent();
							}}
						>
							<input autoComplete="false" name="" value="" type="email" style={{ display: 'none' }} />
							<FormGroup widths="equal">
								<Form.Input placeholder="Email" name="agentMail" value={state.agentMail} onChange={handleChange} fluid required type="email" />
								<Dropdown
									placeholder={t('type')}
									name="agentType"
									selection
									style={{ margin: 'auto', marginTop: '0' }}
									options={agentTypes}
									value={state.agentType}
									onChange={handleChange}
								/>
								<Form.Input
									placeholder={t('fullName')}
									name="agentName"
									value={state.agentName}
									onChange={handleChange}
									fluid
									required={!IS_UPDATE}
									type="text"
								/>
								<Form.Input
									placeholder={t('password')}
									name="agentPassword"
									value={state.agentPassword}
									autoComplete="new-password"
									onChange={handleChange}
									fluid
									//icon={<Icon name="eye" link onClick={() => handleShowPassword(state.showPassword)} />}
									error={state.passworderror ? { content: t('passwordValid'), pointing: 'above' } : false}
									required={!IS_UPDATE}
									type="text"
								/>
								<div className="d-flex">
									<Form.Button positive content={state.addButton} style={{ width: '100px' }} />
									<Form.Button negative onClick={reset} content="X" />
								</div>
							</FormGroup>
						</Form>
					) : (
						''
					)}
					<Message info>
						<p>
							{t('owner')}: {t('ownerDesc')}
						</p>
						<p>
							{t('admin')}: {t('adminDesc')}
						</p>
						<p>
							{t('agent')}: {t('agentDesc')}
						</p>
					</Message>
					<Segment>
						<h3>{t('viewers')}</h3>
						<Table>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell width="7">{t('email')}</Table.HeaderCell>
									<Table.HeaderCell width="3">{t('type')}</Table.HeaderCell>
									<Table.HeaderCell width="3">{t('name')}</Table.HeaderCell>
									<Table.HeaderCell width="1"></Table.HeaderCell>
									<Table.HeaderCell>
										<Button positive onClick={() => handleAddViewers()} size="tiny" fluid>
											{t('addViewers') + ' (' + viewerscount + '/' + 10 + ')'}
										</Button>
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{_.map(viewersData, (data, index) => (
									<Table.Row key={index}>
										<Table.Cell>{data.email}</Table.Cell>
										<Table.Cell>{_.upperFirst(data.role) || 'Owner'}</Table.Cell>
										<Table.Cell>{data.name}</Table.Cell>
										<Table.Cell></Table.Cell>
										{data.type !== 'admin' && (
											<Table.Cell style={{ textAlign: 'right' }}>
												<Button
													primary
													size="tiny"
													onClick={() =>
														handleUpdateViewers({
															mail: data.email,
															agentType: data.role,
															uid: data.id,
															name: data.name,
															password: data.password,
															user_id: data.extra.user_id,
														})
													}
												>
													<i className="edit icon" style={{ color: 'white', opacity: 1, margin: 0, textAlign: 'center' }}></i>
												</Button>
												<Button
													negative
													size="tiny"
													onClick={() => openDeleteButton({ uid: data.uid, email: data.email, user_id: data.extra.user_id })}
												>
													<i className="trash icon" style={{ color: 'white', opacity: 1, margin: 0, textAlign: 'center' }}></i>
												</Button>
											</Table.Cell>
										)}
									</Table.Row>
								))}
							</Table.Body>
						</Table>
					</Segment>
					{state.errorviewer && <div>{state.errorviewer}</div>}
					{state.currentviewer ? (
						<Form
							autoComplete="off"
							onSubmit={() => {
								if (!IS_UPDATE_VIEWER) handleAddViewer();
								else handleUpdateViewer();
							}}
						>
							<input autoComplete="false" name="" value="" type="email" style={{ display: 'none' }} />
							<FormGroup widths="equal">
								<Form.Input placeholder="Email" name="agentMail" value={state.agentMail} onChange={handleChange} fluid required type="email" />
								<Form.Input
									name="agentType"
									value={t('viewer')}
									onChange={handleChange}
									fluid
									readOnly
									required={!IS_UPDATE_VIEWER}
									type="text"
								/>
								<Form.Input
									placeholder={t('fullName')}
									name="agentName"
									value={state.agentName}
									onChange={handleChange}
									fluid
									required={!IS_UPDATE_VIEWER}
									type="text"
								/>
								<Form.Input
									placeholder={t('password')}
									name="agentPassword"
									value={state.agentPassword}
									autoComplete="new-password"
									onChange={handleChange}
									fluid
									//icon={<Icon name="eye" link onClick={() => handleShowPassword(state.showPassword)} />}
									error={state.passworderror ? { content: t('passwordValid'), pointing: 'above' } : false}
									required={!IS_UPDATE_VIEWER}
									type="text"
								/>
								<div className="d-flex">
									<Form.Button positive content={state.addButton} style={{ width: '100px' }} />
									<Form.Button negative onClick={reset} content="X" />
								</div>
							</FormGroup>
						</Form>
					) : (
						''
					)}
					<Message info>
						<p>
							{t('viewer')}: {t('viewerDesc')}
						</p>
					</Message>
				</Page.Wrap>
			</MyMenu>

			{/*delete modal*/}
			<Modal open={state.deleteBtnClicked} onClose={() => closeDeleteButton()} style={{ width: 500 }}>
				<Header icon="trash" content={t('deleteAgentModal')} />
				<Modal.Content>
					<p>{t('deleteAgentQuestion')}</p>
				</Modal.Content>
				<Modal.Actions>
					<Button color="red" onClick={() => closeDeleteButton()}>
						<Icon name="remove" /> {t('no')}
					</Button>
					<Button color="green" onClick={() => handleClickDelete()}>
						<Icon name="checkmark" /> {t('yes')}
					</Button>
				</Modal.Actions>
			</Modal>
		</Page>
	);
}
