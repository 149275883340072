import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// eslint-disable-next-line
import styled from 'styled-components/macro';

class MenuExampleTabularOnLeft extends Component {
	state = { activeItem: 'bio' };

	handleItemClick = (e, { name }) => this.setState({ activeItem: name });

	render() {
		const { activeItem, user, t, product } = this.props;
		let host = user.host === null || user.host === 'app.dialogtab.com' || user.host === 'localhost' || user.host === 'test.dialogtab.com';
		let product_type = product.type;
		let isShopify = product_type === 'shopify';
		let noFacebook = user && user.extra && user.extra.noFacebook;
		let noWebSite = user && user.extra && user.extra.noWebSite;
		let noWhatsappBusiness = user && user.extra && user.extra.noWhatsappBusiness;
		let noWhatsappWeb = user && user.extra && user.extra.noWhatsappWeb;
		let noTsoft = user && user.extra && user.extra.noTsoft;
		let noTicimax = user && user.extra && user.extra.noTicimax;
		let noWooCommerce = user && user.extra && user.extra.WooCommerce;
		let noSaleDetector = user && user.extra && user.extra.noSaleDetector;
		let noFaprika = user && user.extra && user.extra.noFaprika;
		let isContacts = user && user.extra && user.extra.isContacts;
		let auto_take_global = product && product.settings && product.settings.auto_take_global;
		let isEcommerce = product && product.settings && product.settings.analyticView && product.settings.analyticView.ecommerce;
		//let isInstagram = (user && user.extra && user.extra.insta) || (product && product.settings && product.settings.insta);
		let isTelegram = user && user.extra && user.extra.isTelegram;
		let phoneLimit = product && product.settings && product.settings.phone_limit;
		//let isTrial = product && product.settings && product.settings.subscription_package === 'trial-start';
		//let noInstagram = user && user.extra && user.extra.noInstagram;
		return (
			<div
				css={`
					display: flex;
					flex: 1;
					.left-menu {
						flex: 0 0 auto;
						.ui.vertical.menu {
							border-radius: 0 !important;
							height: 100% !important;
							border-top: 0 !important;
							border-bottom: 0 !important;
						}
					}
					.right-menu {
						flex: 1;
						overflow: auto;
					}
				`}
			>
				<div className="left-menu">
					<Menu vertical style={{ overflow: 'auto', borderLeft: 'none' }}>
						<Menu.Item style={{ paddingBottom: '0', paddingTop: '0' }}>
							<Menu.Header style={{ fontSize: '18px', margin: '0', paddingTop: '10px', paddingBottom: '4px' }}>Menu</Menu.Header>
						</Menu.Item>
						<Menu.Menu>
							<Menu.Item name="agent" active={activeItem === 'progress'} style={{ fontSize: '16px' }} as={Link} to="/settings">
								{t('accountProgress')}
							</Menu.Item>
						</Menu.Menu>
						<Menu.Item style={{ paddingBottom: '0', paddingTop: '0' }}>
							<Menu.Header style={{ fontSize: '18px', margin: '0', paddingTop: '10px', paddingBottom: '4px' }}>{t('agents')}</Menu.Header>
						</Menu.Item>
						<Menu.Menu>
							<Menu.Item name="agent" active={activeItem === 'agent'} style={{ fontSize: '16px' }} as={Link} to="/settings/agents">
								{t('multiAgents')}
							</Menu.Item>
							{auto_take_global ? (
								<Menu.Item
									name="agentlimits"
									active={activeItem === 'agentlimits'}
									style={{ fontSize: '16px' }}
									as={Link}
									to="/settings/agentsConfig"
								>
									{t('agentsLimits')}
								</Menu.Item>
							) : (
								''
							)}
						</Menu.Menu>
						{noFacebook && noWebSite && noWhatsappBusiness & noWhatsappWeb ? null : (
							<Menu.Item style={{ paddingBottom: '0', paddingTop: '0' }}>
								<Menu.Header style={{ fontSize: '18px', margin: '0', paddingTop: '10px', paddingBottom: '4px' }}>{t('channels')}</Menu.Header>
							</Menu.Item>
						)}
						<Menu.Menu>
							{!noWebSite ? (
								<Menu.Item
									name="webchat"
									active={activeItem === 'webchat'}
									style={{ fontSize: '16px' }}
									as={Link}
									to="/settings/integrations/website-chat"
								>
									{t('webSiteChat')}
								</Menu.Item>
							) : null}
							{/* {!host ? (
								<Menu.Item
									name="whatsapp-web-api"
									active={activeItem === 'whatsapp-web-api'}
									style={{ fontSize: '16px' }}
									as={Link}
									to="/settings/integrations/whatsapp-web-api"
								>
									WhatsApp Web API
								</Menu.Item>
							) : (
								''
							)} */}
							{phoneLimit && phoneLimit > 0 ? (
								<Menu.Item
									name="whatsapp-web-api"
									active={activeItem === 'whatsapp-web-api'}
									style={{ fontSize: '16px' }}
									as={Link}
									to="/settings/integrations/whatsapp-web-api"
								>
									WhatsApp Web API
								</Menu.Item>
							) : (
								''
							)}

							<Menu.Item
								name="instagram"
								active={activeItem === 'instagram'}
								style={{ fontSize: '16px' }}
								as={Link}
								to="/settings/integrations/instagram-messenger"
							>
								Instagram Messenger
							</Menu.Item>
							{isTelegram ? (
								<Menu.Item
									name="telegram"
									active={activeItem === 'telegram'}
									style={{ fontSize: '16px' }}
									as={Link}
									to="/settings/integrations/telegram"
								>
									Telegram
								</Menu.Item>
							) : (
								''
							)}

							{!noFacebook ? (
								<Menu.Item
									name="facebook"
									active={activeItem === 'facebook'}
									style={{ fontSize: '16px' }}
									as={Link}
									to="/settings/integrations/facebook-messenger"
								>
									Facebook Messenger
								</Menu.Item>
							) : null}
							{!noWhatsappBusiness && host ? (
								<>
									<Menu.Item
										name="whatsapp business"
										active={activeItem === 'whatsapp business'}
										style={{ fontSize: '16px' }}
										as={Link}
										to="/settings/integrations/whatsapp-business/request-access"
									>
										WhatsApp Business API
									</Menu.Item>
									<Menu.Item
										name="sandbox"
										active={activeItem === 'sandbox'}
										style={{ fontSize: '16px' }}
										as={Link}
										to="/settings/integrations/whatsapp-sandbox"
									>
										WhatsApp Sandbox
									</Menu.Item>
								</>
							) : null}
						</Menu.Menu>
						{!noTicimax && !noTsoft && !host ? null : (
							<Menu.Item style={{ paddingBottom: '0', paddingTop: '0' }}>
								<Menu.Header style={{ fontSize: '18px', margin: '0', paddingTop: '10px', paddingBottom: '4px' }}>{t('eCommerce')}</Menu.Header>
							</Menu.Item>
						)}

						{isShopify ? (
							<Menu.Menu>
								<Menu.Item style={{ fontSize: '16px' }} as={NavLink} to="/settings/integrations/shopify">
									{t('Shopify')}
								</Menu.Item>
							</Menu.Menu>
						) : (
							<Menu.Menu>
								{host ? (
									<Menu.Item style={{ fontSize: '16px' }} as={NavLink} to="/settings/integrations/request">
										{t('menuRequest')}
									</Menu.Item>
								) : null}
								{!noTsoft && host ? (
									<Menu.Item
										name="tsoft"
										active={activeItem === 'tsoft'}
										style={{ fontSize: '16px' }}
										as={Link}
										to="/settings/integrations/tsoft"
									>
										T-Soft
									</Menu.Item>
								) : null}
								{!noTicimax && host ? (
									<Menu.Item name="ticimax" style={{ fontSize: '16px' }} as={NavLink} to="/settings/integrations/ticimax" />
								) : null}
								{!noFaprika && host ? (
									<Menu.Item name="faprika" style={{ fontSize: '16px' }} as={NavLink} to="/settings/integrations/faprika" />
								) : null}
								{!noWooCommerce && host ? (
									<Menu.Item name="woocommerce" style={{ fontSize: '16px' }} as={NavLink} to="/settings/integrations/woocommerce" />
								) : null}
								{isEcommerce && (
									<Menu.Item name="ecommercepayment" style={{ fontSize: '16px' }} as={NavLink} to="/settings/integrations/ecommercepayment">
										{t('ecommercepayment')}
									</Menu.Item>
								)}
							</Menu.Menu>
						)}

						<Menu.Item style={{ paddingBottom: '0', paddingTop: '0' }}>
							<Menu.Header style={{ fontSize: '18px', margin: '0', paddingTop: '10px', paddingBottom: '4px' }}>{t('settings')}</Menu.Header>
						</Menu.Item>
						<Menu.Menu>
							<Menu.Item style={{ fontSize: '16px' }} as={NavLink} to="/settings/product/agent">
								{t('agentScreen')}
							</Menu.Item>
							<Menu.Item style={{ fontSize: '16px' }} as={NavLink} to="/settings/product/time">
								{t('workHours')}
							</Menu.Item>
							<Menu.Item name="welcome" style={{ fontSize: '16px' }} as={NavLink} to="/settings/product/welcome">
								{t('welcomeMessages')}
							</Menu.Item>
							{!noSaleDetector && host ? (
								<Menu.Item name="sale" style={{ fontSize: '16px' }} as={NavLink} to="/settings/product/sale">
									{t('saleDetector')}
								</Menu.Item>
							) : null}
							<Menu.Item name="label" style={{ fontSize: '16px' }} as={NavLink} to="/settings/integrations/label">
								{t('label')}
							</Menu.Item>
							{isContacts && (
								<Menu.Item name="contacts" style={{ fontSize: '16px' }} as={NavLink} to="/settings/integrations/contacts">
									{t('contacts')}
								</Menu.Item>
							)}
							<Menu.Item name="wabatemplates" style={{ fontSize: '16px' }} as={NavLink} to="/settings/integrations/wabatemplates">
								WABA Template
							</Menu.Item>
						</Menu.Menu>

						{this.props.user.host === null || this.props.user.host === 'app.dialogtab.com' ? (
							<>
								<Menu.Item style={{ paddingBottom: '0', paddingTop: '0' }}>
									<Menu.Header style={{ fontSize: '18px', margin: '0', paddingTop: '10px', paddingBottom: '4px' }}>
										{t('subscription')}
									</Menu.Header>
								</Menu.Item>
								<Menu.Menu>
									<Menu.Item
										name="package"
										active={activeItem === 'package'}
										style={{ fontSize: '16px' }}
										as={NavLink}
										to="/settings/subscription"
									>
										{t('packages')}
									</Menu.Item>
								</Menu.Menu>
							</>
						) : null}
					</Menu>
				</div>
				<div className="right-menu">{this.props.children}</div>
			</div>
		);
	}
}
const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product.live, user: state.access.user.loggedUserObj });

export default connect(stateToProps)(withTranslation()(MenuExampleTabularOnLeft));
