import React, { Component } from 'react';
import { Form, Segment, Icon, Header, Grid, Button, Message, Input, Popup } from 'semantic-ui-react';
import { log } from '../../util/api';
import { connect } from 'react-redux';
import { updateProfile } from '../../actions/access.actions';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Page from '../../components/Page';

class UserPage extends Component {
	state = {
		list: (this.props.user.extra && this.props.user.extra.wcart_category) || [],
		loading: true,
		openlist: '',
	};
	handleArrayChange = (e, { name, ind, value }) => {
		let productlist = this.state.productlist;
		let product_list = _.find(productlist, { url: ind });
		if (!/images|options/.test(name)) product_list.data[name] = value;
		if (name === 'price') product_list.price = parseFloat(value);
		else if (name === 'name') product_list.title = value;
		else if (/images/.test(name)) {
			product_list.data.images[name.replace(/images_/, '')] = value;
			//product_list.images[name.replace(/images_/, '')] = value;
		} else if (/options/.test(name)) {
			product_list.data.options[name.replace(/options_/, '')].name = value;
			//product_list.options[name.replace(/options_/, '')] = value;
		}
		this.setState({ productlist });
	};
	handleArrayOptionsStatus(ind, name) {
		let productlist = this.state.productlist;
		let product_list = _.find(productlist, { url: ind });
		product_list.data.options[name].status = !product_list.data.options[name].status;
		this.setState({ productlist });
	}
	handleAddImages = (name, ob, value) => {
		let productlist = this.state.productlist;
		let product_list = _.find(productlist, { url: name });
		product_list.data[ob].push({ name: '', status: false });
		//product_list[ob].push('');
		this.setState({ productlist });
	};
	handleAddOptions = (name, ob) => {
		let productlist = this.state.productlist;
		let product_list = _.find(productlist, { url: name });
		product_list.data[ob].push('');
		//product_list[ob].push('');
		this.setState({ productlist });
	};
	handleArrayToggle = (e, { name }) => {
		let productlist = this.state.productlist;
		let product_list = _.find(productlist, { url: name });
		product_list.data.status = !product_list.data.status;
		this.setState({ productlist });
	};
	handleRemove = (index, category, name) => {
		let productlist = this.state.productlist;
		let product_list = _.find(productlist, { url: index });
		//_.pullAt(product_list, [index]);
		//productlist.splice(product_list, 1);
		product_list.data.deleted = true;
		this.setState({ productlist });
	};
	handleRemoveImage = (index, ind, ob) => {
		let productlist = this.state.productlist;
		let product_list = _.find(productlist, { url: ind });
		//_.pullAt(product_list[ob], [index]);
		_.pullAt(product_list.data[ob], [index]);
		this.setState({ productlist });
	};
	postData() {
		let { productlist, startList } = this.state;
		let items = _.differenceWith(productlist, startList, _.isEqual);
		this.setState({ saveLoad: true });
		items &&
			items.length &&
			log('/api/addItems', {
				items,
			}).then(() => {
				this.setState({ saveLoad: false });
			});
	}
	swap(name, a, b) {
		let { productlist } = this.state;
		let product_list = _.filter(productlist, { title: name });
		let fromO = product_list[b];
		let toO = product_list[a];
		let mFrom = _.findIndex(productlist, fromO);
		let mTo = _.findIndex(productlist, toO);
		//list[a] = list.splice(b, 1, list[a])[0];
		productlist.splice(mTo, 0, productlist.splice(mFrom, 1)[0]);
		productlist = _.map(productlist, (it, i) => {
			it.order = i;
			return it;
		});
		this.setState({
			productlist,
		});
	}
	fileUpload(file, name, ind, category) {
		const url = 'https://uploads.yapaytech.com/flow/uploadImage';
		const formData = new FormData();
		formData.append('files[]', file);
		let image_url = '';
		fetch(url, {
			method: 'post',
			body: formData,
		})
			.then((response) => {
				return response.json();
			})
			.then(async (data) => {
				image_url = data && data.files && data.files[0] && data.files[0].url;
				let productlist = this.state.productlist;
				let product_list = _.find(productlist, { url: ind });
				product_list.data.images[name.replace(/images_/, '')] = image_url;
				this.setState({ productlist });
			});
	}
	handleFileInput = (e, ind, category) => {
		let file = e.target.files[0];
		let name = e.target.name;
		this.fileUpload(file, name, ind, category);
	};
	async componentDidMount() {
		let pid = this.props.product.id;
		await log('/api/readItems', { where: { pid } }).then((data) => {
			let productlist = data.data.data;
			this.setState({ productlist });
		});
		await log('/api/readItems', { where: { pid } }).then((data) => {
			let startList = data.data.data;
			this.setState({ startList, loading: false });
		});
	}
	render() {
		let { list, saveLoad, productlist, loading, openlist, opendenI, openedOi } = this.state;
		const { t, product } = this.props;
		let pid = product.id;
		if (loading) return <Page.Loader></Page.Loader>;
		return (
			<Segment>
				<Header>{t('products')}</Header>
				{_.map(list, (it, i) => {
					let product_list = _.filter(productlist, (oo) => {
						return oo.data.cate_id === it.id && oo.data.deleted === false;
					});
					return (
						<div style={{ marginBottom: '20px' }}>
							<Message size="large" style={{ cursor: 'pointer' }} onClick={() => this.setState({ openlist: openlist === i ? '' : i })}>
								<div style={{ display: 'flex' }}>
									<span>{it.name + ' (' + (product_list.length || 0) + ')'}</span>
									<Icon name={openlist === i ? 'angle down' : 'angle up'} style={{ marginLeft: 'auto', cursor: 'pointer' }} />
								</div>
							</Message>
							{openlist === i &&
								_.map(product_list, (o, oi) => (
									<div key={oi} i={oi} action={this.swap.bind(this, it.name)}>
										<Grid columns="equal" style={{ borderBottom: '1px solid grey', marginBottom: '20px' }}>
											{/* <Grid.Column width={1}>
											<div style={{ display: 'flex', alignItems: 'center', height: '100%', cursor: 'grab' }}>
												<Icon name="arrows alternate vertical" size="large"></Icon>
											</div>
										</Grid.Column> */}
											<Grid.Column width={13}>
												<Form>
													<Form.Group widths="equal">
														<Form.Input
															label={t('productName')}
															value={o.data.name}
															required
															ind={o.url}
															name="name"
															category={it.name}
															width={4}
															onChange={this.handleArrayChange}
														/>
														<div>
															{_.map(o.data.images, (im, im_i) => (
																<Form.Field>
																	{im_i === 0 ? (
																		<label
																			style={{ cursor: 'pointer', width: '253px', display: 'flex' }}
																			onClick={() => {
																				if (oi === openedOi && i === opendenI) {
																					this.setState({ openedImage: false, openedOi: null, opendenI: null });
																				} else {
																					this.setState({ openedImage: true, openedOi: oi, opendenI: i });
																				}
																			}}
																		>
																			{t('image') + '(' + o.data.images.length + ')'}
																		</label>
																	) : (
																		''
																	)}
																	{opendenI === i && openedOi === oi && (
																		<Form.Input
																			action
																			value={im}
																			ind={o.url}
																			name={'images_' + im_i}
																			category={it.name}
																			onChange={this.handleArrayChange}
																		>
																			<input />
																			<Button
																				icon
																				type="button"
																				size="tiny"
																				basic
																				color="red"
																				style={{ borderRadius: '0 .28571429rem .28571429rem 0' }}
																				onClick={() => this.handleRemoveImage(im_i, o.url, 'images')}
																			>
																				<Icon name="trash"></Icon>
																			</Button>
																			<div
																				className="image-upload"
																				style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}
																			>
																				<label htmlFor={'file-input_image_' + it.name + '_' + oi + '_' + im_i}>
																					<Icon
																						name="upload"
																						color="grey"
																						size="large"
																						style={{ cursor: 'pointer' }}
																					></Icon>
																				</label>
																				<Input
																					id={'file-input_image_' + it.name + '_' + oi + '_' + im_i}
																					type="file"
																					name={'images_' + im_i}
																					accept="*"
																					ind={o.id}
																					style={{ display: 'none' }}
																					category={it.name}
																					onChange={(e) => this.handleFileInput(e, o.url, it.name)}
																				/>
																			</div>
																		</Form.Input>
																	)}
																</Form.Field>
															))}
															<Button
																icon
																basic
																color="green"
																size="mini"
																type="button"
																style={{ marginTop: '5px' }}
																onClick={() => {
																	this.handleAddImages(o.url, 'images');
																}}
															>
																<Icon name="plus"></Icon>
															</Button>
														</div>
														<Form.Input
															width={2}
															required
															label={t('price')}
															value={o.data.price}
															ind={o.url}
															name="price"
															category={it.name}
															onChange={this.handleArrayChange}
														/>
														<Form.Input
															width={2}
															label={t('discoutprice')}
															value={o.data.discoutprice}
															ind={o.url}
															name="discoutprice"
															category={it.name}
															onChange={this.handleArrayChange}
														/>
														<div>
															{_.map(o.data.options, (im, im_i) => (
																<Form.Field>
																	{im_i === 0 ? (
																		<label style={{ cursor: 'pointer', width: '253px', display: 'flex' }}>
																			{t('option') + '(' + o.data.options.length + ')'}
																		</label>
																	) : (
																		''
																	)}
																	{opendenI === i && openedOi === oi && (
																		<Form.Input
																			action
																			value={im.name}
																			ind={o.url}
																			name={'options_' + im_i}
																			category={it.name}
																			onChange={this.handleArrayChange}
																		>
																			<input />
																			<Popup
																				trigger={
																					<Button
																						icon
																						basic
																						color="black"
																						onClick={() => this.handleArrayOptionsStatus(o.url, im_i)}
																					>
																						<Icon name={im.status ? 'check' : 'close'}></Icon>
																					</Button>
																				}
																				content={'Status'}
																			/>
																			<Button
																				icon
																				type="button"
																				size="tiny"
																				basic
																				color="red"
																				onClick={() => this.handleRemoveImage(im_i, o.url, 'options')}
																			>
																				<Icon name="trash"></Icon>
																			</Button>
																		</Form.Input>
																	)}
																</Form.Field>
															))}
															<Button
																icon
																basic
																color="green"
																size="mini"
																type="button"
																style={{ marginTop: '5px' }}
																onClick={() => {
																	this.handleAddImages(o.url, 'options');
																}}
															>
																<Icon name="plus"></Icon>
															</Button>
														</div>
													</Form.Group>
												</Form>
											</Grid.Column>
											<Grid.Column style={{ justifyContent: 'center', display: 'flex' }}>
												<Form>
													<Form.Field
														required
														style={{
															display: 'block',
															margin: '0 0 1.28571429rem 0',
															color: 'rgba(0,0,0,.87)',
															fontSize: '.92857143em',
															fontWeight: '700',
															textTransform: 'none',
														}}
													>
														{t('status') + ': '}
													</Form.Field>
													<Form.Checkbox
														toggle
														checked={o.data.status}
														name={o.url}
														onChange={this.handleArrayToggle}
														category={it.name}
													></Form.Checkbox>
												</Form>
											</Grid.Column>
											<Grid.Column style={{ textAlign: 'center', marginTop: '10px' }}>
												<Button
													icon
													basic
													color="black"
													onClick={() => {
														if (oi === openedOi && i === opendenI) {
															this.setState({ openedOption: false, openedOi: null, opendenI: null });
														} else {
															this.setState({ openedOption: true, openedOi: oi, opendenI: i });
														}
													}}
												>
													<Icon name={openedOi === oi && opendenI === i ? 'angle down' : 'angle up'} />
												</Button>
												<Button icon basic color="red" onClick={() => this.handleRemove(o.url, it.name, o.data.name)}>
													<Icon name="trash"></Icon>
												</Button>
											</Grid.Column>
										</Grid>
									</div>
								))}
							<Button
								basic
								color="green"
								onClick={() => {
									productlist.push({
										url: pid + '-wcart-#' + productlist.length,
										data: {
											name: '',
											title: it.name,
											cate_id: it.id,
											price: '',
											status: false,
											deleted: false,
											images: [''],
											options: [{ name: '', status: true }],
											wcart: true,
										},
										pid,
										title: '',
										price: '',
									});
									this.setState({ productlist, openlist: i });
								}}
							>
								{t('add')}
							</Button>
						</div>
					);
				})}
				<div style={{ marginTop: '30px', display: 'flex' }}>
					<Button color="green" onClick={() => this.postData()} loading={saveLoad}>
						{t('save')}
					</Button>
				</div>
				{/* {JSON.stringify(_.differenceWith(productlist, startList, _.isEqual), null, 2)} */}
			</Segment>
		);
	}
}

export default connect(
	(state) => ({ user: state.access.user.loggedUserObj, product: state.product.live }),
	(dispatch) => ({ updateUser: (user) => dispatch(updateProfile(user)) })
)(withTranslation()(UserPage));
