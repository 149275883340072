import React, { Component } from 'react';
import MyMenu from '../menu';
import { connect } from 'react-redux';
import { Icon, Breadcrumb, Segment, Form, Input, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Page from '../../../components/Page';
import { get, post } from '../../../util/api';
import AccountProgress from '../../../components/AccountProgress';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

class EcommercePayments extends Component {
	state = {
		name: this.props.user && this.props.user.name,
		email: this.props.user && this.props.user.email,
		phone: this.props.user && this.props.user.phone,
		loading: true,
	};
	componentDidMount() {
		this.getPayments();
	}
	async getPayments() {
		let data = await get('/dahi/channel/get/ticimax');
		let config = data && data.data && data.data.config;
		let ecomerce_type = data && data.data && data.data.type;
		if (config) {
			this.setState({ config, ecomerce_type, loading: false });
		} else {
			this.setState({ error: true, loading: false });
		}
	}
	handleArrayChange(name, value, i, o, p) {
		let { config } = this.state;
		let { payments } = config;
		if (name === 'door_price') {
			payments[i].price = parseFloat(value);
		} else if (name === 'one_price') {
			payments[i].firms[o].price = parseFloat(value);
		} else {
			//console.log(payments[i].firms[o].price[p][name], name, value, i, o, p);
			payments[i].firms[o].price[p][name] = parseFloat(value);
		}
		this.setState({ config });
	}
	savePayments() {
		this.setState({ loadingSave: true });
		let { config, ecomerce_type } = this.state;
		post('/dahi/channel/save', { channel: ecomerce_type, data: config }).then(() => this.setState({ loadingSave: false }));
	}

	render() {
		let { config, error, loadingSave } = this.state;
		const { t } = this.props;
		if (this.state.loading) {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="shopify">
						<Page.Loader />
					</MyMenu>
				</Page>
			);
		}
		if (error) {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="ecommercepayment">
						<Page.Wrap>
							<Segment>{t('noPaymentMethods')}</Segment>
						</Page.Wrap>
					</MyMenu>
				</Page>
			);
		}
		let { payments } = config;
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/settings`}>
							<Icon name="setting" />
							{t('settings')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
							{t('integrations')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />
						<Breadcrumb.Section>{t('ecommerce')}</Breadcrumb.Section>
					</Breadcrumb>
				}
				extra={
					<div className="ml-auto d-flex">
						<AccountProgress></AccountProgress>
						<Button className="ml-auto" primary type="submit" onClick={() => this.savePayments()} loading={loadingSave}>
							{t('saveChanges')}
						</Button>
					</div>
				}
				flex
			>
				<MyMenu activeItem="request">
					<Page.Wrap>
						<Segment>
							{_.map(payments, (it, i) => (
								<div key={'payments_' + i} className="main-payments">
									<div style={{ width: '30%', marginRight: '40px', padding: '10px' }}>
										{<h3>{t('paymentMethods')}</h3>}
										<Input style={{ marginBottom: '5px' }} readOnly value={it.name} fluid></Input>
										<Input
											fluid
											label={t('doorPrice')}
											value={it.price}
											type="number"
											onChange={(e, { value }) => this.handleArrayChange('door_price', value, i)}
										></Input>
									</div>
									<div className="firms-payments">
										{_.map(it.firms, (o, oi) => (
											<div key={'firms_' + oi} className="firms-box" style={{ marginBottom: '20px' }}>
												<div style={{ width: '100%', marginRight: '40px' }}>
													{<h3>{t('cargoFirm')}</h3>}
													<Input readOnly value={o.name} fluid style={{ marginBottom: '10px' }}></Input>
												</div>
												<div className="firms-prices">
													{<h3>{t('prices')}</h3>}
													{_.isArray(o.price) ? (
														_.map(o.price, (p, pi) => (
															<div key={'price_' + pi} style={{ marginBottom: '20px' }}>
																<Form>
																	{p.total >= 0 && (
																		<Input
																			fluid
																			style={{ marginBottom: '5px', width: '100%' }}
																			label={t('cartTotalPrice')}
																			value={p.total}
																			type="number"
																			onChange={(e, { value }) => this.handleArrayChange('total', value, i, oi, pi)}
																		></Input>
																	)}
																	{p.count && (
																		<Input
																			fluid
																			style={{ marginBottom: '5px', width: '100%' }}
																			label={t('cartTotalItem')}
																			value={p.count}
																			type="number"
																			onChange={(e, { value }) => this.handleArrayChange('count', value, i, oi, pi)}
																		></Input>
																	)}
																	<Input
																		fluid
																		style={{ marginBottom: '5px', width: '100%' }}
																		label={
																			p.total
																				? t('cargoPriceUp', { price: p.total })
																				: t('cargoPriceDown', { price: p.total })
																		}
																		value={p.price}
																		type="number"
																		onChange={(e, { value }) => this.handleArrayChange('price', value, i, oi, pi)}
																	></Input>
																</Form>
															</div>
														))
													) : (
														<Form>
															<Input
																fluid
																style={{ marginBottom: '5px', width: '100%' }}
																label={t('cargoPrice')}
																value={o.price}
																type="number"
																onChange={(e, { value }) => this.handleArrayChange('one_price', value, i, oi)}
															></Input>
														</Form>
													)}
												</div>
											</div>
										))}
									</div>
								</div>
							))}
						</Segment>
					</Page.Wrap>
				</MyMenu>
			</Page>
		);
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.live,
	user: state.access.user.loggedUserObj,
});
export default connect(stateToProps)(withTranslation()(EcommercePayments));
