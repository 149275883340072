import React, { Component } from 'react';
import { Button, Icon, Header, List, Segment, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import 'react-intl-tel-input/dist/main.css';
import { withTranslation } from 'react-i18next';
import Page from '../../../components/Page';
import { Link } from 'react-router-dom';
class PlatformModal extends Component {
	state = {
		selectedPlatform: '',
		activePage: 1,
		btnLoading: false,
	};

	render() {
		let { t } = this.props;

		return (
			<Page.Wrap>
				<Segment>
					<div>
						<Button basic color="grey" labelPosition="left" icon size="small" as={Link} to="/appstore/help">
							<Icon name="arrow left"></Icon>
							{t('back')}
						</Button>
						<Header>{t('label')} </Header>
					</div>
					<div>
						<p>Konuşmaları üzerinize alabilmek için WhatsApp Business uygulamasından etiket tanımlaması yapmanız gerekmektedir.</p>
						<List ordered>
							<List.Item>Telefonunuzdan WhatsApp Business uygulamasını açıp sağ üstteki seçeneklerden "Etiketler" bölümünü seçiniz.</List.Item>
							<List.Item>Açılan pencerede sağ alttaki "+" butonu ile yeni etiket ekleyiniz.</List.Item>
							<List.Item>
								<span className="label-missing-mail">{'test@dialogtab.com'}</span> gibi tüm agentlar için etiket oluşturmanız gerekmektedir.
							</List.Item>
							<List.Item>Etiketi oluşturduktan sonra WhatsApp Web sayfasını yenileyiniz.</List.Item>
						</List>
						<Image src="https://cdnydm.com/media/g68ToFfhxbA1mAAPcWKryQ.png?size=3234x2130" size="huge" style={{ borderRadius: '3px' }}></Image>
					</div>
				</Segment>
			</Page.Wrap>
		);
	}
}
const stateToProps = (state) => ({ user: state.access.user.loggedUserObj, product: state.product.live });

export default connect(stateToProps)(withTranslation()(PlatformModal));
