import { connect } from 'react-redux';
//import 'regenerator-runtime/runtime';
import React from 'react';
import { Button, Container, Segment, Popup, Icon, Input, Dropdown } from 'semantic-ui-react';
import { YMaps, Map } from 'react-yandex-maps';
import Page from '../../components/Page';
const mapState = {
	center: [41.08609786163481, 29.076447618124238],
	zoom: 18,
	type: 'yandex#satellite',
	bounds: undefined,
	control: [],
};

/* const mapState2 = {
	zoom: 18,
	type: 'yandex#satellite',
	bounds: undefined,
	control: [],
}; */

function UploadTextInput(props) {
	function fileUpload(file) {
		const url = 'https://uploads.yapaytech.com/flow/uploadImage';
		const formData = new FormData();
		formData.append('files[]', file);
		let image_url = '';
		fetch(url, {
			method: 'post',
			body: formData,
		})
			.then((response) => {
				return response.json();
			})
			.then(async (data) => {
				image_url = data && data.files && data.files[0] && data.files[0].url;
				handleChange(image_url);
			});
	}
	const handleFileInput = (e) => {
		let file = e.target.files[0];
		fileUpload(file);
	};
	const handleChange = (value) => {
		props.setUrl(value);
	};
	return (
		<>
			<Input
				type={props.type || 'text'}
				placeholder={props.placeholder || '...'}
				value={props.url}
				style={{ marginLeft: '10px', marginRight: '10px' }}
				onChange={(e, { value }) => {
					handleChange(value);
				}}
			/>
			<Popup
				content={'Upload Image'}
				trigger={
					<label htmlFor="file-input" className="ui small icon button image-upload">
						<Icon name="upload" color="blue" size="large" style={{ cursor: 'pointer' }}></Icon>
					</label>
				}
			/>
			<input
				id="file-input"
				type="file"
				accept=".jpg, .jpeg"
				style={{ display: 'none' }}
				onChange={(e) => {
					handleFileInput(e);
				}}
			/>
		</>
	);
}

class App extends React.PureComponent {
	onMapClick(event) {
		this.setState({ lat: event.get('coords') });
	}

	state = {
		url: 'https://cdn.yapaytech.com/mapai/test8.jpg',
		step: 1,
		zoom: true,
		type: 'yandex-map',
		lat: [41.08609786163481, 29.076447618124238],
		showMap: true,
		width: 400,
		height: 400,
	};

	setUrl = (url) => this.setState({ url });

	setStep = (step) => this.setState({ step });

	setImageUrl() {
		let { type, lat, url, image_url, image_url2 } = this.state;
		//console.log('type', type);
		switch (type) {
			case 'url2':
				image_url = `https://mapapi.yapaytech.com/?type=url2&url=${url}`;
				break;
			case 'url':
				image_url = `https://mapapi.yapaytech.com/?type=url&url=${url}`;
				break;
			case 'yandex':
				image_url = `https://mapapi.yapaytech.com/?type=yandex2&location=${lat}`;
				image_url2 = `https://mapapi.yapaytech.com/?type=yandex&location=${lat}`;
				break;
			case 'yandex-map':
				image_url = `https://mapapi.yapaytech.com/?type=yandex2&location=${lat}`;
				image_url2 = `https://mapapi.yapaytech.com/?type=yandex&location=${lat}`;
				break;
			default:
				break;
		}
		this.setState({ image_url2, image_url, loading: true });
	}

	toggleMap() {
		const { showMap } = this.state;
		this.setState({ showMap: !showMap });
	}

	onBoundsChange = () => {
		this.setState({
			lat: this.state.map.getCenter(),
		});
	};

	render() {
		let { type, lat, image_url, image_url2, loading, showMap } = this.state;
		let queryType = [
			{
				text: 'Yandex Map',
				value: 'yandex-map',
			},
			{
				text: 'Koordinat',
				value: 'yandex',
			},
			{
				text: 'Url',
				value: 'url',
			},
			{
				text: 'Url Resize',
				value: 'url2',
			},
		];

		return (
			<Page title="Çatı">
				<Page.Wrap>
					<Container id="yt-roof">
						{this.state.step === 1 && (
							<Segment>
								<div className="step-1" style={{ marginBottom: '30px' }}>
									<Dropdown selection options={queryType} value={type} onChange={(e, { value }) => this.setState({ type: value })}></Dropdown>
									{type === 'yandex' ? (
										<div className="query_config">
											<Input
												name="lat"
												placeholder="Location"
												value={lat}
												onChange={(e, { value }) => this.setState({ lat: value })}
												style={{ marginLeft: '10px', marginRight: '10px' }}
											></Input>
											{/* <Input
										name="lon"
										placeholder="Longitude"
										value={lon}
										onChange={(e, { value }) => this.setState({ lon: value })}
										style={{ marginLeft: '10px', marginRight: '10px' }}
									></Input> */}
											<Button onClick={() => this.setImageUrl()}>Çatıları Bul</Button>
										</div>
									) : type === 'yandex-map' ? (
										<div className="query_config" style={{ marginLeft: '30px' }}>
											<Button onClick={() => this.setImageUrl()}>Çatıları Bul</Button>
										</div>
									) : (
										<div className="query_config">
											<UploadTextInput url={this.state.url} setUrl={this.setUrl} />
											<Button onClick={() => this.setImageUrl()}>Çatıları Bul</Button>
										</div>
									)}
								</div>
								{type === 'yandex-map' ? (
									<YMaps>
										<div style={{ paddingBottom: '15px' }}>Center: {JSON.stringify(this.state.lat)}</div>
										<div id="map-basics">
											{showMap && (
												<Map
													onClick={this.onMapClick.bind(this)}
													state={mapState}
													instanceRef={(map) => this.setState({ map })}
													onBoundsChange={this.onBoundsChange}
												/>
											)}
											{/* <button onClick={() => setZoom(zoom + 1)}>Zoom in</button> */}
										</div>
									</YMaps>
								) : (
									<div></div>
								)}
								{type !== 'yandex' && type !== 'yandex-map' && <p style={{ marginTop: '5px' }}>Only jpg and jpeg format is accepted.</p>}
								{loading && type !== 'yandex-map' && <Icon className="loading-icon" name="spinner" loading size="big"></Icon>}
								{loading && type === 'yandex-map' && (
									<Icon
										style={{ position: 'absolute', right: '0', top: '0' }}
										className="loading-icon"
										name="spinner"
										loading
										size="big"
									></Icon>
								)}
								{image_url && (
									<>
										{/* <div style={{ textAlign: 'center' }}>{!loading && <a style={{ fontSize: '30px', color: 'black' }}>SONUÇLAR</a>}</div> */}
										<div className="canvas-wrap" style={{ paddingTop: '20px' }}>
											{!loading && (
												<p
													style={{
														fontSize: '20px',
														paddingBottom: '14px',
														display: 'block',
														borderTop: '2px solid',
														paddingTop: '20px',
														borderColor: '#ddd',
														color: 'black',
													}}
												>
													1. Sonuç
												</p>
											)}
											<img style={{ width: '100%' }} src={image_url} alt="" onLoad={() => this.setState({ loading: false })}></img>
											{!loading && (
												<p
													style={{
														fontSize: '20px',
														paddingBottom: '14px',
														display: 'block',
														borderTop: '2px solid',
														paddingTop: '20px',
														borderColor: '#ddd',
														color: 'black',
													}}
												>
													2. Sonuç
												</p>
											)}
											<img style={{ width: '100%' }} src={image_url2} alt="" onLoad={() => this.setState({ loading: false })}></img>
										</div>
									</>
								)}
							</Segment>
						)}

						{/* {this.state.step === 2 && (
					<Segment className={'step-2 ' + (this.state.zoom ? 'zoom-in' : '')}>
						<Button onClick={() => this.setStep(1)}>Go To Url Change</Button>

						<DataPreview url={this.state.url} type={type} location={lat + ',' + lon} />
					</Segment>
				)} */}
					</Container>
				</Page.Wrap>
			</Page>
		);
	}
}

export default connect((state) => ({ user: state.access.user.loggedUserObj, product: state.product.live }))(App);
