import React, { Component } from 'react';
import Page from '../../../components/Page';
import { Icon, Breadcrumb, Button, Modal, Header, Segment, List, Form } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { post } from '../../../util/api';
import MyMenu from '../menu';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

class Phones extends Component {
	phoneToDelete = null;
	state = {
		loading: true,
		saveButton: 'add',
	};
	componentDidMount() {
		this.getList();
	}
	async getList() {
		let defaultTags = [
			{ title: 'Urgent', color: '#ed5565', active: true },
			{ title: 'Lead', color: '#5d9cec', active: true },
			{ title: 'Customer', color: '#a0d468', active: true },
			{ title: 'Finance', color: '#FFCE54', active: true },
		];
		let list = await post('/dahi/tag/list', {});
		this.setState({
			tag_list: (list.data && list.data.result && list.data.result.result && _.filter(list.data.result.result, { active: true })) || defaultTags,
			loading: false,
		});
	}
	handleChange = (e, { name, value }) => {
		let { selected, tag_list } = this.state;
		tag_list[selected][name] = value;
		this.setState({ tag_list });
	};
	colorChance(value) {
		let { selected, tag_list } = this.state;
		tag_list[selected].color = value;
		this.setState({ tag_list });
	}
	addTagList() {
		let { tag_list } = this.state;
		if (!tag_list.length || tag_list[tag_list.length - 1]._id)
			tag_list.push({
				title: '',
				color: '#5d9cec',
			});
		this.setState({ tag_list, selected: tag_list.length - 1, saveButton: 'add' });
	}
	async saveTag() {
		let { selected, tag_list } = this.state;
		this.setState({ saveButtonLoading: true });
		let selectedTag = tag_list[selected];
		selectedTag.tag_id = tag_list[selected]._id;
		await post('/dahi/tag/upsert', selectedTag);
		await this.getList();
		this.setState({ saveButtonLoading: false });
	}
	async deleteTag() {
		let { selected, tag_list } = this.state;
		this.setState({ deleteButtonLoading: true });
		let selectedTag = tag_list[selected];
		selectedTag.tag_id = tag_list[selected]._id;
		await post('/dahi/tag/remove', selectedTag);
		await this.getList();
		this.setState({ deleteButtonLoading: false, deleteModal: false });
	}
	render() {
		const { t } = this.props;
		let { tag_list, selected, saveButton, saveButtonLoading, deleteButtonLoading } = this.state;
		if (this.state.loading) {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="label">
						<Page.Loader />
					</MyMenu>
				</Page>
			);
		} else {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />
							<Breadcrumb.Section>{t('label')}</Breadcrumb.Section>
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="label">
						<Page.Wrap>
							<Segment style={{ display: 'flex', padding: '0' }}>
								<div className="tag-list">
									<div className="head" style={{ display: 'flex' }}>
										<h3>{t('labels')}</h3>
										<div className="add-button">
											<Button icon circular color="green" size="mini" onClick={() => this.addTagList()}>
												<Icon name="add"></Icon>
											</Button>
										</div>
									</div>
									<List style={{ margin: '0' }}>
										{_.map(tag_list, (it, i) => (
											<List.Item
												className="list-item"
												style={{ display: 'flex', fontSize: '16px', lineHeight: '40px' }}
												onClick={() => this.setState({ selected: i, saveButton: 'change' })}
											>
												<Icon
													name="circle"
													style={{
														color: it.color,
														marginLeft: '20px',
														display: 'flex',
														alignItems: 'center',
													}}
												></Icon>
												<span style={{ width: '100%', marginLeft: '10px' }}>{' ' + it.title}</span>
											</List.Item>
										))}
									</List>
								</div>
								<div className="tag-editor">
									<Page.Wrap>
										<Form>
											<Form.Input
												required
												label={t('name')}
												value={(tag_list[selected] && tag_list[selected].title) || ''}
												name="title"
												onChange={this.handleChange}
												disabled={!(selected >= 0)}
											></Form.Input>
											<Form.Field disabled={!(selected >= 0)}>
												<label>{t('color')}</label>

												<div className="tag-color">
													<ul className="tag-color-colors">
														<li
															className="tag-color-color-item"
															onClick={() => this.colorChance('#5d9cec')}
															style={{ background: '#5d9cec' }}
														>
															<div
																className="tag-color-dot"
																style={{
																	display: (tag_list[selected] && tag_list[selected].color) === '#5d9cec' ? 'block' : 'none',
																}}
															></div>
														</li>
														<li
															className="tag-color-color-item"
															onClick={() => this.colorChance('#4fc1e9')}
															style={{ background: '#4fc1e9' }}
														>
															<div
																className="tag-color-dot"
																style={{
																	display: (tag_list[selected] && tag_list[selected].color) === '#4fc1e9' ? 'block' : 'none',
																}}
															></div>
														</li>
														<li
															className="tag-color-color-item"
															onClick={() => this.colorChance('#48cfad')}
															style={{ background: '#48cfad' }}
														>
															<div
																className="tag-color-dot"
																style={{
																	display: (tag_list[selected] && tag_list[selected].color) === '#48cfad' ? 'block' : 'none',
																}}
															></div>
														</li>
														<li
															className="tag-color-color-item"
															onClick={() => this.colorChance('#a0d468')}
															style={{ background: '#a0d468' }}
														>
															<div
																className="tag-color-dot"
																style={{
																	display: (tag_list[selected] && tag_list[selected].color) === '#a0d468' ? 'block' : 'none',
																}}
															></div>
														</li>
														<li
															className="tag-color-color-item"
															onClick={() => this.colorChance('#ffce54')}
															style={{ background: '#ffce54' }}
														>
															<div
																className="tag-color-dot"
																style={{
																	display: (tag_list[selected] && tag_list[selected].color) === '#ffce54' ? 'block' : 'none',
																}}
															></div>
														</li>
														<li
															className="tag-color-color-item"
															onClick={() => this.colorChance('#fc6e51')}
															style={{ background: '#fc6e51' }}
														>
															<div
																className="tag-color-dot"
																style={{
																	display: (tag_list[selected] && tag_list[selected].color) === '#fc6e51' ? 'block' : 'none',
																}}
															></div>
														</li>
														<li
															className="tag-color-color-item"
															onClick={() => this.colorChance('#ED5565')}
															style={{ background: '#ED5565' }}
														>
															<div
																className="tag-color-dot"
																style={{
																	display: (tag_list[selected] && tag_list[selected].color) === '#ED5565' ? 'block' : 'none',
																}}
															></div>
														</li>
														<li
															className="tag-color-color-item"
															onClick={() => this.colorChance('#ac92ec')}
															style={{ background: '#ac92ec' }}
														>
															<div
																className="tag-color-dot"
																style={{
																	display: (tag_list[selected] && tag_list[selected].color) === '#ac92ec' ? 'block' : 'none',
																}}
															></div>
														</li>
														<li
															className="tag-color-color-item"
															onClick={() => this.colorChance('#ec87c0')}
															style={{ background: '#ec87c0' }}
														>
															<div
																className="tag-color-dot"
																style={{
																	display: (tag_list[selected] && tag_list[selected].color) === '#ec87c0' ? 'block' : 'none',
																}}
															></div>
														</li>
														{/* <li
															className="tag-color-color-item"
															onClick={() => this.colorChance('#e6e9ed')}
															style={{ background: '#e6e9ed' }}
														>
															<div
																className="tag-color-dot"
																style={{
																	display: (tag_list[selected] && tag_list[selected].color) === '#e6e9ed' ? 'block' : 'none',
																}}
															></div>
														</li> */}
														<li
															className="tag-color-color-item"
															onClick={() => this.colorChance('#aab2bd')}
															style={{ background: '#aab2bd' }}
														>
															<div
																className="tag-color-dot"
																style={{
																	display: (tag_list[selected] && tag_list[selected].color) === '#aab2bd' ? 'block' : 'none',
																}}
															></div>
														</li>
														<li
															className="tag-color-color-item"
															onClick={() => this.colorChance('#434a54')}
															style={{ background: '#434a54' }}
														>
															<div
																className="tag-color-dot"
																style={{
																	display: (tag_list[selected] && tag_list[selected].color) === '#434a54' ? 'block' : 'none',
																}}
															></div>
														</li>
													</ul>
												</div>
											</Form.Field>
											<div style={{ display: 'flex' }}>
												<Button positive disabled={!(selected >= 0)} onClick={() => this.saveTag()} loading={saveButtonLoading}>
													{t(saveButton)}
												</Button>
												<Button
													basic
													color="red"
													onClick={() => this.setState({ deleteModal: true })}
													style={{ marginLeft: 'auto' }}
													disabled={saveButton === 'add'}
												>
													{t('delete')}
												</Button>
											</div>
										</Form>
									</Page.Wrap>
								</div>
								<Modal
									open={this.state.deleteModal}
									onClose={() => {
										this.setState({ deleteModal: false });
									}}
									style={{ width: 500 }}
								>
									<Header icon="dont" content={t('delete')} />
									<Modal.Content>
										<p>{t('areYouSureDeleteTag')}</p>
									</Modal.Content>
									<Modal.Actions>
										<Button
											color="red"
											onClick={() => {
												this.setState({ deleteModal: false });
											}}
										>
											<Icon name="remove" /> {t('no')}
										</Button>
										<Button color="green" onClick={() => this.deleteTag()} loading={deleteButtonLoading}>
											<Icon name="checkmark" /> {t('yes')}
										</Button>
									</Modal.Actions>
								</Modal>
							</Segment>
						</Page.Wrap>
					</MyMenu>
				</Page>
			);
		}
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.live,
	user: state.access.user.loggedUserObj,
});

export default connect(stateToProps)(withTranslation()(Phones));
