import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Icon, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { logout } from '../actions/access.actions';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const MarginZero = { fontSize: '1.25em', opacity: 0.7 };
const HeaderItem = (props) => {
	if (props.onClick) {
		if (props.flag) {
			return (
				<div className="header-item item" onClick={props.onClick} name={props.name}>
					<img src={`/icons/flags/${props.flag}.png`} alt={`/icons/flags/${props.flag}.png`} style={{ width: '18px' }}></img>
					<br></br>
					<p className="title"> {props.name}</p>
				</div>
			);
		}
		return (
			<div className="header-item item" onClick={props.onClick} name={props.name}>
				<Icon name={props.icon} style={MarginZero} />

				<span className="title">{props.name}</span>
			</div>
		);
	}
	return (
		<NavLink className="header-item item" {...props}>
			<Icon name={props.icon} style={MarginZero} />
			<span className="title">{props.name}</span>
		</NavLink>
	);
};

/* function ChangeLanguage() {
	const { i18n } = useTranslation();
	return (
		<HeaderItem
			onClick={() => {
				i18n.changeLanguage(i18n.language === 'tr' ? 'en' : 'tr');
			}}
			flag={i18n.language === 'tr' ? 'tr' : 'gb'}
			name={i18n.language === 'tr' ? 'TR' : 'EN'}
		/>
	);
} */
function ChangeLanguageNew() {
	const [openModal, setOpenModal] = useState(0);
	const { i18n } = useTranslation();
	let langs = [
		{ flag: 'gb', key: 'en', text: 'English' },
		{ flag: 'tr', key: 'tr', text: 'Türkçe' },
		{ flag: 'de', key: 'de', text: 'Deutsche' },
		{ flag: 'fr', key: 'fr', text: 'Français' },
		{ flag: 'es', key: 'es', text: 'Español' },
		{ flag: 'pt', key: 'pt', text: 'Português' },
		{ flag: 'ru', key: 'ru', text: 'Pусский' },
	];
	let selectedLang = _.find(langs, { key: i18n.languages[0] }) || _.find(langs, { key: 'en' });
	if (openModal) {
		return (
			<>
				<HeaderItem onClick={() => setOpenModal(true)} flag={selectedLang.flag} name={selectedLang.text} />
				<Modal
					open={openModal}
					closeIcon
					onClose={() => {
						setOpenModal(false);
					}}
				>
					<Modal.Content style={{ background: 'lightgray' }}>
						<div className="lang-modal" style={{ justifyContent: 'center' }}>
							{_.map(langs, (o) => {
								return (
									<div
										className="header-item item"
										key={o.key}
										name={o.text}
										style={{ textAlign: 'center', fontSize: '22px', marginLeft: '10px', marginRight: '10px' }}
										onClick={() => {
											i18n.changeLanguage(o.key);
											setOpenModal(false);
										}}
									>
										<img src={`/icons/flags/${o.flag}.png`} alt={`/icons/flags/${o.flag}.png`}></img>
										<br></br>
										<p className="title"> {o.text} </p>
									</div>
								);
							})}
						</div>
					</Modal.Content>
				</Modal>
			</>
		);
	}
	return <HeaderItem onClick={() => setOpenModal(true)} flag={selectedLang.flag} name={selectedLang.text} />;
}

/*<Menu.Item as={NavLink} to="/variables" name="Variables">
			<Icon name="dolly" />
			Variables
		</Menu.Item>*/

const Header = (props) => {
	const { t } = useTranslation();
	const isAgent = props.user && props.user.extra && props.user.extra.__isAgent;
	const isExtApp = props.product && props.product.settings && props.product.settings.panel === 'ext';
	const wgroup = props.user && props.user.extra && props.user.extra.wgroup;
	const wcart = props.user && props.user.extra && props.user.extra.wcart;
	const isHideAnalyticAgent = props.product && props.product.settings && props.product.settings.isHideAnalyticAgent;
	const roleAgent = props.user && props.user.role && props.user.role === 'agent';
	const reseller = props.user && props.user.extra && props.user.extra.reseller;
	let wsendmessage = props.product && props.product.settings && props.product.settings.wsendmessage;
	let wvalidation = props.product && props.product.settings && props.product.settings.wvalidation;
	let showWABroadcast = wsendmessage && !roleAgent;
	let platform = props.product && props.product.settings && props.product.settings.panel;
	if (platform === 'roof') {
		return (
			<div className="ui vertical icon menu navbar">
				<Link className="item console-logo" to="/">
					<img src={window.CONFIG.icon} alt="" />
				</Link>
				<HeaderItem exact to="/" name="Çatı" icon="map" />
				<div className="mt-auto" />
				<div className="header-seperator"></div>
				<ChangeLanguageNew />
				<HeaderItem to="/account" name={t('myAccount')} icon="user" />
				<HeaderItem onClick={props.logoutUser} name={t('logout')} icon="log out" />
			</div>
		);
	}
	return (
		<div className="ui vertical icon menu navbar">
			<Link className="item console-logo" to="/">
				<img src={window.CONFIG.icon} alt="" />
			</Link>
			{reseller ? <HeaderItem to="/reseller" name={t('Partnership')} icon="handshake" /> : null}
			{reseller || isExtApp ? null : <HeaderItem exact to="/" name="Chat" icon="chat" />}
			{reseller || isExtApp ? null : isHideAnalyticAgent && roleAgent ? null : roleAgent ? (
				<HeaderItem to="/analytic/agent" name={t('analytic')} icon="chart bar" />
			) : (
				<HeaderItem to="/analytic/dashboard" name={t('analytic')} icon="chart bar" />
			)}
			{/* 	{isExtApp && <HeaderItem to="/appstore" name={t('Apps')} icon="table" />} */}
			{isExtApp && <HeaderItem to="/appstore/install" name={t('install')} icon="setting" />}
			{isExtApp && !roleAgent && <HeaderItem exact to="/" name={t('subscriptions')} icon="table" />}
			{isExtApp && !roleAgent && <HeaderItem to="/appstore/agents" name={t('agents')} icon="users" />}

			{wgroup ? <HeaderItem to="/wgroup" name={t('WhatsApp Group')} icon="whatsapp" /> : null}
			{showWABroadcast ? <HeaderItem to="/wsendmessage" name={t('WhatsApp Broadcast')} icon="whatsapp" /> : null}
			{wvalidation ? <HeaderItem to="/wvalidation" name={'WhatsApp Validation'} icon="whatsapp" /> : null}
			{wcart ? <HeaderItem to="/wcart" name={t('WhatsApp Cart')} icon="cart" /> : null}
			<div className="mt-auto" />
			{/* {isAgent || reseller ? null : <HeaderItem to="docs.dialogtab.com" name={t('docs')} icon="file" />} */}
			{isAgent || reseller || isExtApp ? null : <HeaderItem to="/settings" name={t('settings')} icon="setting" />}
			{/* {isExtApp && <HeaderItem to="/settings/integrations/ecommerce" name={t('ecommerce')} icon="setting" />} */}
			{isExtApp && <HeaderItem to="/appstore/help" name={t('help')} icon="help" />}
			<div className="header-seperator"></div>
			<ChangeLanguageNew />
			<HeaderItem to="/account" name={t('myAccount')} icon="user" />
			<HeaderItem onClick={props.logoutUser} name={t('logout')} icon="log out" />
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(logout()),
});
export default connect((state) => ({ user: state.access.user.loggedUserObj, product: state.product.live }), mapDispatchToProps)(Header);
