import React, { Component } from 'react';
import { Icon, Segment, Table, Container, Button, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import TypeList from '../filter3.json';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { withTranslation } from 'react-i18next';
import 'moment/locale/tr';
import { Link } from 'react-router-dom';
class Analytic extends Component {
	state = {
		filterType: false,
		filterAgent: false,
		loading: true,
		start_date: this.props.start_date,
		end_date: this.props.end_date,
		today: moment(),
		group: ['agent'],
		value: _.map(TypeList, (it) => {
			return it;
		}),
		pid: /* '1e00a47c670273a623c13c54dba44006' || */ this.props.product.live.settings.live.token,
		selectedAgent: 'all',
		selectedDate: 'daily',
		column: 'created_at',
		botId: '',
		direction: 'desc',
		columndirection: 'descending',
		dataloading: true,
		isGroup: false,
	};
	_i = 0;
	handleSort = (clickedColumn) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				direction: 'desc',
			});

			return;
		}
		this.setState({
			direction: direction === 'asc' ? 'desc' : 'asc',
			columndirection: direction === 'asc' ? 'descending' : 'ascending',
		});
	};
	TableHead = (column, columndirection, t, selectedHeader) => (
		<Table.Header>
			<Table.Row>
				{_.map(selectedHeader, ({ value, title }) => (
					<Table.HeaderCell sorted={column === value ? columndirection : null} onClick={this.handleSort(value)}>
						{title}
					</Table.HeaderCell>
				))}
			</Table.Row>
		</Table.Header>
	);
	TableLine = (data, column, direction) => (
		<Table.Body>
			{_.map(_.orderBy(data, [column], [direction]), (it) => (
				<Table.Row>
					<Table.Cell>{it.extra.product_code}</Table.Cell>
					<Table.Cell>{it.extra.product_name}</Table.Cell>
					<Table.Cell>{it.extra.product_size}</Table.Cell>
					<Table.Cell>{it.extra.user_phone}</Table.Cell>
					<Table.Cell>{moment(it.created_at).format('DD-MM-YYYY HH:mm')}</Table.Cell>
					<Table.Cell>
						{
							<Button icon as={Link} to={`/#${it.cid}`} target="_blank">
								<Icon name="conversation"></Icon>
							</Button>
						}
					</Table.Cell>
				</Table.Row>
			))}
		</Table.Body>
	);
	TableLineGroup = (data, column, direction) => {
		let sortedData = [];
		if (column === 'count') {
			sortedData = _.orderBy(
				data,
				[
					function (o) {
						return o.length;
					},
				],
				[direction]
			);
		} else {
			sortedData = _.orderBy(data, [column], [direction]);
		}
		return (
			<Table.Body>
				{_.map(sortedData, (it) => (
					<Table.Row>
						<Table.Cell>{it[0].extra.product_code}</Table.Cell>
						<Table.Cell>{it[0].extra.product_name}</Table.Cell>
						<Table.Cell>{it.length}</Table.Cell>
					</Table.Row>
				))}
			</Table.Body>
		);
	};
	componentDidMount() {
		this.getData();
	}
	toggle = () => this.setState((prevState) => ({ isGroup: !prevState.isGroup, column: !prevState.isGroup ? 'count' : 'created_at' }));

	handleFilterCheck = (value, array) => () => {
		let valueArr = array;
		if (!_.find(valueArr, value)) {
			valueArr.push(value);
		} else {
			_.pullAllWith(valueArr, [value], _.isEqual);
		}
		this.setState({ value: valueArr });
	};
	handleGroupChange = (e, { text, value }) => this.setState({ group: value });
	filterSelectType = () => {
		this.setState({ filterType: !this.state.filterType });
	};
	componentDidUpdate(oldProps, oldState) {
		const check_list = ['start_date', 'end_date', 'selectedAgent', 'selectedDate'];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			let timer;
			if (key === 'start_date' || key === 'end_date') {
				if (this.props[key] !== oldProps[key]) {
					window.clearTimeout(timer);
					timer = setTimeout(() => this.getData(), 3000);
					break;
				}
			}
			if (this.state[key] !== oldState[key]) {
				window.clearTimeout(timer);
				timer = setTimeout(() => this.getData(), 3000);
				break;
			}
		}
	}

	async getData() {
		this.setState({ loading: true });
		let { start_date, end_date } = this.props;
		await fetch('https://log.yapaytech.com/live/getEvents', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date,
				end_date,
				filter: {
					label: 'out-of-stock',
					pid: this.state.pid,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ stockresult: _.orderBy(data.result, 'created_at', 'desc'), loading: false });
			});
	}

	dateChange = ({ selection }) => {
		let start_date = moment(selection.startDate).startOf('day');
		let end_date = moment(selection.endDate);
		if (end_date.format('YYYY-MM-DD') !== this.state.today.format('YYYY-MM-DD')) {
			end_date = end_date.endOf('day');
		} else {
			end_date = moment();
		}
		this.setState({ start_date, end_date });
	};
	render() {
		let { column, direction, stockresult, isGroup, columndirection } = this.state;
		let { t } = this.props;
		if (this.state.loading)
			return (
				<Container>
					<Segment style={{ marginBottom: '20px' }}>
						<Page.Loader></Page.Loader>
					</Segment>
				</Container>
			);

		let GroupData = _.groupBy(stockresult, 'extra.product_id');
		let eventHeader = [
			{
				value: 'extra.product_code',
				title: t('ProductCode'),
			},
			{
				value: 'extra.product_name',
				title: t('name'),
			},
			{
				value: 'extra.product_size',
				title: t('Size'),
			},
			{
				value: 'extra.user_phone',
				title: t('phone'),
			},
			{
				value: 'created_at',
				title: t('date'),
			},
			{
				value: '',
				title: '',
			},
		];
		let groupHeader = [
			{
				value: '0.extra.product_code',
				title: t('ProductCode'),
			},
			{
				value: '0.extra.product_name',
				title: t('name'),
			},
			{
				value: 'count',
				title: t('count'),
			},
		];
		let selectedHeader = [];
		switch (isGroup) {
			case true:
				stockresult = GroupData;
				selectedHeader = groupHeader;
				break;
			case false:
				selectedHeader = eventHeader;
				break;

			default:
				selectedHeader = groupHeader;
				break;
		}
		return (
			<Container>
				<Segment style={{ marginBottom: '20px' }}>
					<Checkbox toggle name="isGroup" checked={isGroup} label={t('group')} onChange={this.toggle} />
					<Table sortable>
						{this.TableHead(column, columndirection, t, selectedHeader)}
						{isGroup ? this.TableLineGroup(stockresult, column, direction) : this.TableLine(stockresult, column, direction)}
					</Table>
				</Segment>
			</Container>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product });

export default connect(stateToProps)(withTranslation()(Analytic));
