import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { Grid, Header, Icon, Segment, Table, Container, Radio } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import TypeList from '../filter3.json';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { withTranslation } from 'react-i18next';
import 'moment/locale/tr';

const ChartWrap = (props) => (
	<Grid.Column width={props.width}>
		<Header as="h4" attached="top" style={{ display: 'flex' }}>
			<Icon name={props.icon || 'chart bar'} />
			<Header.Content>{props.title}</Header.Content>
			{props.content}
		</Header>
		<Segment attached>{props.children}</Segment>
	</Grid.Column>
);

class Analytic extends Component {
	state = {
		filterType: false,
		filterAgent: false,
		loading: true,
		start_date: this.props.start_date,
		end_date: this.props.end_date,
		today: moment(),
		group: ['agent'],
		value: _.map(TypeList, (it) => {
			return it;
		}),
		pid: /* '1e00a47c670273a623c13c54dba44006' || */ this.props.product.live.settings.live.token,
		selectedAgent: 'all',
		selectedDate: 'daily',
		column: null,
		botId: '',
		direction: null,
		dataloading: true,
	};
	_i = 0;
	handleSort = (clickedColumn) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				direction: 'desc',
			});

			return;
		}
		this.setState({
			direction: direction === 'asc' ? 'desc' : 'asc',
		});
	};
	TableHead = (data, keys, head, column, direction) => (
		<Table.Header>
			<Table.HeaderCell width={6}>Agent</Table.HeaderCell>
			<Table.HeaderCell sorted={column === 'count' ? direction : null} onClick={this.handleSort('count')}>
				Count
			</Table.HeaderCell>
			<Table.HeaderCell sorted={column === 'sum' ? direction : null} onClick={this.handleSort('sum')}>
				Sum
			</Table.HeaderCell>
			<Table.HeaderCell sorted={column === 'avg' ? direction : null} onClick={this.handleSort('avg')}>
				Average
			</Table.HeaderCell>
		</Table.Header>
	);
	TableLine = (data, keys, head, st, agentList, productAgentList, productAgentList2, selectedAgent) => (
		<Table.Body>
			{selectedAgent === 'all' ? (
				_.map(_.orderBy(productAgentList2, [this.state.column], [this.state.direction]), (val, index) => (
					<Table.Row key={index}>
						<Table.Cell>{(val && val.developer) || 'Bot'}</Table.Cell>
						<Table.Cell>{val && val.count}</Table.Cell>
						<Table.Cell>{val && val.sum && val.sum.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</Table.Cell>
						<Table.Cell>{val && val.avg && val.avg.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</Table.Cell>
					</Table.Row>
				))
			) : (
				<Table.Row key="1">
					<Table.Cell>
						{(_.find(productAgentList2, { id: selectedAgent }) && _.find(productAgentList2, { id: selectedAgent }).developer) || 'Bot'}
					</Table.Cell>
					<Table.Cell>
						{(_.find(productAgentList2, { id: selectedAgent }) && _.find(productAgentList2, { id: selectedAgent }).count) || '0'}
					</Table.Cell>
					<Table.Cell>{(_.find(productAgentList2, { id: selectedAgent }) && _.find(productAgentList2, { id: selectedAgent }).sum) || '0'}</Table.Cell>
					<Table.Cell>
						{(_.find(productAgentList2, { id: selectedAgent }) &&
							_.find(productAgentList2, { id: selectedAgent }).avg &&
							_.find(productAgentList2, { id: selectedAgent }).avg.toFixed(2)) ||
							'0'}
					</Table.Cell>
				</Table.Row>
			)}
			{selectedAgent === 'all' ? (
				<Table.Row key={'all'}>
					<Table.Cell>{'All'}</Table.Cell>
					<Table.Cell>{_.sumBy(productAgentList2, 'count') || 0}</Table.Cell>
					<Table.Cell>
						{(_.sumBy(productAgentList2, 'sum') &&
							_.sumBy(productAgentList2, 'sum').toLocaleString('tr-TR', {
								style: 'currency',
								currency: 'TRY',
							})) ||
							0}
					</Table.Cell>
					<Table.Cell>
						{(_.sumBy(productAgentList2, 'sum') / _.sumBy(productAgentList2, 'count') &&
							(_.sumBy(productAgentList2, 'sum') / _.sumBy(productAgentList2, 'count')).toLocaleString('tr-TR', {
								style: 'currency',
								currency: 'TRY',
							})) ||
							0}
					</Table.Cell>
				</Table.Row>
			) : (
				''
			)}
		</Table.Body>
	);
	componentDidMount() {
		let { start_date, end_date } = this.state;
		fetch('https://log.yapaytech.com/live/getEventInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date,
				end_date,
				filter: {
					label: 'template',
					pid: this.state.pid,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.each(data.result.result, function (it) {
					return (it.dayNumber = moment(it.date).utc().dayOfYear());
				});
				_.each(data.result.result, function (it) {
					return (it.hour = moment(it.date).utc().hour());
				});
				this.setState({ templateTotalData: data.result.total, templateDayData: data.result.result, loading: false });
			});
	}
	toggle = () => this.setState((prevState) => ({ selectedDate: prevState.selectedDate === 'daily' ? 'hourly' : 'daily' }));

	handleFilterCheck = (value, array) => () => {
		let valueArr = array;
		if (!_.find(valueArr, value)) {
			valueArr.push(value);
		} else {
			_.pullAllWith(valueArr, [value], _.isEqual);
		}
		this.setState({ value: valueArr });
	};
	handleGroupChange = (e, { text, value }) => this.setState({ group: value });
	filterSelectType = () => {
		this.setState({ filterType: !this.state.filterType });
	};
	componentDidUpdate(oldProps, oldState) {
		const check_list = ['start_date', 'end_date', 'selectedAgent', 'selectedDate'];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			if (key === 'start_date' || key === 'end_date') {
				if (this.props[key] !== oldProps[key]) {
					this.getData()();
					break;
				}
			}
			if (this.state[key] !== oldState[key]) {
				this.getData()();
				break;
			}
		}
	}

	getData = (value) => async () => {
		this.setState({ loading: true });
		let current = ++this._i;
		let { start_date, end_date } = this.props;
		fetch('https://log.yapaytech.com/live/getEventInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date,
				end_date,
				filter: {
					label: 'template',
					pid: this.state.pid,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.each(data.result.result, function (it) {
					return (it.dayNumber = moment(it.date).utc().dayOfYear());
				});
				_.each(data.result.result, function (it) {
					return (it.hour = moment(it.date).utc().hour());
				});
				if (current !== this._i) return;
				this.setState({ templateTotalData: data.result.total, templateDayData: data.result.result, loading: false });
			});
	};

	dateChange = ({ selection }) => {
		let start_date = moment(selection.startDate).startOf('day');
		let end_date = moment(selection.endDate);
		if (end_date.format('YYYY-MM-DD') !== this.state.today.format('YYYY-MM-DD')) {
			end_date = end_date.endOf('day');
		} else {
			end_date = moment();
		}
		this.setState({ start_date, end_date });
	};
	exportExcel = (value, pid) => {
		fetch('https://log.yapaytech.com/analytic/download', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				filename: pid + '_sellingData',
				sheets: [
					{
						name: 'sheet - selling',
						x: ['developer', 'count', 'sum', 'avg'],
						datasets: value,
					},
				],
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let dataurl = data.url;
				var a = document.createElement('a');
				a.href = dataurl;
				a.setAttribute('download', dataurl);
				a.click();
			});
	};
	render() {
		if (this.state.loading)
			return (
				<Container>
					<Segment style={{ marginBottom: '20px' }}>
						<Page.Loader>sss</Page.Loader>
					</Segment>
				</Container>
			);
		let { start_date, end_date, t, i18n } = this.props;
		const barOptions = {
			scales: {
				xAxes: [{ stacked: false }],
				yAxes: [
					{
						id: 'A',
						stacked: false,
						position: 'left',
						ticks: { min: 0 },
						gridLines: {
							display: false,
						},
						scaleLabel: {
							display: true,
							labelString: t('labelCount'),
						},
					},
				],
				//yAxes: [{ stacked: false, ticks: { min: 0 } }],
			},
			tooltips: {
				mode: 'index',
				intersect: false,
				filter: (a) => a.yLabel,
			},
			hover: {
				mode: 'nearest',
				intersect: true,
			},
			legend: {
				display: true,
				labels: { fill: true, reverse: true },
			},
		};
		let data = chartData(this.state.templateDayData, start_date, end_date, this.state.selectedDate, t, i18n);

		if (this.state.loading)
			return (
				<Container>
					<Segment style={{ marginBottom: '20px' }}>
						<Page.Loader></Page.Loader>
					</Segment>
				</Container>
			);
		return (
			<Container>
				<Segment style={{ marginBottom: '20px' }}>
					<Table>
						<Table.Body>
							<Table.Cell>{t('total')}:</Table.Cell>
							<Table.Cell>{this.state.templateTotalData}</Table.Cell>
						</Table.Body>
					</Table>
					<ChartWrap
						width={8}
						title={'Graph'}
						agentList={this.state.agentList}
						content={<Radio style={{ marginLeft: 'auto' }} toggle label="24 hour Distribution" onChange={this.toggle}></Radio>}
					>
						<Line data={data} options={barOptions} />
					</ChartWrap>
				</Segment>
			</Container>
		);
	}
}
// date array
var getDateArray = function (start, end) {
	var arr = [],
		dt = new Date(start);

	while (dt <= end) {
		arr.push(new Date(dt));
		dt.setDate(dt.getDate() + 1);
	}

	return arr;
};
var getHourArray = function (start, end) {
	var arr = [],
		dt = 0;

	while (dt < 24) {
		arr.push(dt);
		dt++;
	}
	return arr;
};
function chartData(data, start_date, end_date, selectedDate, t, i18n) {
	moment.locale(i18n.language);
	/* let dataset = _.each(data, function(it) {
		return { label: it.type, count: it.count };
	}); */
	let startDate = new Date(start_date);
	let endDate = new Date(end_date);
	var dateArr = getDateArray(startDate, endDate);
	var hourArr = getHourArray();
	let dayData = _.groupBy(data, 'dayNumber');
	let hourData = _.groupBy(data, 'hour');
	let nDayData = _.map(dayData, function (it) {
		return {
			count: _.sumBy(it, 'count'),
			day: moment(it[0].date).format(),
			dayNumber: it[0].dayNumber,
		};
	});
	let nHourData = _.map(hourData, function (it) {
		return {
			count: _.sumBy(it, 'count'),
			day: moment(it[0].date).format(),
			hour: it[0].hour,
		};
	});
	let labels = [];
	if (selectedDate === 'daily') {
		_.each(dateArr, function (d, i) {
			if (_.findIndex(nDayData, { dayNumber: moment(d).dayOfYear() }) === -1) {
				nDayData.push({
					day: moment(d).format(),
					dayNumber: moment(d).dayOfYear(),
					count: 0,
				});
			}
		});
		nDayData = _.sortBy(nDayData, 'day');
	} else {
		_.each(hourArr, function (d, i) {
			if (_.findIndex(nHourData, { hour: d }) === -1) {
				nHourData.push({
					hour: d,
					count: 0,
				});
			}
		});
		nHourData = _.sortBy(nHourData, 'hour');
	}
	let nc = [
		{
			label: t('count'),
			data: _.map(selectedDate === 'daily' ? nDayData : nHourData, (item) => item.count),
			borderColor: '#8B565C',
			yAxisID: 'A',
			backgroundColor: '#8B565C',
			fill: false,
		},
	];
	if (selectedDate === 'daily') {
		labels = _.map(dateArr, (it) => moment(it).format('DD-MMM'));
	} else {
		labels = hourArr;
	}
	return {
		labels,
		datasets: nc,
	};
}
const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product });

export default connect(stateToProps)(withTranslation()(Analytic));
