import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Webchat from './modules/webchat';
import Maytapi from './modules/maytapi';
import SandBox from './modules/sandbox';
import WhatsappBusiness from './modules/whatsapp-business';
import Tsoft from './modules/tsoft';
import Ticimax from './modules/ticimax';
import Facebook from './modules/facebook';
import Faprika from './modules/faprika';
import WooCommerce from './modules/woocommerce';
import EcomercePayment from './modules/ecommerce_payments';
import Instagram from './modules/instagram';
import Akinon from './modules/akinon';
import Request from './modules/survey';
import Shopify from './modules/shopify';
import Telegram from './modules/telegram';
import Ecommerce from './modules/ecommerce';
import { connect } from 'react-redux';
import InstagramMessenger from './modules/instagrammessenger';
import LabelPage from './modules/label';
import WATemplatesPage from './modules/wabaTemplates';
import ContactPage from './modules/contacts';

class Channel extends React.Component {
	render() {
		let { user, product } = this.props;
		//let host = user.host === null || user.host === 'app.dialogtab.com' || user.host === 'localhost' || user.host === 'test.dialogtab.com';
		let product_type = product.type;
		let isShopify = product_type === 'shopify';
		let noFacebook = user && user.extra && user.extra.noFacebook;
		let noWebSite = user && user.extra && user.extra.noWebSite;
		let noWhatsappBusiness = user && user.extra && user.extra.noWhatsappBusiness;
		let noWhatsappWeb = user && user.extra && user.extra.noWhatsappWeb;
		let noTsoft = user && user.extra && user.extra.noTsoft;
		let noTicimax = user && user.extra && user.extra.noTicimax;
		let noFaprika = user && user.extra && user.extra.noFaprika;
		let noInstagram = user && user.extra && user.extra.noInstagram;
		let noAkinon = user && user.extra && user.extra.noAkinon;
		let noWooCommerce = user && user.extra && user.extra.noWooCommerce;
		let isTelegram = user && user.extra && user.extra.isTelegram;
		let isContacts = user && user.extra && user.extra.isContacts;
		//let phoneLimit = product && product.settings && product.settings.phone_limit;
		//let isTrial = product && product.settings && product.settings.subscription_package === 'trial-start';
		return (
			<Switch>
				{!noWebSite ? <Route path="/settings/integrations/website-chat" component={Webchat} /> : null}
				{isShopify ? <Route path="/settings/integrations/shopify" component={() => <Shopify {...this.props} />} /> : null}
				{!noTsoft ? <Route path="/settings/integrations/tsoft" component={Tsoft} /> : null}
				{!noTicimax ? <Route path="/settings/integrations/ticimax" component={Ticimax} /> : null}
				{!noWooCommerce ? <Route path="/settings/integrations/woocommerce" component={WooCommerce} /> : null}
				{!noFaprika ? <Route path="/settings/integrations/faprika" component={Faprika} /> : null}
				{!noFacebook ? <Route path="/settings/integrations/facebook-messenger" component={Facebook} /> : null}
				{!noWhatsappBusiness ? <Route path="/settings/integrations/whatsapp-business" component={WhatsappBusiness} /> : null}
				{!noWhatsappWeb ? <Route path="/settings/integrations/whatsapp-web-api" component={Maytapi} /> : null}
				{!noWhatsappBusiness ? <Route path="/settings/integrations/whatsapp-sandbox" component={SandBox} /> : null}
				{!noInstagram ? <Route path="/settings/integrations/instagram-api" component={Instagram} /> : null}
				{!noInstagram ? <Route path="/settings/integrations/instagram-messenger" component={InstagramMessenger} /> : null}
				{!noAkinon ? <Route path="/settings/integrations/akinon" component={Akinon} /> : null}
				{isTelegram ? <Route path="/settings/integrations/telegram" component={Telegram} /> : null}
				<Route path="/settings/integrations/ecommerce" component={Ecommerce} />
				{isContacts ? <Route path="/settings/integrations/contacts" component={ContactPage} /> : null}
				<Route path="/settings/integrations/ecommercepayment" component={EcomercePayment} />
				<Route path="/settings/integrations/label" component={LabelPage} />
				<Route path="/settings/integrations/wabatemplates" component={WATemplatesPage} />
				<Route path="/settings/integrations/request" component={Request} />
				<Redirect to="/settings/integrations/website-chat" />
			</Switch>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product.live, user: state.access.user.loggedUserObj });

export default connect(stateToProps)(Channel);
